<script>
  import { createEventDispatcher } from "svelte";
  import wrappedFetch from "../wrappedfetch.js";
  let dispatch = createEventDispatcher();

  const getPageDetails = async () => {
    const response = await wrappedFetch("helpcontent?helpId=Help12");
    return await response.json();
  };
</script>
<style>
  .field a {
    color: #1d70b8;
    cursor: pointer;
    text-decoration: none;
}
</style>

<svelte:head>
  <title>About | eAdmissions</title>
</svelte:head>
{#await getPageDetails() then details}
  <section class="">
    <div class="container">
      <div class="tile is-parent">
        <article
          class="tile is-child notification"
          style="background-color: transparent;">
            <div class="field"  style="margin-left: -0.75rem">
              <a href="/">
                <span class="fas fa-arrow-left" />
                Back
              </a>
            </div>
            <h1 class="title" style="text-align: center;color: #70457a;">
              About
            </h1>
        </article>
      </div>

      <div class="tile is-parent" style="margin-top:0px;">
        <div class="tile is-vertical is-12">

          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title is-5">{details[0].text}</p>

              <div class="content">
                <!-- <p>The Pan London eAdmissions System has been created by the London Local Authorites to
                                    provide a website where parents can easily find details about the schools maintained
                                    by the 33 London Local Authorities and Surrey County Council, and complete an
                                    application online.</p>
                                <p>The system has now operated successfully for the past 10 years.</p>
                                <p>Comments and suggestions for the site can be sent to <a
                                        href="mailto:webeditor@eadmissions.org.uk">webeditor@eadmissions.org.uk</a></p> -->
                <p>
                  {@html details[1].text}
                </p>

                <p class="title is-6">
                  {@html details[2].text}
                </p>
                {@html details[3].text}
                <!-- <ul>
                                    <li>Site Registration - you must register to be able to create online applications
                                        and view their outcome.</li>
                                    <li>Application System - once you have registered then you can create online school
                                        applications to the 33 London Local Authorities and Surrey County Council.</li>
                                    <li>'Find your nearest' tool, which enables you to either see all the schools in a
                                        particular Local Authority or alternatively the schools located within 10km of a
                                        postcode.</li>
                                </ul> -->
                <p class="title is-6">
                  {@html details[4].text}
                </p>
                <p>
                  {@html details[5].text}
                </p>
                <hr style="background-color: #55aab0;height: 1px;">
                <p>This site is hosted by 
                  <a href="https://www.lgfl.net/" target="_blank">
                    <img src="https://cdn.atomwide.com/lgfl/i/lgfl-heart-logo.png" align="absmiddle" alt="LGfL" style="height: 40px; border: 0px;">
                  </a>
                  and developed by 
                  <a href="https://www.wavenet.co.uk/education" target="_blank">
                    <img src="/images/logos/Wavenet-education.png" align="absmiddle" alt="Wavenet" border="0" style="height: 40px;">
                  </a>
                </p>
              </div>

            </article>
          </div>

          <div
            class="tile is-ancestor columns is-multiline"
            style="margin-left:1px; margin-top:5px;">
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/copyright')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/copyright')}}>
              <article
                class="tile home-button is-child notification is-primary">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-9">
                    <p class="title is-4">Copyright</p>
                    <p style="text-decoration:underline">View</p>
                  </div>
                  <div class="column is-3">
                    <span class="fas fa-copyright" style="font-size: 3rem;" />
                  </div>
                </div>
              </article>

            </div>
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/disclaimer')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/disclaimer')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #407f8a; color:white;">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-9">
                    <p class="title is-4">Disclaimer</p>
                    <p style="text-decoration:underline">View</p>
                  </div>
                  <div class="column is-3">
                    <i
                      class="fas fa-exclamation-triangle"
                      style="font-size: 3rem;" />
                  </div>
                </div>
              </article>
            </div>
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/privacy')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/privacy')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #666666; color:white;">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-9">
                    <p class="title is-4">Privacy</p>
                    <p style="text-decoration:underline">View</p>
                  </div>
                  <div class="column is-3">
                    <span class="">
                      <span class="fas fa-user-secret" style="font-size: 3rem;" />
                    </span>
                  </div>
                </div>
              </article>
            </div>
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/accessibility')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/accessibility')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #416EA1; color:white;">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-9">
                    <p class="title is-4">Accessibility</p>
                    <p style="text-decoration:underline">View</p>
                  </div>
                  <div class="column is-3">
                    <span class="">
                      <i
                        class="fas fa-universal-access"
                        style="font-size: 3rem;" />
                    </span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
{/await}
