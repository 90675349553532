<script>
  import { createEventDispatcher } from 'svelte';  
  const dispatch = createEventDispatcher();

  let error = "";
  let regex = /^[\w!#\$%&'\*\+\-/=\?\^_`\{\|\}~\.]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  export let email = "";
  export let isRequired = false;
  export let isValid = false;
  export let placeholder = "alice@bob.com";
  export let isDirty = false;
  export let ref = null;
  export let label ="";
  export let func = null; 
  export let faIcon = null;
  export let emailToCompare = null;
  export let isInUse =false;
  export let isForConfirmation = false;
  export let readoutLabel = null;
  $: {
    isValid = true

    if (isRequired && (email == "" || !email))
      isValid = false;

    if (isDirty) {
      if (isRequired && email == "") {
        isValid = false; 
        error = isForConfirmation ? "Please re-enter your " + label : "Please enter your " + label;
      } else if (!regex.test(email)) {
        isValid = false;
        error = "Not a valid email address.";
      } else if(emailToCompare && emailToCompare.toLowerCase() != email.toLowerCase()){
        isValid = false;
        error = 'Email addresses do not match. ';
      }
      else if(isInUse) 
      {
        isValid = false;
        error ='Email address is already in use.'
      }
      else if(func){
        let result = func();
        if (IS_DEV) console.log(result.isValid + ':' + email);
        if(!result.isValid)
         error = result.message;
        else
          isValid= true;
      } 
      else {
        isValid = true; error="";
      }
    }
  }

</script>
<style>
  .fa-icon {
    padding-top: 10px;
    padding-left: 10px;
  }
</style>
<input
  aria-label={readoutLabel ? readoutLabel : label}
  class="input {!isValid && isDirty ? 'is-danger' : ''}"
  type="text"
  {placeholder}
  on:blur={() => {
    email = email.trim(); 
    if (!isDirty)
      isDirty = email !="";
     dispatch('blur');
  }}
  bind:value={email}
  bind:this={ref}
  autocomplete="off"
  list="autocompleteOff" />

{#if faIcon}
  <span class="icon is-small is-left fa-icon { faIcon }" />
{/if}
{#if !isValid && isDirty}
  <p class="help has-text-danger">{error}</p>
{/if}
