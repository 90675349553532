<script>
  import Modal from "./HTMLElements/Modal.svelte";
  import ModalMessage from "./HTMLElements/ModalMessage.svelte";
  import { createEventDispatcher } from "svelte";
  import { User } from "../store.js";
  import wrappedFetch from "../wrappedfetch.js";
  let mobileCode = "";
  export let showModal = false;
  let showSuccessMessage = false;
  const dispatch = createEventDispatcher();
  let info = "";
  let success = false;
  let errMessage = "";
  let isUpdating =false;
  let isSending = false;

  export let mobile = "";
  export let isDispatchResend = false;
  const verifyCode = e => {
    
    errMessage = "";
    e.preventDefault();
    if (mobileCode == "") {
      errMessage = "Please enter your verification code";
      return;
    }
    isUpdating = true;
    wrappedFetch("user/updateSMSverificationcode?code=" + mobileCode)
      .then(res => res.json())
      .then(result => {
        success = result.success;
        if (success) {
          info = result.message;
          $User.isMobileValidated = "Y";
          showModal = false;
          showSuccessMessage = true;        
        } else {
          errMessage = "Your mobile could not be verified,please try again!";
        }
        isUpdating = false;
      });
    return false;
  };

  const verifyLater = e => {
    e.preventDefault();
    showModal = false;
  };
  const resendCode = e => {
    e.preventDefault();
     isSending = true;    
     setTimeout(()=>{  isSending = false; }, 5000);
    wrappedFetch("user/sendSMSverificationcode")
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          info = "Your mobile verification code has been sent";
        }
        setTimeout(()=>{  isSending = false; }, 60000);
      });
   
  };
</script>
<style>
.disable {
  pointer-events: none;
  cursor: default;
}</style>


<ModalMessage
  bind:showModal={showSuccessMessage}
  on:continue={() => {
    dispatch('close');
    showSuccessMessage = false;
  }}>
  {#if info != ''}
    <div class="field">
      <p>{info}</p>
    </div>
  {/if}
</ModalMessage>

<Modal bind:showModal allowClose={false}>
  {#if !success}
    <div class="field">
      <div style="padding: 1rem;">
        <p class="title is-4">Verification code sent!</p>
        <p>
          You have been sent a verification code to your mobile:
          <b>{mobile}</b>.Please enter the validation code below and click 'Verify'.
        </p>
        <p style="margin-bottom: -10px;">
          If you do not receive the message, please click
          <a href="javascript:;" class={isSending?'disable':''} on:click={e =>{if(isDispatchResend) dispatch('resend'); else resendCode(e);}}>Resend Code</a>
          to send the verification code again.
        </p>
      </div>
      <form on:submit={verifyCode}>
        <div class="control" style="padding: 1rem;">
          <input
            type="text"
            class={errMessage != '' ? 'input is-danger' : 'input'}
            bind:value={mobileCode}
            required
            placeholder="Example: ABCDE" />
          {#if errMessage != ''}
            <p style="margin-top:10px;" class="help is-danger">{errMessage}</p>
          {/if}
        </div>
        <div class="field" style="padding: 0.5rem;">
         
          <div class="buttons" style="float: right;">
            <button              
              class="button is-primary {isUpdating ? 'is-loading' : ''}"
              type="submit"
              disabled={isUpdating}
              on:click={verifyCode}>
              Verify
            </button>
          </div>
           <div class="buttons" style="float: left;">
            <button
              class="button"
              on:click={() => {               
                dispatch('verifyLater')
               errMessage="";
               mobileCode="";
                showModal = false;
              }}>
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="field">
        <p style="padding:1rem;">You have been sent verification code in your mobile <b>{mobile}</b>.Please enter the validation code below and click 'Verify'.Alternatively, please click 'Resend Code' to send the verification code again.</p>       
        <form on:submit={verifyCode}>
        <div class="control" style="padding:1rem;">
            <input  type="text"  class="input"  bind:value={mobileCode} required placeholder="ABCDE">  
        </div>
        <div class="field" style="padding:0.5rem;">
    <div class="buttons" style="float:right;">  <button class="button is-primary" type="submit" on:click={verifyCode}> Verify</button>
      <button class="button is-purple" on:click={resendCode}>Resend Code</button>
      </div>  
      </form>   
     </div> -->
  {/if}
</Modal>
