<script>
	import { Username, changePasswordThrough, IsMobileVerified, IsEmailValidated, SecurityQuestions } from "./store.js";
	import { onMount, createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	import wrappedFetch from '../../wrappedfetch.js';

	let email = false,
	sms = false,
	password = true;
	let selected = 1;
	let canResetPassword = true;
	let getResetpasswordAttributes;
	let isComponentLoading = true;
	onMount(async function() {
	if($Username.value == '')
	dispatch('pageChange','/account/forgotpassword');
	getResetpasswordAttributes = await wrappedFetch("user/CanResetPassword?username=" + $Username.value)
	.then(res => res.json())
	.then(result => {
	return result; });
	if(getResetpasswordAttributes.usoPasswordChangeMessage == "")
	{
	canResetPassword = getResetpasswordAttributes.canResetPasswordInEadmissions;
	if(getResetpasswordAttributes.isParent || !getResetpasswordAttributes.hasOTP)
	$SecurityQuestions = await wrappedFetch("user/GetSecurityQuestionsForUser?username=" + $Username.value)
	.then(res => res.json())
	.then(result => {
	if(result.success)
	return result.securityAnswers;
	else return [];
	});
	isComponentLoading = false;

	}
	isComponentLoading = false;
	});

	$: {
		if (selected === 1) {
		$changePasswordThrough = "email";
		} else if (selected === 2) {
		$changePasswordThrough = "SMS";
		} else if (selected === 3) {
		$changePasswordThrough = "password";
		} else if (selected === 4) {
		$changePasswordThrough = "securityQuestions";
	}
	
	}
</script>

<style>
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #407f8a; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-flex;
    animation: spin 2s linear infinite;   
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>

<form action="" class="box">
{#if isComponentLoading}
<div class="container" style="text-align:center">
<div class="loader"></div>
</div>
{:else}

  <div class=" field">
    <a href="/account/forgotpassword">
      <span class="fas fa fa-arrow-left" />
      Back
    </a>
  </div>

  <hr />

  <h1 class="title is-4">Password change: {$Username.value}</h1>
  {#if getResetpasswordAttributes &&  (getResetpasswordAttributes.usoPasswordChangeMessage != "" || getResetpasswordAttributes.hasOTP)}
  <div class="notification is-invalid">
    {#if getResetpasswordAttributes.usoPasswordChangeMessage != ""}
    <p style="margin-bottom:0px;">{@html getResetpasswordAttributes.usoPasswordChangeMessage}</p>
    {:else}
    <p style="margin-bottom:0px;">Please <a href="https://support.lgfl.org.uk/public/changepass.aspx" target="_blank">click here</a> to change your password!</p>
    {/if}
  </div>
  {:else if getResetpasswordAttributes}
  <p class="subtitle is-6">How would you like to change your password?</p>
  <!-- {#if $IsEmailValidated} -->
  {#if getResetpasswordAttributes && getResetpasswordAttributes.isParent}
    <label class="radio" disabled={canResetPassword ? null : true} >
      <input type="radio" tabindex="0" bind:group={selected} value={1} disabled={canResetPassword ? null : true} />
      By email
    </label>
    {#if !canResetPassword}
      <p class="help has-text-danger">This option is currently unavailable, as your password has been changed within the last hour and the new value may still be on its way to you.</p>
    {/if}
    <br/>
  {/if}
  
  {#if (getResetpasswordAttributes && !getResetpasswordAttributes.isParent && getResetpasswordAttributes.canResetBySMSInUso) || (getResetpasswordAttributes && getResetpasswordAttributes.isParent && $IsMobileVerified)}
    <label class="radio" disabled={canResetPassword ? null : true}>
      <input type="radio" tabindex="0" bind:group={selected} value={2} disabled={canResetPassword ? null : true}/>
      By SMS
    </label>
    {#if !canResetPassword}
      <p class="help has-text-danger">This option is currently unavailable, as your password has been changed within the last hour and the new value may still be on its way to you.</p>
    {/if}
    <br />
  {/if}
  
  {#if getResetpasswordAttributes}
  <label class="radio">
    <input type="radio" tabindex="0" bind:group={selected} value={3} disabled={canResetPassword ? null : true} />
    By entering my current password
  </label>
  {#if !canResetPassword}
  <p class="help has-text-danger">This option is currently unavailable, as your password has been changed within the last hour and the new value may still be on its way to you.</p>
	{/if}
  <br />
  {/if}
  
  {#if $SecurityQuestions.length > 1 }
    <label class="radio">
      <input type="radio" tabindex="0" bind:group={selected} value={4} />
      By answering my reminder questions
    </label>
	{#if !canResetPassword}
	<p class="help has-text-danger">This option is currently unavailable, as your password has been changed within the last hour and the new value may still be on its way to you.</p>
  	{/if}
  {/if}
  <hr />

  {#if canResetPassword}
	<div style="text-align: right;">
		<a href='/account/selectedresetoption' class="button is-purple">Continue</a>
	</div>
  {/if}

  {/if}

{/if}
</form>
