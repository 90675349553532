<script>
  import ModalMessage from "../HTMLElements/ModalMessage.svelte";
  import ModalConfirm from "../HTMLElements/ModalConfirm.svelte";
  import Spinner from "../HTMLElements/Spinner.svelte";
  import Help from "../help.svelte";
  import Modal from "../HTMLElements/Modal.svelte";
  import {createEventDispatcher,onMount} from 'svelte';
  import { Child } from "./store.js";
  import wrappedFetch from '../../wrappedfetch.js';
  import * as utils from "../Utils/utils.js";
  const dispatch = new createEventDispatcher();
  export let id;
  export let applicationId;
  let showMessage = false;
  let modalMessage;
  let showAddFile = false;
  let docPreview;
  let docName;
  let documents = [];
  let fileToDelete;
  let showTest = false;
  let showHelpModal = false;
  let helpId = "Help37";
  let docType;
  let uploadError = "";
  let document;
  let uploadingDocument = false;
  let isDocTypeValid = false;
  let isDocTypeDirty = false;
  let docTypeError = "";
  let isFileValid = false;
  let isFileDirty = false;
  let fileError = "";
  let isPageLoading = true;

  let allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/tiff",
    "application/pdf"
  ];
  let types =[];
  let isDeleting = false;
  let helpText='';
  let showMoreInfoHelptext = false;

  let isUploadVisible =false;
  let isUploadRemoveVisible = false;

  const getDocuments = () => {
    return wrappedFetch(`/documents?applicationId=${applicationId}&x=${new Date().getTime()}`)
      .then(response => response.json())
      .then(response => (documents = response));
  };

  const getDocumentTypes = () => {
    return wrappedFetch(
      `/documents/GetDocumentTypesList?applicationId=${applicationId}&childId=${id}`
    ).then(response => response.json());   
  };

  

  const deleteDocument = () => {
    isDeleting = true;
    wrappedFetch(`/documents/deletefile?docId=${fileToDelete.id}`, {
      method: "post"
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          documents = documents.filter(d => d.id != fileToDelete.id);
          fileToDelete = null;
          
          
          modalMessage = "Document deleted successfully.";
          dispatch("notification", modalMessage)
        }
        isDeleting = false;
      });
  };

  const validate = e => {
    docTypeError = "";
    fileError = "";
    isDocTypeValid = true;
    isFileValid = true;
    if (isDocTypeDirty && !docType.id) {
      isDocTypeValid = false;
      docTypeError = "You must choose a document type.";
    }
    if (isFileDirty && !document) {
      isFileValid = false;
      fileError = "You must browse for a document to attach.";
    } else if (isFileDirty && document && document.length > 0) {
      if (!allowedFileTypes.includes(document[0].type)) {
        isFileValid = false;
        fileError = "Only images or PDFs are accepted.<br/>";
      }
       if((document[0].size/(1024*1024)).toFixed(2) > 30) //converting bytes to MB
      {       
        isFileValid = false;
        fileError = fileError+"Document size is too big.";

      }
    }
    return isDocTypeValid && isFileValid;
  };
  const onAttachSubmit = e => {
    e.preventDefault();
    isFileDirty = true;
    isDocTypeDirty = true;
    uploadError = "";
    let formData = new FormData(e.target);
    if (!validate()) return false;
    uploadingDocument = true;
    wrappedFetch("/documents/uploadfile", {
      method: "post",
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          showAddFile = false;
          isUploadRemoveVisible = true;
          getDocuments();          
          modalMessage = "Document attached successfully.";
          dispatch("notification", modalMessage)
        } else {
          uploadError = response.message;
        }

        uploadingDocument = false;
      });
  };

  const getChild = async () => {
  let result = await wrappedFetch(`/child/getchildren?childId=${id}&x=${new Date().getTime()}`).then(
      response => response.json()
    );

    let applications =result.children;
     
      let app = applications[0].applications.filter(a=> a.applicationId == applicationId)[0];

      isUploadVisible= app.isUploadVisible;
      isUploadRemoveVisible = app.isUploadRemoveVisible;
        
  };

  
  onMount(async()=>{
  var response = await getDocumentTypes();
  types= response.documentTypeList;
  helpText = response.helpText;
  await getChild();
  await getDocuments();
  isPageLoading = false;
  })
 
  const handleDocumentTypesChange = () => {};
  export const saveAndExit = async () => {
    dispatch("pageChange", "/user/home");
  };
  let browser = utils.getBrowserName();
</script>

<style>
  table td:not([align]),
  table th:not([align]) {
    vertical-align: inherit;}
    .document-list{
      font-size: 1.25rem;;
    }
</style>

<svelte:head>
  <title>Documents | eAdmissions</title>
</svelte:head>
{#if isPageLoading}
<Spinner/>
{:else}

<div class="field">
  <a href="/user/home">
    <span class="fas fa-arrow-left" />
    Back
  </a>
</div>
  
  <div style="display:inline-flex" >
    <h1 class="title is-3">Attach documents for {$Child.forename + ' ' + $Child.surname}</h1>
    <span name="infoIcon" class="icon helpicon"  on:click={() => {showHelpModal = true;}}>
      <span class="fas fa-info-circle"></span>
    </span>
  </div>
<p class="subtitle is-5">You can attach the following supporting documents (in  PNG, PDF or JPG format) to your online school application:</p>
<ul>
  {#each types as type}
  <li class="document-list">{type.name}</li>
  {/each}
</ul>
<p class="subtitle is-5">
  <!-- Please note you can photograph your support documentation and attach them to your application.  -->
For further information from your Local Authority, please <a  on:click={()=>showMoreInfoHelptext=true}>click here</a>.
</p>
{#if documents.length > 0}
<div class="table-container">
  
  <table class="table" tabindex="0" aria-label="documents you have uploaded">
    <thead>
      <tr>
      <th />
       <th>File Name</th>
       <th>Document Type</th>
        <th>Reference</th>
        <th>Uploaded</th>
      </tr>
    </thead>
    <tbody>
      {#each documents as doc}
        <tr>
         <td>
           {#if isUploadRemoveVisible}
            <button
              on:click={() => (fileToDelete = doc)}
              class="button is-danger" style="font-size:0.9rem;">
              <span class="fa fa-trash" />
              <span style="margin-left:6px">Delete</span>
            </button>
            {/if}
          </td>
            <td>
            <a
              href="javascript:;"
              on:click={() => {docPreview = doc.id; docName = doc.fileName}}
              title="View document">
              {doc.fileName}
            </a>
          </td>
           <td>{doc.name}</td>
          <td>{doc.applicationRef} - {doc.id}</td>
          <td>{doc.uploadedStr}</td>
         
        
         
        </tr>
      {/each}
    </tbody>
  </table>
</div>
{/if}
<div style="text-align: right;">
  
  {#if isUploadVisible}
  <button
    class="button is-primary"
    on:click={() => {
      showAddFile = true;
      docType = {};
    }}>
    Attach
  </button>
  {/if}
  <a href="/user/home" class="button" style="margin-right: 15px;">
    Home
  </a>
</div>
{/if}

<Modal bind:showModal={docPreview}>
  <div style="text-align: center;">
  {#if docName.split('.')[1] == 'pdf'}
    {#if browser == 'ie'}
      {#if !utils.isAcrobatInstalled()}
        <p>To preview file, please download Adobe PDF Reader from <a href="https://get.adobe.com/uk/reader/" target='_blank'>here</a></p>
      {:else}
        <iframe src="/documents/preview?docId={docPreview}" title="{docName}" style="width: 100%; height: calc(50vh);"/>
      {/if}
    {:else}
      <iframe src="/documents/preview?docId={docPreview}" title="{docName}" style="width: 100%; height: calc(50vh);"/>
    {/if}
  {:else}
    <img src="/documents/preview?docId={docPreview}" alt="attached image"/>
  {/if}
  </div>
  <br />
  <footer class="modal-card-foot buttons is-right" slot="footer">
  <div style="text-align: right;">
    <a download={docName} href="/documents/preview?docId={docPreview}" target="_blank" class="button is-primary">
      Download
    </a>
  </div>
  </footer>
</Modal>

<Modal bind:showModal={showAddFile}>
  <form on:submit={onAttachSubmit}>
    <input type="hidden" name="applicationId" value={applicationId} />
    <input type="hidden" name="docType" value={docType.id} />
    <input type="hidden" name="schoolId" value={docType.schoolId} />
    <div class="field">

      <label class="label" for="type">Document type</label>

      <div
        class="select {!isDocTypeValid && isDocTypeDirty ? 'is-danger' : ''}">

        <select
          name=""
          id="type"
          bind:value={docType}
          on:change={() => {
            isDocTypeDirty = true;
            validate();
          }}>
          <!-- {#await getDocumentTypes()}
            <option value="0">Loading options</option>
          {:then types}
            <option value="0">Select an option</option>
            {#each types as t}
              <option value={t}>{t.name}</option>
            {/each}
          {/await} -->
          <option value="0">Select an option</option>
          {#each types as t}
            <option value={t}>{t.name}</option>
          {/each}
        </select>
      </div>
      {#if docTypeError != ''}
        <span>
          <p class="help has-text-danger" style="margin-top:.5rem">
            {docTypeError}
          </p>
        </span>
      {/if}
    </div>

    <div class="field">
      <label class="label" for="upload">Browse for document to attach</label>

      <div class="control">
        <input
          class="input {!isFileValid && isFileDirty ? 'is-danger' : ''}"
          type="file"
          name="document"
          bind:files={document}
          id="upload"
          on:change={() => {
            isFileDirty = true;
            validate();
          }} />
        {#if fileError != ''}
          <span>
            <p class="help has-text-danger">{@html fileError}</p>
          </span>
        {/if}
      </div>
    </div>
    {#if uploadError != ''}
      <div class="field">
        <p class="help is-danger">
          {@html uploadError}
        </p>
      </div>
    {/if}

    <!-- <input
          type="submit"
          class="button is-primary {uploadingDocument ? 'is-loading' : ''}"
          name="continue"
          id="continue"
          value="Attach"
          disabled={uploadingDocument} />       -->
    <button
      type="submit"
      class="button is-primary {uploadingDocument ? 'is-loading' : ''}"
      name="continue"
      id="continue"
      value="Attach"
      disabled={uploadingDocument}>
      Attach
    </button>

    <div style="text-align: justify; font-size:1rem; margin-top: 10px;">
      <strong>Please Note:</strong>
      <p>
        Supplementary Information Forms (SIFs) must be sent directly to the
        school. Please check the relevant <a href="/contact/" target="_blank">Local Authority's guidance.</a>
      </p>
      <p style=" margin-top: 10px;">
        If you are unable to attach any documents please send them to your Home
        Local Authority quoting your child's name and application reference
        number.
      </p>
    </div>
  </form>
</Modal>

<ModalConfirm showModal={fileToDelete} on:ok={deleteDocument} bind:isUpdating={isDeleting}>
  Are you sure you want this delete this file?
</ModalConfirm>

{#if showHelpModal}
  <Modal bind:showModal={showHelpModal}>
    <Help
      {helpId}
      showSearchOption={false}
      showContent={false}
      doAnimation={false}
      isPopup={true} />
    <!-- <footer class="modal-card-foot buttons is-right" slot="footer">
      <button
        class="button continue"
        on:click={() => {
          showHelpModal = false;
        }}>
        Close
      </button>

    </footer> -->
  </Modal>
{/if}

<Modal bind:showModal={showMoreInfoHelptext}>
{@html helpText}

</Modal>
