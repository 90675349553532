<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { RelationshipOptions,Child,dateToWords } from "./store.js";
  import { GetChild } from "./service.js";
  import Modal from "../../components/HTMLElements/Modal.svelte";
  import Help from "../../components/help.svelte";
  const dispatch = new createEventDispatcher();
  export let id;
  export let applicationId;
  const SchoolNurseryLabel = "School/Nursery:"; 
  let showCouncilTax = false;
  let showHelpModal = false;
  let helpId = "Help7";
  let isCouncilTaxIncomplete = false;
  let schoolText="";
  //export let substep='';
  export const saveAndExit = async () => {
    dispatch("pageChange", `/user/home`);
  }

  onMount(async ()=>{

    if (!$Child || $Child.id != id)
      await GetChild(id);
      if($Child.phase=="P") schoolText="nursery"; else schoolText = 'school';
      showCouncilTax = $Child.showBoroughCouncilTax == 'Y' || $Child.showBoroughCouncilTax == 'M' ;  
     // showCouncilTax = $Child.councilTaxRef && $Child.councilTaxRef!="";
     isCouncilTaxIncomplete=$Child.showBoroughCouncilTax == 'M' && ($Child.councilTaxRef == null || $Child.councilTaxRef == "")
  });
  const findNextStep = () => {

    

    if($Child.scSkip || ($Child.scForename && $Child.scForename != ''))
    {
      if($Child.isApplicationEHCPBarred == 'Y')
       dispatch("pageChange", `/user/home`);
      else
      dispatch("pageChange", `/application/preferences/${id}/${applicationId}`);
    }
    else if($Child.relationship && $Child.relationship != '') 
      dispatch("pageChange", `/application/secondcontact/${id}/${applicationId}`);
    else if($Child.educationStatus && $Child.educationStatus !="" )
      dispatch("pageChange", `/application/relationdetails/${id}/${applicationId}`);
    else if($Child.multipleBirth && $Child.multipleBirth != '')
      dispatch("pageChange", `/application/currentschool/${id}/${applicationId}`);
    else
      dispatch("pageChange", `/application/childattributes/${id}/${applicationId}`);
  }
</script>
<style>

  .icon {
    color: #209cee;
    cursor: pointer;
  }
  .panel-heading p{
    margin-bottom:0px;
  }

    </style>

<div class="step-13-shows">
 <div style="display:inline-flex">
  <h4 class="title is-3">Are these details correct?</h4>
  <span
        name="infoIcon"
        class="icon helpicon"
        on:click={() => {
          showHelpModal = true;
        }}>
        <span class="fas fa-info-circle" />
      </span>
</div>
  <div style="margin-top:-1.5rem;margin-bottom:2rem;">
    <ul class="subtitle is-5">
      <li>When correct select ‘Save and continue’ </li>
      <li>If incorrect then select ‘Change details’</li>
    </ul>
  </div>
 
  <nav class="panel" style="margin-top: 15px;">
    {#if $Child}
      <div
        class="panel-heading"
        style="display: flex;align-items: stretch;font-size: inherit;">
        <p style="display: flex;flex-grow: 1;align-items: center;">
          Child details
         {#if $Child.forename !="" && !isCouncilTaxIncomplete} <span style="margin-left: 5px;" class="icon has-text-success">
            <span class="fas fa-check" />
          </span>{/if}
            {#if $Child.forename ==""  || isCouncilTaxIncomplete} <span style="margin-left: 5px; color:#cc0404;" class="icon">
            <span class="fas fa-times" />
          </span>{/if}
        </p>
        <a aria-label="Change child details" class="button" style=" font-size: 1rem;" href="/application/childdetails/{id}/{applicationId}">
          Change details
        </a>
      </div>

      <div class="panel-block">

      <div class="column">
        Full name:
        <strong> {$Child.forename} {$Child.middleName || ''} {$Child.surname}</strong>
        <br />
         DoB:
        <strong> {dateToWords($Child.dob)}</strong>    
        <br/> 
        Sex:
        <strong> {$Child.genderDetail}</strong>
         <br />
        Address: 
         <strong>   {#if $Child.useParentAddress == "Y"} 
            {#each $Child.parentAddress.addr.split(',') as addr}
             {addr}<br/>
            {/each}
            {:else}
            {#each $Child.address.addr.split(',') as addr}
              {addr}<br/>
            {/each}
            {/if}
            
          </strong>
  

        {#if showCouncilTax}
        Council tax number:
        <strong>{$Child.councilTaxRef == null ? 'Not set':$Child.councilTaxRef }</strong>
        {/if}
      </div>
   

    </div>

    <div  class="panel-heading" style="display: flex;align-items: stretch;font-size: inherit;">
      <p style="display: flex;flex-grow: 1;align-items: center;">
        Child details continued
           {#if  $Child.multipleBirth &&( $Child.multipleBirth == "Y" || $Child.multipleBirth =="N")} <span style="margin-left: 5px;" class="icon has-text-success">
            <span class="fas fa-check" />
          </span>{/if}
            {#if  !$Child.multipleBirth || ($Child.multipleBirth != "Y" && $Child.multipleBirth !="N" ) } <span style="margin-left: 5px; color:#cc0404;" class="icon">
            <span class="fas fa-times" />
          </span>{/if}
      </p>
      {#if ($Child.multipleBirth && ($Child.multipleBirth == "Y" || $Child.multipleBirth =="N") || $Child.useParentAddress == 'Y' ? $Child.parentAddress : $Child.address ) }
      <a aria-label="change child details" class="button"  style=" font-size: 1rem;" href="/application/childattributes/{id}/{applicationId}" >
        Change details
      </a>
      {/if}
      </div>
      <div class="panel-block">

      <div class="column">     
       
        Multiple birth: 
        <strong>
           {#if $Child.multipleBirth == null || ($Child.multipleBirth != "Y" && $Child.multipleBirth !="N" )} Not Set  {:else if $Child.multipleBirth == 'Y'}Yes{:else}No{/if}
        </strong>
        <br />
        Has been in care: 
        <strong>
          {#if $Child.inCareId != null}Yes{:else}No{/if}
        </strong>
        <br />
        Has an Education Health and Care Plan  (EHCP): 
        <strong>
       {#if $Child.statement == null} No  {:else if $Child.statement == 'Y'}Yes{:else}No{/if}
        </strong>
        <br />

        </div>

      </div>
      {#if $Child && $Child.phase != 'N1'}
      <div
        class="panel-heading"
        style="display: flex;align-items: stretch;font-size: inherit;">
        <p style="display: flex;flex-grow: 1;align-items: center;">
          Current {schoolText}
          {#if $Child.educationStatus !=""} <span style="margin-left: 5px;" class="icon has-text-success">
            <span class="fas fa-check" />
          </span>{/if}
            {#if $Child.educationStatus ==""} <span style="margin-left: 5px; color:#cc0404;" class="icon">
            <span class="fas fa-times" />
          </span>{/if}
        </p>
        {#if $Child.multipleBirth || $Child.educationStatus !=""  }
        <a class="button" aria-label="change education status" style=" font-size: 1rem;" href="/application/currentschool/{id}/{applicationId}">
          Change details
        </a>
        {/if}
      </div>
      <div class="panel-block">

        <div class="column">
          {SchoolNurseryLabel}:
          <strong>
            {#if $Child.notAtSchool == 'Y'}
              Not currently attending {schoolText}
            {:else if $Child.homeEducated == 'Y'}
              Educated at home
            {:else if $Child.abroadEducated == 'Y'}
              Educated abroad
            {:else if $Child.currentSchoolId}{$Child.currentSchoolName}
            {:else}{#if $Child.currentSchoolNameTyped == null } Not set {:else} {$Child.currentSchoolNameTyped}{/if} {/if}
          </strong>
        </div>

      </div>
{/if}
  <div  class="panel-heading" style="display: flex;align-items: stretch;font-size: inherit;">
      <p style="display: flex;flex-grow: 1;align-items: center;">
        Relationship details
           {#if  $Child.relationship && $Child.relationship !=""} <span style="margin-left: 5px;" class="icon has-text-success">
            <span class="fas fa-check" />
          </span>{/if}
            {#if  !$Child.relationship || $Child.relationship ==""} <span style="margin-left: 5px; color:#cc0404;" class="icon">
            <span class="fas fa-times" />
          </span>{/if}
      </p>
              {#if ($Child && $Child.phase != 'N1' && $Child.educationStatus !="") || ($Child && $Child.phase == 'N1' && $Child.multipleBirth && $Child.multipleBirth != "") || ($Child.relationship && $Child.relationship !="")}
      <a class="button"  aria-label="change relationship details" style=" font-size: 1rem;" href="/application/relationdetails/{id}/{applicationId}" >
        Change details
      </a>
      {/if}
      </div>
      <div class="panel-block">

      <div class="column">      
        Relationship:
        <strong>{#if !$Child.relationship }Not set {:else} {RelationshipOptions.find((r) => r.value == $Child.relationship).label}{/if}</strong>
        <br />
        Parental responsibility: 
        <strong>
         {#if $Child.responsibility == null} Not Set  {:else if $Child.responsibility == 'Y'}Yes{:else}No{/if}
        </strong>        
        </div>
      </div>
      <div
        class="panel-heading"
        style="display: flex;align-items: stretch;font-size: inherit;">
        <p style="display: flex;flex-grow: 1;align-items: center;">
          Second contact
          {#if $Child.scSkip || $Child.scForename != null } <span style="margin-left: 5px;" class="icon has-text-success">
            <span class="fas fa-check" />
          </span>{:else}
            <span style="margin-left: 5px; color:#cc0404;" class="icon">
            <span class="fas fa-times" />
          </span>{/if}
        </p>
        {#if $Child.scSkip || $Child.scForename != null || ($Child.relationship && $Child.responsibility != "") }
        <a class="button"  aria-label="change second contact" style=" font-size: 1rem;" href="/application/secondcontact/{id}/{applicationId}" >
          Change details
        </a>
        {/if}

      </div>
      <div class="panel-block">

        <div class="column">
        {#if !$Child.scSkip || $Child.scSkip == 'N'}
          Full name:
          <strong>
          {#if $Child.scForename != null && $Child.scForename != ''}
            {$Child.scForename + ' ' + $Child.scSurname}
          {:else}Not set (Optional){/if}
          </strong>
          <br />
          Relationship:
          <strong>
            {#if $Child.scRelationship != null && $Child.scRelationship != ''}
             {RelationshipOptions.find((r) => r.value == $Child.scRelationship).label} 
            {:else}Not set (Optional){/if}
          </strong>
          <br />
        {/if}
        {#if $Child.scForename == null || $Child.scForename == ''}
          Step completed:
          {#if $Child.scSkip == null || $Child.scSkip == "Y"} 
            <strong> No</strong>
            {#if $Child.scSkip == "Y"}
              <br/>
              <p style="margin-bottom:0px;">Entering a Second Contact is optional.</p>
            {/if} 
          {:else}
            <strong> Yes </strong>
          {/if}
        {/if}
        </div>

      </div>
    {/if}
  </nav>

  <div>
    <button on:click={findNextStep} class="button is-primary">
      Save and continue
    </button>
  </div>
</div>
{#if showHelpModal}
  <Modal bind:showModal={showHelpModal}>
    <Help
      {helpId}
      showSearchOption={false}
      showContent={false}
      doAnimation={false}
      isPopup={true} />
    <!-- <footer class="modal-card-foot buttons is-right" slot="footer">
      <button
        class="button continue"
        on:click={() => {
          showHelpModal = false;
        }}>
        Close
      </button>

    </footer> -->
  </Modal>
{/if}
