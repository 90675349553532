<script>
  import { Child, HelpSummary } from "../components/application/store.js";
  import { GetChild } from "../components/application/service.js";
  import ChildSummary from "../components/application/childSummary.svelte";
  import ChildDetails from "../components/application/childdetails.svelte";
  import ChildAttributes from "../components/application/childadditionaldetails.svelte";
  import RelationDetails from "../components/application/RelationDetails.svelte";
  import Address from "../components/application/address.svelte";
  import CurrentSchool from "../components/application/currentschool.svelte";
  import SecondContact from "../components/application/secondcontact.svelte";
  import Documents from "../components/application/documents.svelte";
  import {
    beforeUpdate,
    afterUpdate,
    createEventDispatcher,
    setContext
  } from "svelte";
  const dispatch = new createEventDispatcher();
  import Preferences from "../components/application/preferences.svelte";
  import TempFinder from "../components/applicationpreference/schoolpicker.svelte";
  import Submit from "../components/application/submit.svelte";
  import Modal from "../components/HTMLElements/Modal.svelte";
  import Help from "../components/help.svelte";
  import wrappedFetch from "../wrappedFetch.js";
  export let isLoading = false;
  export let step = "";
  export let childId = "";
  export let applicationId;
  export let showContextualHelpModal = false;
   let applications=[];
   let lateApplications=[];
  let showApplicationResubmit = false;;
  //export let isSibling = true;
  let isIndexed = false;
  let showContent = false;
  let startAt;
  let endAt;
  let helpId;
  let doAnimation = true;
  let navigateUrl;


  let child;
  let childApps=[];
  

  $: showConfirmationPopup = step ? false : true;

  //setContext('applicationStore',{showTooFewQuestion:true,showTowerHamletQuestion:true});

  afterUpdate(() => {
    if(child){
      setTimeout(attachListener, 0);
    }
  });
  
  window.onbeforeunload = function() {
      if (showConfirmationPopup)
        return "Are you sure you want to leave? By leaving any unsaved changes to application will be lost. ";
    };
  const attachListener =() =>{
    var elements = document.querySelectorAll("input, select");
    elements.forEach(el => {
      el.removeEventListener("change",()=>{});
      el.addEventListener("change", function() {
        showConfirmationPopup = true;
      });
    });
  }
  export const saveAndExit = () => {    
    let appList = childApps.length>0 ? childApps[0].applications:null;
    let app =appList? appList.filter(a=> a.applicationId == applicationId)[0]:null;

    // let appList = childApps[0].applications;
    // let app = appList.filter(a=> a.applicationId == applicationId)[0];
    if(!applications)
    if (IS_DEV) console.log("applications", applications);
    if (IS_DEV) console.log("appList", appList);

     if(app && applications.length == 0)
      applications.push(app);

      
  
    if(step=='preferences' && applications.length > 0 && app  && app.applicationStatus == 'Submitted with unsubmitted edits')
     {
       navigateUrl =  "/user/home";
       showApplicationResubmitModal = true;
      return;
     }
     if(saveAndExitApp)
      saveAndExitApp.saveAndExit();
    
  };
  export const navigate = e => { 
    let appList = childApps.length > 0 ? childApps[0].applications : null;
    let app = appList ? appList.filter(a=> a.applicationId == applicationId)[0]: null;
    if(applications.length == 0)
      applications.push(app);

    if(step=='preferences' &&  (app && app.applicationStatus == 'Submitted with unsubmitted edits'))
    {
      navigateUrl = e.detail;
      showApplicationResubmitModal = true;
      return;
    }
    if (showConfirmationPopup) {
      if ( confirm("Are you sure you want to leave? By leaving any unsaved changes will be lost.") )
        dispatch("pageChange", e.detail);
      else e.preventDefault();
    }
     else 
     dispatch("pageChange", e.detail);
  };

  let saveAndExitApp;
  let Route = "";//ChildDetails;

  const getHelpSummary = async () => {
    const response = await wrappedFetch("helpcontent/getsummary");
    HelpSummary.set(await response.json());
  };

  const getChild =async  () => {
    let response =  await wrappedFetch(`/child/getchildren?childId=${childId}&x=${new Date().getTime()}`).then(
      response => response.json()
    );    
    return response.children;
  };

  if ($HelpSummary.length < 1) getHelpSummary();

  let childPromise;

  if (!applicationId || applicationId == "null") { applicationId = 0;}

  $: {
    childPromise =  GetChild(childId);

    Promise.all([childPromise,getChild()] ).then(values =>{
      child = values[0]
      childApps = values[1]
      
      switch (step) {
        case "childdetails":
          helpId = "Help18";
          isIndexed = true;
          startAt = 2;
          endAt = 8;
          Route = ChildDetails;
          break;

          case "childattributes":
          helpId = "Help18";
          isIndexed = true;
          startAt = 14;
          endAt = 16;
          Route = ChildAttributes;
          break;

        case "relationdetails":
          helpId = "Help18";
          isIndexed = true;
          startAt = 10;
          endAt = 12;
          Route = RelationDetails;
          break;

        case "currentschool":
          helpId = "Help18";
          isIndexed = true;
          startAt = 18;
          endAt = 18;
          showContent = true;
          Route = CurrentSchool;
          break;

        case "secondcontact":
          helpId = "Help38";
          showContent = true;
          Route = SecondContact;
          break;

        case "childsummary":
          Route = ChildSummary;
          break;

        case "preferences":
          if (!canAccessPreference()){
            dispatch("pageRedirect", {url:'/user/home/',text:'cannotAccessPreference'});
            return;
          }
          helpId = "Help8";
          isIndexed = false;
          Route = Preferences;
          showContent = true;
          break;

        case "documents":
          if (!canAccessDocuments())
            dispatch("pageRedirect", {url:'/user/home/'});
          else
            Route = Documents;
          break;

        case "submit":
          if (!canAccessSubmit())
            dispatch("pageRedirect", {url:'/user/home/'});
          else Route = Submit;
          break;
        
      }

    })
  }

  const confirmNav = substep => {
    var path = "";
    if (substep != "" && substep != step) {
      path = `/application/${substep}/${childId}/${applicationId}`;

      if (step == "submit" || !showConfirmationPopup)
        dispatch("pageChange", path);
      else if (showConfirmationPopup)
        if (confirm("Are you sure you want to leave? By leaving any unsaved changes will be lost."))
          dispatch("pageChange", path);
    }
  };

  const canAccessPreference = (a,b) =>{

    if (childId == 0)
      return false;

    if (applicationId == 0 && childId > 0 && child.canApply && (child.scSkip || (child.scForename != ""  && child.scSurname != "")))
      return true;
    else if (applicationId == 0)
      return false;

    let appList = childApps[0].applications;
    let app = appList.filter(a=> a.applicationId == applicationId)[0];

    if (applicationId > 0 && childId > 0 && app.isSchoolEditable)
      return true;
      
    return false;
  }

  const canAccessSubmit = (a, b)=>{
    
    if (applicationId == 0 || childId == 0)
      return false;

    let appList = childApps[0].applications;   
    let app = appList[0];

    if (app.isResubmitable || app.isSchoolEditable)
      return true;

    return false;
  }

   const canAccessDocuments = (a, b)=>{
      if (applicationId == 0 || childId == 0)
        return false;

      let appList = childApps[0].applications;   
      let app = appList.filter(a=> a.applicationId == applicationId)[0];

      if (app.isUploadVisible || app.isUploadRemoveVisible)
        return true;

      return false;
   }
   const canDeleteApplication=()=>{
     return true;
   }
let showApplicationResubmitModal = false;
  //  const showApplicationResubmitModal = () =>{

  //    showApplicationResubmit = true;
  //  }
</script>

<style>
  .notAllowed{
    cursor: not-allowed;
  }
</style>
 <svelte:head>
  <title>Application | eAdmissions</title>
</svelte:head>

{#if child &&  Route!=""}
  <section class="section application">
    <div class="container">
      <div class="columns is-centered is-desktop">
        <div class="column is-10-desktop">
          {#if childApps }
         
            <div class="table-container">
              <ul
                class="steps is-balanced has-content-centered is-horizontal"
                style="list-style:none;">
                <li
                  class="steps-segment {step == 'childdetails'  || step == 'childattributes'  || step == 'relationdetails' || step == 'currentschool' || step == 'secondcontact' || step == 'childsummary' ? 'is-active' : ''}">
                  <a
                    on:click={() => {
                      confirmNav(child.scSkip ? 'childsummary' : 'childdetails');
                    }}
                    class="steps-marker" />
                  <div class="steps-content">
                    <a
                      style="color: inherit;"
                      on:click={() => {
                        confirmNav(child.scSkip ? 'childsummary' : 'childdetails');
                      }}
                      class="is-size-4">
                      Details
                    </a>
                  </div>
                </li>

                <li
                  class="steps-segment {step == 'preferences' ? 'is-active' : ''}">
                  <a
                    on:click={() => {
                      confirmNav(canAccessPreference() ? 'preferences' : '');
                    }}
                    class="steps-marker {canAccessPreference() ? '' : 'notAllowed'}" ></a>
                  <div class="steps-content">
                    <a
                      style="color: inherit;"
                      on:click={() => {
                        confirmNav(canAccessPreference() ? 'preferences' : '');
                      }}
                      class="is-size-4 {canAccessPreference() ? '' : 'notAllowed'}">
                      Preferences
                    </a>
                  </div>
                </li>
                <li class="steps-segment {step == 'submit' ? 'is-active' : ''}">
                  <a
                    on:click={() => {
                      confirmNav(canAccessSubmit() ? 'submit' : '');
                    }}
                    class="steps-marker {canAccessSubmit() ? '' : 'notAllowed'}" />
                  <div class="steps-content">
                    <a
                      style="color: inherit;"
                      on:click={() => {
                        confirmNav(canAccessSubmit() ? 'submit' : '');
                      }}
                      class="is-size-4 {canAccessSubmit() ? '' : 'notAllowed'}">
                      Submit
                    </a>
                  </div>
                </li>
                <li
                  class="steps-segment {step == 'documents' ? 'is-active' : ''}">
                  <a
                    on:click={() => {
                      confirmNav(canAccessDocuments() ? 'documents' : '');
                    }}
                    class="steps-marker {canAccessDocuments() ? '' : 'notAllowed'}" />
                  <div class="steps-content">
                    <a
                      style="color: inherit;"
                      on:click={() => {
                        confirmNav(canAccessDocuments() ? 'documents' : '');
                      }}
                      class="is-size-4 {canAccessDocuments() ? '' : 'notAllowed'}"
                      id="documents">
                      Documents
                    </a>
                  </div>
                </li>
              </ul>
            </div>
         
 {/if}
          <svelte:component
            this={Route}
            id={childId}
            {applicationId}
            {childApps}
            isSibling={child.isSibling}
            bind:this={saveAndExitApp}
            on:pageChange={event => dispatch('pageChange', event.detail)}
            on:pageRedirect={event => dispatch('pageRedirect', event.detail)}
            on:notification={event => dispatch('notification', event.detail)}
            on:showContextualHelpModal={() => (showContextualHelpModal = true)}
            on:showApplicationResubmitModal={(event)=>{showApplicationResubmitModal = true; navigateUrl = event.detail}}
            bind:applications
            bind:lateApplications
            on:back={event => ( navigate(event) )} 
            on:changed={attachListener}
            bind:isLoading={isLoading}/>
          
            
        </div>
      </div>
      <div class="columns is-centered is-desktop">
        <div class="column is-7-tablet is-8-desktop is-8-widescreen" />
      </div>

    </div>
  </section>
{/if}

<Modal
  bind:showModal={showContextualHelpModal}
  on:closing={() => (doAnimation = false)}>
  <Help
    {helpId}
    showSearchOption={false}
    {showContent}
    doAnimation={false}
    {isIndexed}
    {startAt}
    {endAt}
    isPopup={true} />
  <!-- <footer class="modal-card-foot buttons is-right" slot="footer">
    <button
      class="button continue"
      on:click={() => {
        showContextualHelpModal = false;
      }}>
      Close
    </button>

  </footer> -->
</Modal>

<Modal bind:showModal={showApplicationResubmitModal}>
  {#if applications.length > 1 || (childApps && childApps[0].isSibling)}
    <div style="padding: 1em;">
      <h1 class="title is-4"> You must resubmit following application(s) </h1>

      {#each applications as a}
        <p><span style="padding-left:1em;">{a.reference}</span></p>
        <br/>
      {/each}
       
      {#each lateApplications as la}
        <p><span style="padding-left:1em;">{la.reference}</span></p>
        <br/>
      {/each}
    </div>
  {/if}

  {#if applications.length == 1 }
    <div style="padding: 1em;">
      <h1 class="title is-4"> You must resubmit this application. </h1>
      <p> Please select "Continue" to carry on making changes to your application or "Resubmit" to submit your changes.</p>
    </div> 
  {:else if  applications.length > 0}
    <div style="padding: 1em;">
      <h1 class="title is-4"> You must resubmit this application. </h1>
      <p> Please select "Continue" to carry on making changes to your application or resubmit application from your home page. </p> 
    </div>
  {/if}

  {#if lateApplications.length > 0}
  <div style="padding: 1em;">
      <h1 class="title is-4">Local Authorities notified</h1>
    <p>The relevant Local Authorities have been notified about the changes you have made. Please contact the <a href='https://www.eadmissions.org.uk/contact/' target='_blank' style='text-decoration:underline'>Authority</a> if the child has moved home address.</p><br/>
  </div>
  {/if}
  
  <footer class="modal-card-foot buttons is-right" slot="footer">
    <button
      class="button continue is-primary"
      on:click={() => {
        showApplicationResubmitModal = false;
        dispatch('pageChange',navigateUrl);
      }}>
      Continue
    </button>
{#if  applications.length > 0 }
    <button  
      class="button continue is-purple" 
      on:click={()=>{    
        showApplicationResubmitModal = false;  
        if(applications.length == 1)  
          dispatch('pageChange', `/application/submit/${applications[0].childId}/${applications[0].id}`);
        else      
        dispatch('pageChange', '/user/home'); 
      }    
    }>
      Resubmit
    </button>
    {/if}
  </footer>
</Modal>
