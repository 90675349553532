<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  let error = "";
  let showPassword = false;

  export let text = "";
  export let isRequired = true;
  export let maxLength = 524288;
  export let isValid = text == "" && !isRequired;
  export let placeholder = "";
  export let isDirty = false;
  export let label = "";
  export let faIcon = null;
  export let isPassword = false;
  export let disabled = null;
  export let readoutLabel = null;
  export const getValidity = () => {
   
    if (text == "" && isRequired) {
      return false;
    } else if (text.length > maxLength) {
      return false;
    }
    return true;
  };
  export let ref = null;

  $: {
    isValid = true;
    error = "";
    if ((isRequired == true || isRequired == "true") && (text == "" || text == null )) {isValid = false; }


    if (isDirty) {      
      if ((text === "" || !text) && (isRequired == true || isRequired == "true")) {
        isValid = false;
        error = "Please enter " + label;
      } else if ( text && text.length > maxLength) {
        isValid = false;
        error = "Maximum length can be " + maxLength + " characters.";
      } else isValid = true;
    }
  } 

   const togglePassword = inputName => {
      showPassword = !showPassword;
      if (showPassword) inputName.type = "text";
      else inputName.type = "password";
  
  };
  const onKeyup = (e) => {
    dispatch('keyup', e);
  }
</script>

<style>
  .icon {
    pointer-events: initial !important;
    cursor: pointer;
  }
  input:disabled{
    border: 1px solid lightgray;
  }
</style>

{#if isPassword}
  <input
  aria-label={readoutLabel ? readoutLabel : label}
  class="input {!isValid && isDirty ? 'is-danger' : ''}"
  type="password"
  style="padding-top: 0.5rem;"
  bind:value={text}
  on:blur={() => {
    if (!isDirty)
      isDirty = text != "";
  }}
  on:keyup={onKeyup}
  {maxLength}
  {placeholder} 
  bind:this={ref}
  label="password"
  />
  {#if showPassword}
        <span
          class="icon is-small is-right fas fa-eye"
          aria-hidden="true"
          on:click={() => {           
            togglePassword(ref);
          }}
        />
      {:else}
        <span
          class="icon is-small is-right fas fa-eye-slash"
          aria-hidden="true"
          on:click={() => {            
            togglePassword(ref);
          }} 
      />
        {/if}
  {:else}

  <input
    aria-label={readoutLabel ? readoutLabel : label}
    class="input {!isValid && isDirty ? 'is-danger' : ''}"
    type="text"
    bind:value={text}
    on:blur={() => {
      text = (text && text.length > 0) ?  text.trim() : text;
      if (!isDirty) {
        isDirty = (text &&  text != '') ? true : false;
      }
      dispatch('blur');
    }}
    on:keyup={onKeyup}
    {maxLength}
    {placeholder}
    bind:this={ref} 
    autocomplete="off"
    list="autocompleteOff"
    disabled={disabled}
    />
{/if}

{#if faIcon}
  <span class="icon is-small is-left {faIcon}" />
{/if}
{#if !isValid && isDirty}
  <span>
    <p class="help has-text-danger">{error}</p>
  </span>
{/if}
