<script>
    import {onMount,createEventDispatcher} from "svelte";
  import {SupportUser,Response,isFormAlreadySubmitted } from "./store.js";
  import Steps from "./steps.svelte";
  import Field from "../../components/FormField.svelte";
  import TextBox from "../../components/HTMLElements/Textbox.svelte";
  import Email from "../../components/HTMLElements/Email.svelte";
  import Phone from "../../components/HTMLElements/PhoneNumber.svelte";
  import * as objectWrapper from "../../components/Utils/ObjectWrapper.js";
  import * as validate from "../../components/Utils/ValidateFormFIelds.js";
  const dispatch = new createEventDispatcher();
  let errMsg="";
  let wrappedObj ;
  let userObject={forename:"",surname:"",email:"",confirmEmail:"",mobile:"",postcode:""} 
  let isPageLoading = true;
 const gotoNext = ()=>{
     
    if (!validate.validate(wrappedObj)) {
        errMsg = "Please check required fields have valid data.";
        wrappedObj = wrappedObj;      
        return;
    }
        $Response.forename = wrappedObj.forename.value
        $Response.surname =  wrappedObj.surname.value
        $Response.email = wrappedObj.email.value
        $Response.mobile=wrappedObj.mobile.value
        $Response.postcode=wrappedObj.postcode.value
        $Response.step3=true;       
      
    dispatch('pageChange', '/support/step4')
 }
  onMount(()=>{ 
      if(!$Response.step2 || isFormAlreadySubmitted())
      dispatch('pageChange','/support/step2')
      if($Response.step3)
        userObject = {forename:$Response.forename,surname:$Response.surname,email:$Response.email,confirmEmail:$Response.email,mobile:$Response.mobile,postcode:$Response.postcode}
   else if($SupportUser != null)
  {
      userObject = {forename:$SupportUser.forename,surname:$SupportUser.surname,email:$SupportUser.email,confirmEmail:$SupportUser.email,mobile:$SupportUser.mobile,postcode:$SupportUser.postcode}
  }
  wrappedObj = objectWrapper.wrapObject(userObject);
  if(wrappedObj.confirmEmail.value)wrappedObj.confirmEmail.isDirty = true;
  
  
  isPageLoading=false;
})

</script>
<style>
  .column{
    padding: 0rem 0.75rem;
  }
  .columns{
    margin-right: 0rem;
    margin-left: 0px;
  }
</style>
{#if !isPageLoading}
    <Steps backUrl='/support/step2' buttonText="Next" bind:isPageLoading  on:handleNext={()=>gotoNext()} on:pageChange={event => dispatch('pageChange', event.detail)}>
      
        <div class="columns is-desktop">
            <div class="column">
                <Field label="Your First Name">
                    <span slot="inputField">
                      <TextBox
                        placeholder="Your First Name"
                        isRequired={true}
                        bind:text={wrappedObj.forename.value}
                        bind:isValid={wrappedObj.forename.isValid}
                        bind:isDirty={wrappedObj.forename.isDirty}
                        label="user first name" />
                    </span>
                  </Field>
            </div>
            <div class="column">
                <Field label="Your Last Name"  isRequired={true}>
                    <span slot="inputField">
                      <TextBox
                        placeholder="Your Last Name"
                        isRequired={true}
                        bind:text={wrappedObj.surname.value}
                        bind:isValid={wrappedObj.surname.isValid}
                        bind:isDirty={wrappedObj.surname.isDirty}
                        label="user last name" />
                    </span>
                  </Field> 
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <Field label="Email Address">
                    <span slot="inputField">
                <Email
                placeholder="Email Address"
                isRequired={true}
                bind:email={wrappedObj.email.value}
                bind:isValid={wrappedObj.email.isValid}
                bind:isDirty={wrappedObj.email.isDirty}
             
                label="email address" />
            </span>
        </Field>
            </div>
            <div class="column">
                <Field label="Confirm Email Address">
                    <span slot="inputField">
                      <Email
                        placeholder="Confirm Email Address"
                        isRequired={true}
                        bind:email={wrappedObj.confirmEmail.value}
                        bind:isValid={wrappedObj.confirmEmail.isValid}
                        bind:isDirty={wrappedObj.confirmEmail.isDirty}
                        bind:emailToCompare={wrappedObj.email.value}
                        label="confirm email address" />
                    </span>
                  </Field>        
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <Field label="Contact Number" isRequired={true} >
                    <span slot="inputField">
                      <Phone
                        placeholder="Contact number"
                        isRequired={'true'}
                        bind:number={wrappedObj.mobile.value}
                        bind:isValid={wrappedObj.mobile.isValid}
                        bind:isDirty={wrappedObj.mobile.isDirty}
                        isMobile="false"
                        label="mobile phone number" />
                    </span>
                  </Field> 
            </div>
            <div class="column"></div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <Field label="Home Post Code">
                    <span slot="inputField">
                      <TextBox
                        placeholder="Home Post Code"
                        isRequired={true}
                        bind:text={wrappedObj.postcode.value}
                        bind:isValid={wrappedObj.postcode.isValid}
                        bind:isDirty={wrappedObj.postcode.isDirty}
                        label="postcode" />
                    </span>
                  </Field>
            </div>
            <div class="column"></div>
            </div>
      
        {#if errMsg != ''}
               
        <p id="errMsg" class="help is-danger">
          {@html errMsg}
        </p>
    
      {/if}   
     </Steps>
     {/if}

    