<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { User } from '../store.js'
    import wrappedFetch from "../wrappedFetch.js";
    import Feedback from "./Feedback.svelte";
    const dispatch = createEventDispatcher();
    
    export let active = '';
    export let applicationSubstep = '';
    let width = 0;
    let navMenu ;
    let showFeedback = false;
    let version = "";
		let devs = "";

    const signOut = () => {

         wrappedFetch('user/logout', {
            method: 'post'
        })
        .then(response => {
            $User = null;
            dispatch("pageChange","/")
        })    
    }

    function burgerMenu(){
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
        const dropDownMenu = document.querySelector('#dropdown-menu');

        // Check if there are any navbar burgers
        if ( $navbarBurgers.length > 0) {

          $navbarBurgers.forEach( el => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            el.classList.toggle('is-active');
            document.getElementById(target).classList.toggle('is-active');
          });
        }
    }

    const saveAndExit = () => {
        dispatch('saveAndExit', applicationSubstep);
    }
    let path;
    const confirmNav = (e) => {
      if(applicationSubstep != '' && applicationSubstep != 'submit' &&  applicationSubstep != 'summary' && applicationSubstep != 'delete'){
        dispatch('navigate', path);
      }
      else if(active=="support" && !confirm('Are you sure you want to leave? By leaving changes to this form will be lost.'))
	{

	}
	else
	dispatch('pageChange', path);
	}


	const getVersion = async () => {

	let result = await wrappedFetch("uso/AppVersion"); // resolve how to do this without being logged in (no authentication)
	version = await result.text();
	console.log("uso test version: --[" + version + "]--");
	result = await wrappedFetch("uso/Test");
	devs = await result.text();
	devs ?? "unknown";
	var host = document.location.host;
	host ?? "xxx.xxx.xxx";
	console.log("host: " + host);
	console.log("devs: " + devs);
	devs = host + "/" + devs;
	if (typeof(version) === "undefined" || (typeof(version) === "string" && version.length === 0))
			{ version = "2.3.x.x"; }
	};


	onMount(async () => { getVersion(); });

</script>
<style>
.navbar-item, .navbar-link{
    color: #6f4077 !important;
}
.navbar-dropdown{
    background-color: #6f4077 !important;
}

.navbar-dropdown > .navbar-item{
    color: white !important;
}

.navbar-dropdown > .navbar-item:hover{
    background-color: white;
    color: #6f4077 !important;
}
.version-num { 
    color: grey;
    font-size: xx-small;
}


    .active{
        border-bottom: 1px solid #6f4077;
    }
    .faIcon{
        padding-right: 5px;
    }

      .navbar-start a.navbar-item:hover,
        .navbar-start a.navbar-item.active {
            border-bottom: 1px solid #673c6f;
            padding-left: 0rem;
            padding-right: 0rem;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            padding-bottom: 0.5rem;
        }



        .navbar-burger{
            color: black;
        }

        @media screen and (max-width: 1023px) {
            .navbar-menu{
                background-color:  #fafafa;
            }
        }

    .button{
        margin-top: .5rem;
        margin-right: 0.25rem;
        display: flex;
        font-size: 1rem;
    }
</style>
<svelte:window bind:innerWidth={width}></svelte:window>
<nav class="navbar">
  <div class="navbar-brand">
    <a class="navbar-item" on:click={(e) => {path = (!$User) ? '/' : "/user/home"; burgerMenu(); confirmNav(e);}}>
      <img alt="eAdmissions Logo" src="logo.png" width="175" height="40">
      <span class="version-num">{version}</span>
    </a>
		<label id="devs" hidden="hidden">{devs}</label>
		{#if applicationSubstep != '' && applicationSubstep != 'summary' && applicationSubstep != 'delete'}
      
        {#if width >= 480}
          <a tabindex='0' aria-label="Save And Exit" role="button" href="javascript:;" on:click={saveAndExit} 
             style="position: absolute; right: 0px; margin-left: 10px; margin-right: 10px;">
            <span class="button is-purple" ><span class="faIcon fas fa-times-circle">
              </span> Save and Exit
            </span> 
          </a>
        {:else}
        <a tabindex='0' aria-label="Save And Exit" role="button" href="/user/home" on:click={saveAndExit} style="margin-left: 10px; margin-right: 10px;">
          <svg class="svg-inline--fa fa-times-circle fa-w-16" 
              style="width: 20px; height: 20px; color: #6f4077; position: absolute; right: 18px; top: 15px;" 
              aria-hidden="true"
              data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z">
            </path>
          </svg>
        </a>
        {/if}
    {:else}
      <span class="navbar-burger is-light" data-target="navMenu" on:click={burgerMenu}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </span>
    {/if}
  </div>
  <div id="navMenu" class="navbar-menu" bind:this={navMenu}>
    <div class="navbar-start">
      <a tabindex="0"
        on:click={(e)=>{ path = (!$User) ? '/' : "/user/home"; burgerMenu(); confirmNav(e);}} 
        on:keypress={(e)=>{ if(e.keyCode == 13) {path = (!$User) ? '/' : "/user/home"; burgerMenu(); confirmNav(e);}}} 
        class="navbar-item {active === 'home' || active === 'user' ? 'active' : ''}">
        <span class="faIcon fas fa-home"/>Home
      </a> 

      <a tabindex='0'
         on:click={(e)=>{ path='/help'; burgerMenu(); confirmNav(e); }}
         on:keypress={(e)=>{ if(e.keyCode == 13) {path='/help'; burgerMenu(); confirmNav(e);} }}
         class="navbar-item {active === 'help' || active == 'guidance' || active == 'faq' || active == 'info' || active =='importantdates'  || active =='howtoregister' ? 'active' : ''}">
        <span class="faIcon fas fa-question-circle"/>Help and Information
      </a>
      <a tabindex='0'
         on:click={(e)=>{path="/about"; burgerMenu(); confirmNav(e); }}  
         on:keypress={(e)=>{if(e.keyCode == 13){ path="/about"; burgerMenu(); confirmNav(e);} }}  
         class="navbar-item {active === 'about' || active == 'copyright'|| active == 'disclaimer'|| active == 'privacy'|| active == 'accessibility'? 'active' : ''}">
        <span class="faIcon fas fa-info-circle " />About
      </a>

      <a tabindex='0' role="link" 
         on:click={(e)=>{path="/contact"; burgerMenu(); confirmNav(e); }}
         on:keypress={(e)=>{ if(e.keyCode == 13){ path="/contact"; burgerMenu(); confirmNav(e);} }}
         class="navbar-item {active === 'contact' ? 'active' : ''}">
        <span class="faIcon fas fa-clipboard-list" />Local Authority Details
      </a>
    </div>
    <div class="navbar-end">
        {#if $User }
          <div style="display:none"><span id="username">{$User.username}</span></div>
          {#if applicationSubstep != '' && applicationSubstep != 'summary' && applicationSubstep != 'delete'}
          <a tabindex='0' aria-label="Save And Exit" role="button" class="button is-purple" href="javascript:;" on:click={saveAndExit} style="margin-left: 10px; margin-right: 10px;">
            <span class="faIcon fas fa-times-circle"></span> Save and Exit
          </a>
          {:else}
          <a class="navbar-item has-dropdown  is-hoverable" href="javascript:;">
            <div class="navbar-link" id="menulink" >
              <div id='usermenu' tabindex='0'  aria-haspopup="true" aria-controls="dropdown-menu" style="margin-left: 10px;">
                <span class="faIcon fas fa-user" />
                <span id="username">{$User.username}</span>
              </div>
            </div>
            <div class="navbar-dropdown" id="dropdown-menu" aria-labelledby="usermenu" role="menubar">
                
                <a tabindex='0' role="menuitem" href="javascript:;" 
                  on:click={()=>{/*console.log('update details');*/ burgerMenu(); dispatch("pageChange","/useraccount/details")}}
                  on:keypress={(e) => {if(e.keyCode == 13){burgerMenu(); dispatch('pageChange', '/useraccount/details')}}}
                  class="navbar-item" >
                    Update my details
                </a>
                <a tabindex='0' role="menuitem" href="javascript:;"
                   on:click={()=>{/*console.log('change password');*/ burgerMenu(); dispatch("pageChange","/useraccount/changepassword")}}
                   on:keypress={(e) => {if(e.keyCode == 13){burgerMenu(); dispatch('pageChange', '/useraccount/changepassword')}}}
                   class="navbar-item" >
                    Change Password
                </a>
                {#if $User && $User.rating && $User.rating=="0"}
                  <a tabindex='0' role="menuitem" href="javascript:;"
                   on:click={()=>{/*console.log('change password');*/ burgerMenu(); showFeedback = true;}}
                   on:keypress={(e) => {if(e.keyCode == 13){burgerMenu(); showFeedback = true;}}}
                   class="navbar-item" >
                   Feedback
                </a>
                {/if}
                <a tabindex='0' role="menuitem" href="javascript:;" class="navbar-item" 
                   on:click={signOut}
                   on:keypress={(e) => {if(e.keyCode == 13) signOut()}}>
                    Sign Out
                </a>
            </div>
          </a>
          {/if}
        {:else}
        <div style="display: flex;">
            <a tabindex='0' aria-label="Register" class="button is-purple" href="/register/start" style="margin-left: 0.5rem;">
              <span class="faIcon fas fa-user-plus"></span> Register
            </a>
            <a tabindex='0' aria-label="Sign In" class="button is-primary"  href="/signin" style="background-color: #1b7b81; color:white; margin-right: 0.5rem;">
              <span class="faIcon fas fa-lock"></span> Sign In
            </a>
        </div>
        {/if}
    </div>
  </div>
</nav>
<Feedback bind:showModal={showFeedback}/>
