<script>
  import { createEventDispatcher,getContext } from "svelte";
   /* Removed reference to ShowTowerHamletQuestion 01/08/2024
   import { ShowTooFewSchoolWarning,  ShowTowerHamletQuestion, dateToWords } from "./store.js";*/
	import { ShowTooFewSchoolWarning,  dateToWords } from "./store.js";
  import CheckBox from "../HTMLElements/CheckBoxInput.svelte";
  import Modal from "../../components/HTMLElements/Modal.svelte";
  import ModalMessage from "../../components/HTMLElements/ModalMessage.svelte";
  import Notification from "../../components/HTMLElements/Notification.svelte";
  import Help from "../../components/help.svelte";
  import TooFewSchools from "./fewpreferences.svelte";
  /* Removed 01/08/2024
     import TowerHamletQuestions from "./towerhamletquestion.svelte";
   */
  import wrappedFetch from "../../wrappedFetch.js";
  import { User } from "../../store.js";
  export let id;
  export let applicationId;
  export let isLoading = true;
  let showMessage = false;
  let modalMessage;
  let applicationResponse;
  let imagePath = "images/logos/logo_";
  let declaration = false;
  let laDeclaration = false;
  let GDPRDeclaration = false;
  let laUrl = "";
  let laName = "";
  let error = "";
  let responseError = "";
  let updatingApplication = false;
  let showHelpModal = false;
  let helpId = "Help7";
  let showTooFewSchoolsPopUp = false;
  let isTowerHamlet = false;
 // let showTowerHamletQuestion = false;
  let details;
  let answerDetails;
  let genderDetail;
  let allowClose = false;
  let isApplicationEHCPBarred = false;
  const CurrentSchoolNurseryLabel = "Child's current school/nursery";
  const CurrentSchoolNurseryEnteredLabel = "Current school/nursery name entered";
  const dispatch = new createEventDispatcher();

  export const saveAndExit = async () => {
    dispatch("pageChange", `/user/home`);
  }

  const getApplicationDetails = () => {
        isLoading = true;
    return wrappedFetch("child/getapplicationsummary?applicationId=" + applicationId + "&x=" + new Date().getTime())
      .then(response => response.json())
      .then(result => {
        if(result.preferences.length < 1)
        dispatch('pageChange',`application/preferences/${id}/${applicationId}`)

         details = result;
        if(details.preferences.length < details.borough.maxPref)
        showTooFewSchoolsPopUp = true;

        if(details.child.childFlags && details.child.childFlags.phaseId == 5 && details.applicationLaid == '211')
        {
          answerDetails = details.laAnswers;
         // isTowerHamlet = true;
          genderDetail =details.child.gender == 'M'? "son":"daughter";
        //  showTowerHamletQuestion = !showTooFewSchoolsPopUp && $ShowTowerHamletQuestion;
        }
        laUrl = details.laUrl;
        laName = details.laDisplayName;
        declaration = details.applicationVersion > 0;
        laDeclaration = details.applicationVersion > 0;
        GDPRDeclaration = details.applicationVersion > 0;
        isApplicationEHCPBarred = details.child.isApplicationEHCPBarred == "Y";
            isLoading = false;
        return details;
      });
  };
  const submitApplication = async => {
    if (!declaration) error = "You must accept the declaration";
    else if (laUrl != null && laUrl != "" && !laDeclaration)
      error = "You must accept the " + laName + " terms and conditions";
      if(!GDPRDeclaration)
      error = "You must accept the " + laName + " GDPR declarations";
    else error = "";
    if (error == "") {
      _paq.push(['trackEvent', 'Application Submit','Submit button clicked']);
      let form = new FormData();
      form.append("applicationId", applicationId);
      form.append("declaration", declaration);
      form.append("laDeclaration", laDeclaration);
      updatingApplication = true;
      wrappedFetch(`/child/submitApplication`, {
        method: "post",
        body: form
      })
        .then(response => response.json())
        .then(res => {
          responseError = "";
          showMessage = true;

          if (res.response.success) {
            if($User && $User.submittedApplications == 0)
            $User.submittedApplications = 1;
            applicationResponse = res;
          } else {
            allowClose = true;
            responseError = res.response.message;
          }

          updatingApplication = false;
        });
    }
  };
</script>
<style>
    .panel-heading p{
    margin-bottom:0px;
  }

</style>

{#await getApplicationDetails() then details}
{#if details.applicationStatus == 'Submitted'}
<ModalMessage showModal={details.applicationStatus == 'Submitted'}  allowClose={false} buttonText={'Home Page'} on:continue={()=>{dispatch('pageChange','user/home')}}>
<p>
This application has no changes to submit after it has been last submitted!
</p>
</ModalMessage>
{/if}

{#if !isLoading}
  <div class="step-14-shows" style="word-break: break-word;overflow-wrap: break-word;">
     <div class="field">
              <a href="javascript:;" on:click={()=> {dispatch('back', '/user/home')}}>
                <span class="fas fa-arrow-left" />
                Back
              </a>
            </div>
    <div style="display:inline-flex">
      <h1 class="title is-3">{details.pageText[0].text}</h1>
      <span
        name="infoIcon"
        class="icon helpicon"
        on:click={() => {
          helpId = 'Help7';
          showHelpModal = true;
        }}>
        <span class="fas fa-info-circle" />
      </span>
    </div>
    <br/>
    <p><b>{details.phaseInformationDetails}</b></p>
    <p class="subtitle is-5">{@html details.pageText[1].text}</p>
    <div style="display: flex; flex-wrap: wrap;">
      <div style="margin-right:15px;">
        <a href={details.borough.url} target="_blank">
          <img
            src={imagePath + details.applicationLaid + '.jpg'}
            style="border:0;"
            alt={details.borough.displayName} />
        </a>
      </div>
      <div style="word-break: break-all">
        <p style="margin-bottom:0px;">
          <a href={ !details.borough.url.startsWith('http://') && !details.borough.url.startsWith('https://')
                    ? 'http://' + details.borough.url
                    : details.borough.url}
             target="_blank">
            {details.borough.displayName}
          </a>
        </p>
        <p style="margin-bottom:0px;">
          <a href="tel:{details.borough.phasePhone}">
            {details.borough.phasePhone}
          </a>
        </p>
        <p>
          <a href="mailto:{details.borough.phaseEmail}">
            {details.borough.phaseEmail}
          </a>
        </p>
      </div>
    </div>
    <nav class="panel" style="margin-top: 15px;" tabindex="0">
      <div
        class="panel-heading"
        style="display: flex;align-items: stretch;font-size: inherit;">
        <p style="display: flex;flex-grow: 1;align-items: center;">
          Child details
        </p>
        <!-- <a href="application-step1.html?1" class="" aria-label="more options">
          Change
        </a>
        <a href="#" class="" aria-label="more options">
          <span class="icon">
            <span class="fas fa-angle-down" aria-hidden="true" />
          </span>
        </a> -->
      </div>
      <div class="panel-block">
        <div class="column">Child name</div>
        <div class="column">
          <strong>
            {details.child.forename}
            {details.child.middleName ? details.child.middleName : ''}
            {details.child.surname}
          </strong>
        </div>
      </div>
      <div class="panel-block">
        <div class="column">Address</div>
        <div class="column">
          <strong>
            {#each details.childAddress.split(',') as addr}
              {addr}<br/>
            {/each}
          </strong>
        </div>
      </div>
      {#if details.child.councilTaxRef && details.child.councilTaxRef != ''}
        <div class="panel-block">
          <div class="column">Council tax account number</div>
          <div class="column">
            <strong>{details.child.councilTaxRef}</strong>
          </div>
        </div>
      {/if}
      <div class="panel-block">
        <div class="column">Date of birth</div>
        <div class="column">
       <strong>{dateToWords(details.child.dob)}</strong>
        </div>
      </div>
      <div class="panel-block">
        <div class="column">Sex</div>
        <div class="column">
          <strong>{details.child.genderDetail}</strong>
        </div>
      </div>
      <div class="panel-block">
        <div class="column">Multiple births</div>
        <div class="column">
          <strong>
            {#if details.child.multipleBirth == 'Y'}Yes{:else}No{/if}
          </strong>
        </div>
      </div>
      {#if details.child.inCareId > 0 && details.child.inCareAuthorityName != ''}
        <div class="panel-block">
          <div class="column">
            Child is in the care of or has previously been in the care of {details.child.inCareAuthorityName}
          </div>
        </div>
      {/if}
      {#if details.child.statement == 'Y'}
        <div class="panel-block">
          <div class="column">Child has an EHCP</div>
        </div>
      {/if}
      {#if details.child.educationStatus  != ""}
      {#if details.child.educationStatus == 'NOTATSCHOOL'}
        <div class="panel-block">
          <div class="column">The child is not currently at school</div>
        </div>

      { :else if details.child.educationStatus == 'HOME'}
        <div class="panel-block">
          <div class="column">Child is currently being educated at home</div>
        </div>

      { :else if details.child.educationStatus == 'ABROAD'}
        <div class="panel-block">
          <div class="column">Child has been educated abroad </div>
        </div>

      { :else if details.child.educationStatus == 'NOTFOUND' && details.child.currentSchoolNameTyped && details.child.currentSchoolNameTyped != ''}
        <div class="panel-block">
          <div class="column">{CurrentSchoolNurseryEnteredLabel}</div>
          <div class="column">
            <strong>{details.child.currentSchoolNameTyped}</strong>
          </div>
        </div>
      { :else if details.child.educationStatus != "" && details.child.currentSchoolId > 0 && details.child.currentSchoolName != ''}
        <div class="panel-block">
          <div class="column">{CurrentSchoolNurseryLabel}</div>
          <div class="column">
            <strong>{details.child.currentSchoolName || ""}</strong>
            {#if details.child.school &&  details.child.school.address}
           {#each details.child.school.address.split(',') as addr}
              {addr}<br/>
            {/each}
            {/if}
          </div>
        </div>
      {/if}
      {/if}
      {#if details.laAnswers && details.laAnswers != null}
        {#each details.laAnswers.split(';') as list}
          <div class="panel-block">
            <div class="column">{list.split('=')[0]}</div>
            <div class="column">
              <strong>{list.split('=')[1]}</strong>
            </div>
          </div>
        {/each}
      {/if}
      <div class="panel-block">
        <div class="column">Number of schools applied</div>
        <div class="column">
          <strong>{details.preferences.length} out of {details.borough.maxPref}</strong>
        </div>
      </div>
    </nav>

    <nav class="panel" style="margin-top: 15px;"  tabindex="0">
      <div
        class="panel-heading"
        style="display: flex;align-items: stretch;font-size: inherit;">
        <p style="display: flex;flex-grow: 1;align-items: center;">
          Your details
          <span
            name="infoIcon"
            class="icon info-icon"
            on:click={() => {
              helpId = 'Help5';
              showHelpModal = true;
            }}>
            <span class="fas fa-info-circle" />
          </span>
        </p>
        <!-- <a href="application-step1.html?1" class="" aria-label="more options">
          Change
        </a>
        <a href="#" class="" aria-label="more options">
          <span class="icon">
            <span class="fas fa-angle-down" aria-hidden="true" />
          </span>
        </a> -->
      </div>
      <div class="panel-block">
        <div class="column">Name</div>
        <div class="column">
          <strong>
            {details.parentTitle} {details.parentForename}
            {details.parentSurname}
          </strong>
        </div>
      </div>
      <div class="panel-block">
        <div class="column">Relationship</div>
        <div class="column">
          <strong>{details.relationshipDetail}</strong>
        </div>
      </div>
      <div class="panel-block">
        <div class="column">Parental Responsibility</div>
        <div class="column">
          <strong>
            {#if details.child.responsibility == 'Y'}Yes{:else}No{/if}
          </strong>
        </div>
      </div>

      <div class="panel-block">
        <div class="column">Address</div>
        <div class="column">
          <strong>
            {#each details.parentAddress.split(',') as addr}
            {addr}<br/>
            {/each}
          </strong>
        </div>
      </div>

      {#if details.parentWorkPhone && details.parentWorkPhone != ''}
        <div class="panel-block">
          <div class="column">Work number</div>
          <div class="column">
            <strong>{details.parentWorkPhone}</strong>
          </div>
        </div>
      {/if}
      {#if details.parentHomePhone && details.parentHomePhone != ''}
        <div class="panel-block">
          <div class="column">Home number</div>
          <div class="column">
            <strong>{details.parentHomePhone}</strong>
          </div>
        </div>
      {/if}
      {#if details.parentMobile && details.parentMobile != ''}
        <div class="panel-block">
          <div class="column">Mobile number</div>
          <div class="column">
            <strong>{details.parentMobile}</strong>
          </div>
        </div>
      {/if}
      {#if details.parentEmail && details.parentEmail != ''}
        <div class="panel-block">
          <div class="column">Email</div>
          <div class="column">
            <strong>{details.parentEmail}</strong>
          </div>
        </div>
      {/if}
      {#if details.isCrownService == 'Y'}
        <div class="panel-block">
          <div class="column">In Crown Service</div>
          <div class="column">
            <strong>Priority Requested</strong>
          </div>
        </div>
      {/if}
      {#if details.personalDetailAccepted == 'Y'}
        <div class="panel-block">
          <div class="column">Personal details</div>
          <div class="column">
            <strong>Confirmed</strong>
          </div>
        </div>
      {/if}
      {#if details.privacyPolicyAccepted == 'Y'}
        <div class="panel-block">
          <div class="column">Privacy policy</div>
          <div class="column">
            <strong>Accepted</strong>
          </div>
        </div>
      {/if}
      {#if details.child.scSkip && details.child.scSkip != 'Y'}
        {#if details.child.scSurname && details.child.scSurname != null}
          <div class="panel-block">
            <div class="column">Second contact name</div>
            <div class="column">
              <strong>
                {details.child.scForename ? details.child.scForename : ''}
                {details.child.scSurname ? details.child.scSurname : ''}
              </strong>
            </div>
          </div>
        {/if}
        {#if details.child.scSscRelationshipurname && details.child.scRelationship != null}
          <div class="panel-block">
            <div class="column">Second contact relationship</div>
            <div class="column">
              <strong>{details.child.scRelationship}</strong>
            </div>
          </div>
        {/if}
      {/if}
    </nav>

    <!-- school preferences block -->
    <nav class="panel" style="margin-top: 15px;"  tabindex="0">
      {#each details.preferences as preference}
        <div
          class="panel-heading"
          style="display: flex;align-items: stretch;font-size: inherit;">
          <p style="display: flex;flex-grow: 1;align-items: center;">
            {preference.preferenceSummary.ordinalSequence} Preference School
          </p>
          <!-- <a href="application-step1.html?6" class="" aria-label="more options">
            Change
          </a>
          <a href="#" class="" aria-label="more options">
            <span class="icon">
              <span class="fas fa-angle-down" aria-hidden="true" />
            </span>
          </a> -->
        </div>
        <div class="panel-block">
          <div class="column">Address</div>
          <div class="column">
            <strong>
              {preference.preferenceSummary.schoolName}
              <br />
              {#each preference.preferenceSummary.address.split(',') as addr}
                {addr}<br/>
              {/each}
            </strong>
          </div>
        </div>
        {#if preference.sibling}
          <div class="panel-block">
            <div class="column">Sibling name</div>
            <div class="column">
              <strong>
                {preference.sibling.forename}
                {preference.sibling.middleName ? preference.sibling.middleName : ''}
                {preference.sibling.surname}
              </strong>
            </div>
          </div>
          <div class="panel-block">
            <div class="column">Sibling address</div>
            <div class="column">
              <strong>
                {#each preference.sibling.address.split(',') as addr}
                  {addr}<br/>
                {/each}
              </strong>
            </div>
          </div>
          <div class="panel-block">
            <div class="column">Sibling date of birth</div>
            <div class="column">
              <strong>{preference.sibling.dobStr}</strong>
            </div>
          </div>

          <div class="panel-block">
            <div class="column">Sex of sibling</div>
            <div class="column">
              <strong>{preference.sibling.genderDetail}</strong>
            </div>
          </div>
          <div class="panel-block">
            <div class="column">Sibling school</div>
            <div class="column">
              <strong>{preference.sibling.currentSchoolName}</strong>
            </div>
          </div>
        {/if}
        {#if preference.preferenceSummary.staffChild == 'Y'}
          <div class="panel-block">
            <div class="column">Staff Child</div>
            <div class="column">
              <strong>Priority Requested</strong>
            </div>
          </div>
        {/if}
        {#if preference.preferenceSummary.medical == 'Y'}
          <div class="panel-block">
            <div class="column">Medical reason</div>
            <div class="column">
              <strong>Priority Requested</strong>
            </div>
          </div>
        {/if}
        {#if preference.preferenceSummary.social == 'Y'}
          <div class="panel-block">
            <div class="column">Social reason</div>
            <div class="column">
              <strong>Priority Requested</strong>
            </div>
          </div>
        {/if}
        {#if preference.preferenceSummary.reason && preference.preferenceSummary.reason != ''}
          <div class="panel-block">
            <div class="column">Additional reason</div>
            <div class="column">
              <strong>
                {@html preference.preferenceSummary.reason}
              </strong>
            </div>
          </div>
        {/if}
        {#if preference.information && preference.information != null}
          <div class="panel-block">
            <div class="column">Important school information</div>
            <div class="column">
              {@html preference.information}
            </div>
          </div>
        {/if}
        {#if preference.warningText && preference.warningText != null}
          <div class="panel-block">
            <div class="column">Supplementary information form</div>
            <div class="column">
              {@html preference.warningText}
            </div>
          </div>
        {/if}
        <br />
      {/each}
    </nav>
    <hr />
    <div>
      <h5 class="title is-4">{details.pageText[2].text}</h5>
      {@html details.pageText[3].text}
    </div>
    <hr />
    <div>
      <h5 class="title is-4">{details.pageText[4].text}</h5>
      {@html details.pageText[5].text}
    </div>
    <br />

    {#if error != ''}
      <span>
        <p class="help has-text-danger">{error}</p>
      </span>
    {/if}
    <CheckBox
      label="<strong> I have read and accepted the above declaration </strong>"
      bind:checked={declaration} isDisabled={details.applicationVersion >0}
    />

    {#if details.laUrl && details.laUrl != ''}
      <span>
        <CheckBox
          label="<strong>I have read and accepted {details.laDisplayName} <a
          href={details.laUrl != '' && !details.laUrl.startsWith('http://') && !details.laUrl.startsWith('https://') ? 'http://' + details.laUrl : details.laUrl}
          target='_blank'>terms and conditions</a> </strong>"
          bind:checked={laDeclaration}  isDisabled={details.applicationVersion >0}/>

      </span>
    {/if}
    <CheckBox label="<strong>I agree that {details.laDisplayName} will use the information I have provided to process my application for the schools listed. When processing the application the Council may share the information with other third party organisations such as the previous school, schools being applied for and the maintaining authority for those schools as well as any other relevant statutory partners.</strong>"
      bind:checked={GDPRDeclaration}  isDisabled={details.applicationVersion >0}
      />
    <div>

      <div style="margin-top: 15px;">
        <button
          on:click={submitApplication}
          class="button is-primary {updatingApplication ? 'is-loading' : ''}"
          disabled={updatingApplication}>
          Submit application
        </button>
      </div>
    </div>

  </div>
  {/if}
{/await}


{#if showHelpModal}
  <Modal bind:showModal={showHelpModal}>
    <Help
      {helpId}
      showSearchOption={false}
      showContent={false}
      doAnimation={false}
      isPopup={true} />
    <!-- <footer class="modal-card-foot buttons is-right" slot="footer">
      <button
        class="button continue"
        on:click={() => {
          showHelpModal = false;
        }}>
        Close
      </button>

    </footer> -->
  </Modal>
{/if}

<Modal
  bind:showModal={showMessage}
  on:closed={() => {
    dispatch('pageChange', '/user/home');
  }} allowClose={allowClose}>
  {#if responseError != null && responseError != ''}

      <h4 class="title is-5">Submitting your application has failed. Please try again.</h4>
      <br />
       <p class="help is-danger"><span>{@html responseError}</span></p>
  {:else}
    <h4 class="title is-5">Your application has now been submitted!</h4>
    <p>
      You have been sent a confirmation email for your records.  If you haven't received the email please check your junk/spam folder.
    </p>
    <p>Please ensure you read the email carefully for any additional requirements e.g. links to supplementary information forms.</p>
      <p style="margin-bottom=0px;">
      <strong>
        Application reference:
        <span>{applicationResponse.applicationReference}  <br />   </span>
        Version:
        <span>{applicationResponse.version}</span>
      </strong>
    </p>
    <p style="margin-bottom=0px;">
      <strong>
        Application date:
        <span>{applicationResponse.strApplicationDate}</span>
      </strong>
    </p>
    <p style="margin-bottom=0px;">
      <strong>
        Closing date:
        <span>{applicationResponse.strClosingDate}</span>
      </strong>
    </p>
    <p style="margin-bottom=0px;">
      <strong>
        Outcome date:
        <span>{applicationResponse.strOutcomeDate}</span>
      </strong>
    </p>
<!--
    <p>
      Please click the
      <strong>Home</strong>
      button to go to the home page.

      If you wish to attach the documents requested by your home Local Authority, please click the
      <strong>Attach documents</strong>
      button now. Alternatively, you can attach them before the closing date
      from 'Your home page' by selecting 'Attach a document'.
    </p> -->

    <p>
      Please click on the
      <strong>Attach documents</strong>
      button now if you have documents ready to attach.

      If not please click the
      <strong>Home</strong>
      button now. Alternatively, you can attach them before the closing date
      from 'Your home page' by selecting 'Attach a document'.
    </p>

    <div style="text-align: center;margin-bottom: 15px;">
      <a
      href="/application/documents/{id}/{applicationId}"
      class="button is-primary"
      style="margin-right:10px;">
      Attach documents
    </a>
      <a href="/user/home" class="button" style="margin-right:10px;">Home</a>

    </div>
  {/if}

</Modal>

{#if isApplicationEHCPBarred}
<ModalMessage showModal={isApplicationEHCPBarred}  allowClose={false} buttonText={'Close'} on:continue={()=>{dispatch('pageChange','user/home')}}>
  <p>
    As you have stated your child has an EHCP the option to progress with this application has been removed. If you have ticked this by mistake, please edit your child's details.
  </p>
  </ModalMessage>
{:else if details &&  showTooFewSchoolsPopUp && $ShowTooFewSchoolWarning && details.applicationStatus != "Submitted"}
  <TooFewSchools
    bind:showTooFewSchoolsPopUp
    maxPreference={details.borough.maxPref}
    selectedPreferences={details.preferences.length}

    on:addSchool={() => { dispatch('pageChange',`/application/preferences/${id}/${applicationId}`)}} />
{/if}


