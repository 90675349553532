<script>
  import { createEventDispatcher,getContext } from "svelte";
  import Modal from "./HTMLElements/Modal.svelte";
  import Help from './help.svelte'
  export let info = "";
  export let label = "";
  export let helpId = "";
  export let showInfo = false;
  export let showModal = false;
  export let hasIconLeft = false;
  export let hasIconRight = true;
  export let isTitle = false;
  export let isIndexed = false;
  export let startAt = 0;
  export let endAt = 0;
  export let showContentHeader = true;
  export let showContent = false;
  export let isDispatch=false;
  function toggleInfo() {
    showInfo = !showInfo;
    if(showInfo)
      _paq.push(['trackEvent', 'Info', label]); 
  }

  export let isRequired = true;
 const dispatch = new createEventDispatcher();
</script>

<style>
  .info-container {
    border-left: 2px solid #6f4077;
    font-size: 14px;
    color: #6f4077;
    margin-bottom: .5rem !important;
  }
  .field-container {
    padding: 10px 0px;
  }
  /* .icon {
    color: #209cee; 
    cursor: pointer;
  } */
</style>

<div class="field-container">
  <div class="field">
    <p name="label" class="{isTitle ? 'title is-5' : 'label'}">
      {label}
      {#if isRequired}
        <span style="color: #cc0404;">*</span>
      {/if}
      {#if info}
        <span name="infoIcon" class="icon info-icon">
          <span on:click={toggleInfo} on:keyup={ e =>{ if(e.keyCode == 13) toggleInfo();}} 
                class="fas fa-info-circle"  tabindex="0" aria-label={label + ' help'}/>
        </span>
      {/if}
      {#if info =='' && helpId != ''}
        <span name="infoIcon" class="icon info-icon">
          <span 
            on:click={()=>{
              _paq.push(['trackEvent', 'Info', label]);
              if(isDispatch)            
                dispatch('showHelp',helpId)            
              else
                showModal=true;                  
              }}
            on:keyup={ e =>{ 
              if(e.keyCode == 13) {
                if(isDispatch)          
                  dispatch('showHelp',helpId)            
                else
                  showModal=true; 
              }
            }}
            tabindex="0" aria-label={label + ' help'} 
            class="fas fa-info-circle" />
        </span>
      {/if}
    </p>
    {#if info != '' && showInfo}
      <div class="info-container">
        <p style="padding: 5px;">
          {@html info}
          {#if helpId != ''}
            <div
              on:click={() => {
                if(isDispatch)
                  dispatch('showHelp',helpId)        
                else
                  showModal = true;            
              }}
              on:keyup={e => {
                if(e.keyCode == 13){
                  if(isDispatch)
                    dispatch('showHelp',helpId)        
                  else
                    showModal = true;            
                }
              }}
              style="padding-left: 0px;" >
              <p class="help is-info" style="margin-left: 0; cursor: pointer;">
                More Info
              </p>
            </div>
          {/if}
        </p>
      </div>
    {/if}
  
    <div class="control {hasIconRight ?'has-icons-right' : ''} { hasIconLeft ? 'has-icons-left' : ''}"> 
      <slot name="inputField" />
    </div>
  </div>
</div>
{#if showModal}
  <Modal bind:showModal>
    {#if isIndexed}
      <Help
        {helpId}
        {isIndexed}
        {startAt}
        {endAt}
        showSearchOption={false}
      {showContent}
        doAnimation={false}
           isPopup={true}
        {showContentHeader} />
        
    {:else}
      <Help
        {helpId}
        showSearchOption={false}
        showContent={false}
        doAnimation={false}
        isPopup={true}
        {showContentHeader} />
    {/if}
  </Modal>
{/if}
