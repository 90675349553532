<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { BaseUrl } from "../store";
  import wrappedFetch from "../wrappedFetch.js";
  import Cards from "../components/Cards.svelte";
  let dispatch = createEventDispatcher();
  let searchvalue = "";
  let filteredParagraphs = [];
  let paragraphs = [];

  let showContent = true;
  let regex = new RegExp("<[^>]*>", "gmi");
  let doAnimation = true;
let windowHistory = false;
  const getHelpSearchContent = async () => {  
    const getHelpContents = await wrappedFetch(
      "helpcontent/GetHelpSearchContent"
    );
    return await getHelpContents.json();
  };
  onMount(()=>{ 
    if(document.referrer.includes(BaseUrl))
      windowHistory=true;
   
  })

  const search = s => {
   
    filteredParagraphs=[];
    let rg = new RegExp(
      `(>[\\w\\s,\\.\\-\\(\\)\\'\\"\\?\\!\\*\\&\\%\\;\\:\\@\\_\\#\\/]*)(${s})`,
      "gmi"
    );
    filteredParagraphs = paragraphs
      .map(p => {
        if (s == "") {
          //  noMatch = false;
          return p;
        }

        return {
          id: p.id,
          mainPage: p.mainPage,
          header: p.header.replace(rg, match => {
            return match.replace(new RegExp(s, "gmi"), `<mark>$&</mark>`);
          }),
          content: p.content.replace(rg, match => {
            return match.replace(new RegExp(s, "gmi"), `<mark>$&</mark>`);
          }),
          showContent:
            p.content
              .replace(regex, match => "")
              .toLowerCase()
              .indexOf(s.toLowerCase()) > -1 ||
            s == "" ||
            p.header
              .replace(regex, match => "")
              .toLowerCase()
              .indexOf(s.toLowerCase()) > -1
        };
      })
      .filter(p => {
        return s == "" || p.showContent;
      });

     _paq.push(['trackSiteSearch',
                    // Search keyword searched for
                    s,
                    // Search category selected in your search engine. If you do not need this, set to false
                    "Help",
                    // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
                    filteredParagraphs.length
                ]);

   
  };

  let timeout;
  $: {
    //delays search by 300 ms
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
     if (searchvalue != "") {
      if (paragraphs.length < 1) beginSearch();
      else search(searchvalue);
    }
    }, 300);
  }

  const beginSearch = async () => {
    const contents = await getHelpSearchContent();
    let i = 1;
    contents.forEach((d, k) => {
      switch (d.type) {
        case "h1":
        case "h3":
          if (d.text != "") {
            let mainPage = "";
            switch (d.title) {
              case "Help20":
              mainPage = `<br/><p><strong>View <a href='/howtoregister'> How to register </a></strong></p>`;
              break;
              case "Help41":
              mainPage = `<br/><p><strong>View <a href='/importantdates'> Important dates </a></strong></p>`;
              break;
              case "TechFAQ":
              mainPage = `<br/><p><strong>View <a href='/faq'>FAQ </a></strong></p>`;
              break;
              case "Guidance":
              mainPage = `<br/><p><strong>View <a href='/info'> Who can apply </a></strong></p>`;
              break;
            }
            paragraphs.push({
              mainPage: mainPage,
              header: `<div>${d.text}</div>`,
              content: `<div>${contents[k + 1]["text"]}</div>`,
              id: i++,
              showContent: showContent
            });
          }
          break;
      }
    });
    search(searchvalue);
  };

</script>

<style>
  .card-header-title {
    color: white;
    font-weight: 500;
  }
  .icon {
    /* padding-left: 35px !important; */
    padding-top: 7px !important;
  }
  .field a {
    color: #1d70b8;
    cursor: pointer;
    text-decoration: none;
}
.column .title{
  min-height:54px;
}
</style>

<svelte:head>
  <title>Help | eAdmissions</title>
</svelte:head>
<section class="">
  <div class="container">
    <div class="tile is-parent">   
      <article
        class="tile is-child notification"
        style="background-color: transparent;">
        <div class="field" style="margin-left: -0.75rem">
          {#if windowHistory}
          <a on:click={()=> window.history.go(-1)}>
            <span class="fas fa-arrow-left" />
            Back
          </a>
          {:else}
          <a href="/">
            <span class="fas fa-arrow-left" />
            Back
          </a>
          {/if}
      
            </div>
        <h1 class="title" style="text-align: center;color: #70457a;">
          Help and Information
        </h1>
      </article>
    </div>

    <div class="tile is-parent" style="margin-top:0px;">
      <div class="tile is-vertical is-12">
        <div class="field" style="margin-left: 15px; margin-right: 15px;">
          <div class="control has-icons-left">
            <input
              aria-label="search box"
              type="text"
              class="input"
              placeholder="Start typing to search"
              bind:value={searchvalue}
              on:input={() => {}}
              style="width: 400px;" />
            <span class="icon is-small is-left">
              <span class="fa fa-search" />
            </span>
          </div>
        </div>
        {#if searchvalue == ''}
          <!-- <div class="tile">

            <article class="tile is-child notification">
              <p class="title is-5">What is in this section?</p>
              <div class="content">
                <p>
                  Please select the relevant help option below. If you need to
                  speak to the support team or your Local Authority please click
                  <a href="contact.html">here</a>
                  .
                </p>
                <ul>
                  <li>
                    <strong>Help</strong>
                    is orem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </li>
                  <li>
                    <strong>FAQ</strong>
                    is orem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </li>
                  <li>
                    <strong>Information & Guidance</strong>
                    is orem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </li>
                  <li>
                    <strong>eAdmissions tutorials</strong>
                    is orem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </li>
                </ul>

              </div>

              <hr style=" background-color: #a6cdd1; height: 1px;" />

            </article>
          </div> -->

          <div
            class="tile is-ancestor columns is-multiline"
            style="margin-left:1px; margin-top:5px;">
            <div 
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet is-3-widescreen"
              on:click={() => dispatch('pageChange', '/howtoregister')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/howtoregister')}}>
              <article class="tile home-button is-child notification is-primary">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-8">
                    <p class="title is-4">How to register</p>
                    <p style="text-decoration:underline;margin-bottom:0px;">View</p>
                  </div>
                  <div class="column is-4">
                    <span class="fas fa-question-circle" style="font-size: 3rem;" />
                  </div>
                </div>
              </article>

            </div>
            <div 
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet is-3-widescreen"
              on:click={() => dispatch('pageChange', '/faq')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/faq')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #407f8a; color:white;">
                <div class="columns is-vcentered is-mobile">
                  <div class="column is-8 is-8-mobile">
                    <p class="title is-4">FAQ</p>
                    <p style="text-decoration:underline;margin-bottom:0px;">View</p>
                  </div>
                  <div class="column is-4 is-4-mobile">
                    <span class="fas fa-comments" style="font-size: 3rem;" />
                  </div>
                </div>
              </article>
            </div>
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/info')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/info')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #666666; color:white;">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-8">
                    <p class="title is-4">Who can apply</p>
                    <p style="text-decoration:underline;margin-bottom:0px;">View</p>
                  </div>
                  <div class="column is-4">
                    <span class="fas fa-info-circle" style="font-size: 3rem;" />
                  </div>
                </div>
              </article>
            </div>
            <div
              tabindex="0"
              class="tile tile-hover is-parent column is-half-tablet
              is-3-widescreen"
              on:click={() => dispatch('pageChange', '/importantdates')}
              on:keypress={(e) => {if(e.keyCode == 13) dispatch('pageChange', '/importantdates')}}>
              <article
                class="tile home-button is-child notification"
                style="background-color: #416EA1; color:white;">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-8">
                    <p class="title is-4">Important dates</p>
                    <p style="text-decoration:underline;margin-bottom:0px;">View</p>
                  </div>
                  <div class="column is-4">
                    <span
                      class="fas fa-chalkboard-teacher"
                      style="font-size: 3rem;" />
                  </div>
                </div>
              </article>
            </div>
            <!-- <div
            class="tile tile-hover is-parent column is-half-tablet is-3-widescreen"
            onclick="window.open('https://www.eadmissions.org.uk/ApplicationTutorial.pdf',
            '_self');">
            <article
              class="tile home-button is-child notification"
              style="background-color: #416EA1; color:white;">
              <div class="columns is-mobile is-vcentered">
                <div class="column is-8">
                  <p class="title is-4">Tutorials</p>
                  <p style="text-decoration:underline">View</p>
                </div>
                <div class="column is-4">
                  <span class="fas fa-chalkboard-teacher" style="font-size: 3rem;"/>
                </div>
              </div>
            </article>
          </div> -->
          </div>
        {:else if filteredParagraphs.length > 0}
          <div style="margin: 1rem;">
          {#each filteredParagraphs as item}
            <Cards bind:id={item.id} showContent={item.showContent} {doAnimation}>
              <div class="card-header-title" slot="header">
                {@html item.header}
              </div>
              <div class="content" slot="content">               
                {@html item.content}
                 {@html item.mainPage}
              </div>
            </Cards>
          {/each}
          </div>
        {:else}
          <!-- {#if noMatch} -->
          <div class="notification" style="margin: 1rem;">No search results found!</div>
          <!-- {/if} -->
        {/if}

      </div>
    </div>
  </div>
</section>
