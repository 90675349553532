<script>
  import { ENV } from "../../store.js";
  import { afterUpdate } from "svelte";
  import leaflet from "leaflet";
  import  GestureHandling  from "leaflet-gesture-handling";

  import { createEventDispatcher } from "svelte";
  import * as utils from "../Utils/utils.js";
  import * as animateScroll from "svelte-scrollto";
  import wrappedFetch from "../../wrappedFetch.js";

  let dispatch = createEventDispatcher();
  export const handleAddSchool = school => {
    dispatch("addSchool", school);
  };

  const handleMapClick = laid =>{
    dispatch("LAChange", laid); 
  }

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

  export let isPostcodeSearch = false;
  let selectedLAID = "";
  export let postcode = "";
  export let showMap = false;
  export let selectedLA = "";
  export let map = undefined;
  export let showAddSchool = false;
  export let isNoResult = false;
  export let gender="";
  export let id="";
  export let preferences=[];
  export let LAName="";
  export let phaseName="";
  let mapcontainer;
  let areas=[
{name:"Surrey County Council",laid:936,coords:"128,606,14"},
{name:"Hillingdon",laid:312,coords:"149,297,12"},
{name:"Harrow",laid:310,coords:"237,213,11"},
{name:"Barnet",laid:302,coords:"327,202,11"},
{name:"Enfield",laid:308,coords:"409,162,13"},
{name:"Hounslow",laid:313,coords:"219,367,9"},
{name:"Ealing",laid:307,coords:"233,305,13"},
{name:"Brent",laid:304,coords:"282,259,12"},
{name:"Richmond upon Thames",laid:318,coords:"258,394,11"},
{name:"Hammersmith and Fulham",laid:205,coords:"318,338,9"},
{name:"Kensington and Chelsea",laid:207,coords:"336,332,10"},
{name:"Westminster",laid:213,coords:"360,319,11"},
{name:"Camden",laid:202,coords:"359,266,10"},
{name:"Haringey",laid:309,coords:"402,230,11"},
{name:"Waltham Forest",laid:320,coords:"474,218,10"},
{name:"Redbridge",laid:317,coords:"533,236,9"},
{name:"Islington",laid:206,coords:"401,277,10"},
{name:"City of London",laid:201,coords:"416,315,10"},
{name:"Tower Hamlets",laid:211,coords:"455,307,12"},
{name:"Newham",laid:316,coords:"503,299,11"},
{name:"Barking and Dagenham",laid:301,coords:"583,288,12"},
{name:"Havering",laid:311,coords:"642,244,10"},
{name:"Kingston upon Thames",laid:314,coords:"279,460,10"},
{name:"Merton",laid:315,coords:"335,438,11"},
{name:"Wandsworth",laid:212,coords:"340,389,10"},
{name:"Sutton",laid:319,coords:"350,493,9"},
{name:"Croydon",laid:306,coords:"420,494,12"},
{name:"Bromley",laid:305,coords:"522,482,12"},
{name:"Lambeth",laid:208,coords:"396,379,12"},
{name:"Southwark",laid:210,coords:"425,366,11"},
{name:"Lewisham",laid:209,coords:"467,396,11"},
{name:"Greenwich",laid:203,coords:"521,359,11"},
{name:"Bexley",laid:303,coords:"588,382,11"}
]

  let isMobile = utils.isMobileDevice();

  const handleResize = () => {
    isMobile = utils.isMobileDevice();
  };

  afterUpdate(() => {
    if (mapcontainer && isMobile) {
      animateScroll.scrollTo({ element: "#map" });
    }

    if (showMap) {
      loadMap(selectedLA);
    }
  });

  const setBoundaryMarkers = mapLA => {
    var wsUrl = "https://maptest.lgfl.org.uk/eadmissions/GetMarkersv2.ashx?Env="+ENV+"&Type=LABountary&LEA=" + mapLA;

    if (wsUrl != "") {
      wrappedFetch(wsUrl)
        .then(res => res.json())
        .then(result => {
          let l1 = [];
          let lines = [];

          result[0].coordinates.forEach(line => {
            let a = [];
            line.split(",").forEach(l => {
              a.push(l);
            });
            l1.push(a);
          });
          lines.push(l1);
          map.createPane("labels");
          map.getPane("labels").style.zIndex = -650;
          map.getPane("labels").style.pointerEvents = "none";
          let position = L.tileLayer(
            "https://map-ts.lgfl.org.uk/hot/{z}/{x}/{y}.png",
            {
              attribution: "©OpenStreetMap, ©CartoDB"
            }
          ).addTo(map);

          let positionLabels = L.tileLayer(
            "https://map-ts.lgfl.org.uk/hot/{z}/{x}/{y}.png",
            {
              attribution: "©OpenStreetMap, ©CartoDB",
              pane: "labels"
            }
          ).addTo(map);

          let myLines = [
            {
              type: "Polygon",
              coordinates: lines
            }
          ];

          let myStyle = {
            fillColor: "#" + result[0].FillColour,
            color: "#" + result[0].Outlinecolour,
            weight: 5,
            opacity: 0.65
          };

          L.geoJSON(myLines, { style: myStyle }).addTo(map);
        });
    }
  };

  const loadMap = async selectedLA => {
    if (map) {
      map.off();
      map.remove();

      document.getElementById('map').remove();
      var g = document.createElement("div");
      g.setAttribute("id", "map");
      g.setAttribute("class", "tile is-vertical");
      g.style.height = "760px";
      g.style.maxWidth = "760px";

      document.getElementById("main-div").appendChild(g);
    }

    if (selectedLA == "") return;
    let selectedPhases = "";
    selectedLA.phases.forEach(v => {
      selectedPhases += v.phaseId + ",";
    });
    selectedLA.localAuthority = selectedLA.laid;

    map = L.map("map", {
      center: [selectedLA.latitude, selectedLA.longitude],
      zoom: selectedLA.zoom,
      zoomControl: true,
      gestureHandling: true
    });

    L.tileLayer("https://map-ts.lgfl.org.uk/hot/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
    }).addTo(map);


    if (selectedLA.laid){
      await setBoundaryMarkers(selectedLA.laid);
      await setSchoolMarkers("LIVE", false, selectedLA, selectedPhases, id, gender, selectedLA.laid);
    }
    if (selectedLA.laId){
      await setBoundaryMarkers(selectedLA.laId);
      await setSchoolMarkers("LIVE", false, selectedLA, selectedPhases, id, gender, selectedLA.laId);
    }

    markersLayer.addTo(map);
  };

  let markers, markersArray = [];
  let marker, postcodeMarker;
  let markerOptions, toolTipOptions = {};
  let markersLayer = new L.LayerGroup();
  let htmlPopup = "";


  export const setSchoolMarkers = (
    env,
    fit,
    selectedLA,
    selectedPhases,
    id,
    gender,
    leaID
  ) => {
    const searchType = isPostcodeSearch ? "PC" : "";

    if (selectedPhases == "" && markersLayer) {
      markersLayer.eachLayer(function(layer) {
        layer.remove();
      });
    } else {
      const wsUrl =
        "https://maptest.lgfl.org.uk/eadmissions/GetMarkersv2.ashx?Env="+ENV+"&Type=Schools&LEA=" +
        leaID +
        "&phase=" +
        selectedPhases +
        "&id=" +
        id +
        "&gender=" +
        gender +
        "&lat=" +
        selectedLA.latitude +
        "&lng=" +
        selectedLA.longitude +
        "&SchType=" +
        searchType;

      if (wsUrl != "") {
        wrappedFetch(wsUrl)
          .then(res => res.json())
          .then(result => {
            if(preferences.length > 0)
              result= result.filter(s => { if(!preferences.includes(s.school_id)) return s;});  
            markers = result; 
               
            if (markers.length == 0) {
              isNoResult = true;
             // showMap = false;
            } else {
              markersLayer.eachLayer(function(layer) {
                layer.remove();
              });
              markersArray = []; // reset the markers so we dont keep pushing the same items to the array on reload

              markers.forEach(place => {
                if (
                  place.sch_url != "" &&
                  !place.sch_url.startsWith("http://") &&
                  !place.sch_url.startsWith("https://")
                )
                  place.sch_url = "http://" + place.sch_url;

                htmlPopup =
                  "<div> <p><strong>" +
                  place.Name +
                  "</strong><br/><br/>Address:<br/>" +
                  place.schaddr +
                  "</p>";
                if (place.work_phone != "") {
                  htmlPopup = htmlPopup + `Phone : <a href="tel:${place.work_phone}"><strong>${place.work_phone}</strong></a>`;
                }
                if (place.fax != "") {
                  htmlPopup = htmlPopup + `<br/>Fax : <strong>${place.fax}</strong>`;
                }
                if (place.email != "") {
                  htmlPopup = htmlPopup + `<br/>Email : <a href="mailto:${place.email}"><strong>${place.email}</strong></a>`;
                }
                htmlPopup =
                  htmlPopup +
                  "<p><a href=" +
                  place.sch_url +
                  " target=_blank><strong>School's website</strong></a>" +
                  "<a href=" +
                  place.gov_url +
                  " target='_blank' style='margin-left:50px'><strong>More Information</strong></a></p>";
                if (showAddSchool) {
                  htmlPopup =
                    htmlPopup +
                    "<p><button class='addSchool'>Add school to Application</button></p>";
                }
                htmlPopup = htmlPopup + "</div>";

                let icon = new L.Icon({
                  iconUrl: "/images/map/arrow_" + place.colour + ".png",
                  iconSize: [32, 32],
                  //iconAnchor: [11, 41],
                  zIndexOffset: -5000
                });

                markerOptions = { icon: icon };
                toolTipOptions = {};
                let button;
                marker = L.marker(
                  [place.MarkerLat, place.MarkerLong],
                  markerOptions
                )
                  .bindTooltip(place.Name, { direction: "center" })
                  .bindPopup(htmlPopup);
                if (showAddSchool) {
                  marker
                    .on("popupopen", () => {                    
                      button = document.querySelector(".addSchool");

                      //  document.querySelector("#addSchool").style.display="none";
                      button.addEventListener("click", e => {
                        e.preventDefault();
                        dispatch("addSchool", place);
                        // handleAddSchool(place);
                      });
                    })
                    .on("popupclose", () => {
                      //document.querySelector("#addSchool").remove();
                      button.remove();
                    });
                }

                markersLayer.addLayer(marker); //leaflet Layer group
                markersArray.push(marker);
              });

              let group = L.featureGroup(markersArray);
              if (fit) {
                map.fitBounds(group.getBounds());
              }
            }
            if (isPostcodeSearch) {              
              let gIcon = new L.Icon({
                iconUrl: "/images/map/pointer.png",
                iconSize: [28, 50],
                zIndexOffset: -5000
              });
              markerOptions = { icon: gIcon };
              toolTipOptions = {};             
              postcodeMarker = L.marker(
                [selectedLA.latitude, selectedLA.longitude],
                markerOptions
              ).bindTooltip(postcode, { direction: "center" });
              markersLayer.addLayer(postcodeMarker); //leaflet Layer group
              markersArray.push(postcodeMarker);
            }
          });
      }
    }
  };
</script>

<style>
  @media screen and (max-width: 809px) {
    #img {
      display: none;
    }
  }
</style>

<svelte:window on:resize={handleResize} />

{#if !showMap}
  <div id="img" class="tile is-vertical" usemap="#image-map">
    <img
      src="images/map/LGFLmap.jpg"
      usemap="#image-map"
      id="lamap"
      alt="lamap"
      style="width:760px; height:760px" />
  </div>
{:else}
  {#if isNoResult }
  {#if LAName!=""}
    <p class="help has-text-danger">There are no admitting {phaseName} schools in {LAName}!</p>
    {:else}
     <p class="help has-text-danger">No schools were found!</p>
    {/if}
  {/if}
  <div
    id="map"
    class="tile is-vertical"
    bind:this={mapcontainer}
    style="height:760px;max-width:760px" />
{/if}
<map id="image-map" name="image-map">
  {#each areas as area}
    <area target="" alt={area.name} title={area.name} coords={area.coords} shape="circle" on:click={()=>{handleMapClick(area.laid)}} href="javascript:void(null);" />
  {/each}
</map>
