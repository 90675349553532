<script>
  import { createEventDispatcher } from "svelte";
  import ListPicker from "./listpicker.svelte";
  import MapPicker from "./mappicker.svelte";
  import KeywordsPicker from "./keywordspicker.svelte";
  import Help from '../help.svelte'
  const dispatch = new createEventDispatcher();

  let selectedFinder = "list";
  export let childID;
  export let laid;
  export let phaseID ;
  export let postcode;
  export let gender ;
  export let isMultipleApplication = false;
  export let selectedPreferences=[];
  export let phaseName="";
  export let LAName="";
  let selectedSchoolIds=[];
  if(selectedPreferences.length > 0)
  {
    selectedPreferences.forEach(p=>{
selectedSchoolIds.push(p.schoolId);
    })
  }
  const onAddSchool = (e) => {
      dispatch('addSchool', e.detail);
  }
</script>

<h1 class="title is-4" id="addHeader" >Add schools</h1>
<p class="subtitle is-5" id="desc">Find schools to add to your application from:</p>

{#if !isMultipleApplication}
<div class="filter-container" aria-labelledby="addHeader">
  <label class="radio">
    <input type="radio" bind:group={selectedFinder} value={'list'} checked />
    List of schools   
  </label>
  <span name="infoIcon" class="icon" style="margin-right:1rem;"   on:click={() => dispatch('showHelp','Help29')}>
    <span class="fas fa-info-circle"></span>
  </span>

  <label class="radio">
    <input type="radio" bind:group={selectedFinder} value={'map'} />
    Map
   
  </label>
  <span name="infoIcon" class="icon" style="margin-right:1rem;"  on:click={() => dispatch('showHelp','Help32')}>
    <span class="fas fa-info-circle"></span>
  </span>

  <label class="radio">
    <input type="radio" bind:group={selectedFinder} value={'keywords'} />
    Keywords    
  </label>
   <span name="infoIcon" class="icon"  on:click={() => dispatch('showHelp','Help33')}>
 <span class="fas fa-info-circle"></span>
 </span>
</div>
<hr />
{/if}
<div aria-labelledby="desc">
  {#if selectedFinder == 'list' || isMultipleApplication}
    <ListPicker on:addSchool={onAddSchool} {phaseID} {gender} {laid} {isMultipleApplication}  {selectedSchoolIds} showSchoolsList={isMultipleApplication}></ListPicker>
  {:else if selectedFinder == 'map'}
    <MapPicker {laid} {phaseID} {phaseName} {LAName} {gender} {postcode} on:addSchool={onAddSchool}  bind:preferences = {selectedSchoolIds}></MapPicker>
  {:else}
    <KeywordsPicker on:addSchool={onAddSchool} {childID}  {selectedSchoolIds}></KeywordsPicker>
  {/if}
</div>


<style>
.filter-container{
  display:flex;
  flex-wrap:wrap
}
.filter-container > *{
  margin-top: 5px;
}
.icon {
  color: #209cee;
  cursor: pointer;
}
</style>