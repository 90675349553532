<script>
	import Navaid from "navaid";
	import { onDestroy } from "svelte";
	import NavBar from "./NavBar.svelte";

	import Home from "../routes/Home.svelte";
	import About from "../routes/About.svelte";
	import Copyright from "./about/copyright.svelte";
	import Disclaimer from "./about/disclaimer.svelte";
	import Privacy from "./about/privacy.svelte";
	import Accessibility from "./about/accessibility.svelte";
	import Contact from "../routes/Contact.svelte";
	import FindSchools from "../routes/FindSchools.svelte";

	import Register from "../routes/Register.svelte";
	import Signin from "../routes/Signin.svelte";
	import UserHome from "../routes/user/Home.svelte";
	import UserHomeTemp from "../routes/user/HomeTemp.svelte";
	import ResetPassword from "../routes/ResetPassword.svelte";
	import UserAccount from "../routes/UserAccount.svelte";
	import Application from "../routes/Application.svelte";

	import Help from "../routes/Help.svelte";
	import Faq from "../routes/Faq.svelte";
	import Guidance from "../routes/Guidance.svelte";
	import Info from "../routes/Info.svelte";
	import ImportantDates from "../routes/ImportantDates.svelte";
	import HowToRegister from "../routes/HowToRegister.svelte";
	import HowToInstall from "../routes/HowToInstall.svelte";
	import Sibling from "../routes/Sibling.svelte";
	import ApplicationSummary from "./application/review.svelte";
	import DeleteApplication from "./application/delete.svelte";
	import Offer from "../routes/offer.svelte";
	import NotFound from "../routes/404.svelte";
	import PhaseHelp from "../routes/phasehelp.svelte";
	import PushNotification from "../routes/PushNotification.svelte";
	import Test from "../routes/test.svelte";

	import { User, Location } from "../store.js";
	import { onMount, beforeUpdate } from "svelte";
	import ModalMessage from "./HTMLElements/ModalMessage.svelte";
	import Notification from "./HTMLElements/Notification.svelte";
	import WrappedFetch from "../wrappedfetch.js";
	import Support from "../routes/support/support.svelte";
	import FurtherFeedback from "../routes/furtherfeedback/furtherfeedback.svelte";


	$User = u;

	let Route, params, active;
	let uri = location.pathname;
	let showNav = true;
	let saveAndExitApp;
	let showModal = false;
	let modalMessage;
	let notificationMessage = "";
	let showNotification = false;
	let showTimerModal = false;
	let sessionTimer;
	let sessionExpireIn = 60;
	let intervalTimer;
	let subStep = "";
	//let showInstallHelp = false;

	let timeOutDuration = 10000;
	let isComponentLoading = false;
	let excludePages = ["signin", "register", "account"];
	let showInstallHelp =
		uri == "/user/home"
			? false
			: uri != "/howtoinstall" &&
			  !window.matchMedia("(display-mode: standalone)").matches;
	let showCannotAccessPrefrenceModal = false;
	$: active = !uri || uri == "" ? "home" : uri.split("/")[1] || "home";
	$: applicationSubstep =
		uri && uri != "" && uri.split("/")[1] == "application"
			? uri.split("/")[2]
			: "";
	$: subStep = uri && uri != "" && uri.split("/")[1];

	beforeUpdate(() => {
		if ($User == null && active != "support") window.onbeforeunload = null;
	});
	const setInstallHelpCookie = (cname, exdays) => {
		let d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		// d.setTime(d.getTime() + (20*1000));
		document.cookie =
			cname + "=" + d.getTime() + "; expires = " + d.toUTCString() + ";path=/";
	};
	const getInstallHelpCookie = (cname) => {
		let cookies = document.cookie.split(";");
		let cookieFound = cookies.find((c) => c.includes(cname));
		if (!cookieFound) {
			return false;
		}
		return true;
	};
	let isHelpInstallCookieValid = getInstallHelpCookie("installHelp");

	let showInstallHelpNotification =
		showInstallHelp && !isHelpInstallCookieValid;
	$: {
		showInstallHelpNotification = showInstallHelp && !isHelpInstallCookieValid;
	}

	if (!isHelpInstallCookieValid) {
		setInstallHelpCookie("installHelp", 7);
	}
	const startSessionTimer = () => {
		if (sessionTimer) clearTimeout(sessionTimer);
		if (intervalTimer) clearInterval(intervalTimer);

		//sessionExpireIn = 20;
		sessionExpireIn = 60;

		if ($User && $User.username)
			sessionTimer = setTimeout(() => {
				sessionExpireIn = 2 * 60;
				intervalTimer = setInterval(startSessionInterval, 1000);
				sessionTimer = null;
			}, (sessionExpireIn - 2) * 60 * 1000);
	};

	startSessionTimer();

	const startSessionInterval = () => {
		sessionExpireIn -= 1;
		if (sessionExpireIn < 1) {
			clearInterval(intervalTimer);
			intervalTimer = null;
			showTimerModal = false;
			modalMessage = "Your session has expired.";
			showModal = true;
			setTimeout(() => {
				window.location.href = "/signin";
			}, 2000);
		} else showTimerModal = true;
	};

	function sec2time(timeInSeconds) {
		var pad = function (num, size) {
				return ("000" + num).slice(size * -1);
			},
			time = parseFloat(timeInSeconds).toFixed(3),
			hours = Math.floor(time / 60 / 60),
			minutes = Math.floor(time / 60) % 60,
			seconds = Math.floor(time - minutes * 60),
			milliseconds = time.slice(-3);

		let message = "";

		if (minutes > 0) message += minutes + " minute and ";

		return message + seconds + " seconds";
	}

	const handleNavigate = (e) => {
		saveAndExitApp.navigate(e);
	};

	//$: active = uri.split('/')[1] || 'home';
	function draw(m, obj) {
		//params = obj || {};
		if (m.preload) {
			m.preload({ params }).then(() => {
				Route = m.default;
			});
		} else {
			Route = m.default;
		}
	}

	function track(obj) {
		uri = obj.state || obj.uri;
		let online = window.navigator.onLine;
		if (!online) window.location.href = "/offline.html";
		let navMenu = document.getElementById("navMenu");
		let navBurger = document.getElementsByClassName("navbar-burger");

		if (navMenu && navBurger.length > 0) {
			navMenu.classList.remove("is-active");
			document
				.getElementsByClassName("navbar-burger")[0]
				.classList.remove("is-active");
		}

		_paq.push(["setCustomUrl", uri && uri != "" ? uri : "/"]);

		if ($User && $User.username) {
			_paq.push(["setUserId", $User.username]);
			if (Sentry)
				Sentry.configureScope(function (scope) {
					scope.setUser({ id: $User.id, username: $User.username });
				});
		}

		_paq.push(["trackPageView"]);

		$Location = uri;
		if (window.scrollTo) window.scrollTo(0, 0);

		document.addEventListener("click", function (e) {
			const $els = document.querySelectorAll(".navbar-burger");
			if ($els.length <= 0) return;
			const $el = $els[0];
			const target = $el.dataset.target;
			//const $target = document.getElementById(target);
			// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
			if (
				e.target.className &&
				e.target.className != "" &&
				typeof e.target.className.indexOf === "function" &&
				e.target.className.indexOf("navbar-burger") == -1 &&
				e.target.id != "usermenu" &&
				e.target.id != "menulink"
			) {
				$el.classList.remove("is-active");
				document.getElementById(target).classList.remove("is-active");
			}
		});
	}

	addEventListener("fetch-error", (event) => {
		modalMessage =
			'<h4 class="title is-5">Sorry, an unexpected error has occured! Please try again.</h4><p>If the issue persists, please contact our <a href="https://www.eadmissions.org.uk/support/"" target="_blank" style="text-decoration:underline">technical support team</a> for assistance.</p>';
		showModal = true;
	});

	addEventListener("fetch-end", (event) => {
		startSessionTimer();
	});

	addEventListener("replacestate", track);
	addEventListener("pushstate", track);
	addEventListener("popstate", track);

	const baseUrl = "";

	const router = Navaid("/", (p) => {
		showNav = true;
		if (p.toLowerCase() == "/eadmissions/app") router.route("/", true);
		else Route = NotFound;
	})
		.on("/", () => {
			showNav = true;
			if ($User) {
				router.route("/user/home", true);
				return;
			}
			Route = Home;
		})
		.on("/contact", () => {
			showNav = true;
			Route = Contact;
		})
		.on("/findschools/", (p) => {
			showNav = true;
			params = p;
			Route = FindSchools;
		})
		.on("/register/:step?/:username?", (p) => {
			showNav = false;
			params = p;
			Route = Register;
		})
		.on("/signin/:username?/:hash?", async (p) => {
			showNav = false;
			params = p;
			if ($User != null) {
				if ($User.isEmailValidated == "Y" && $User.isAccountValidated == "Y") {
					router.route("/user/home", true);
					return;
				}

				//   if (p.hash && !$User.isEmailValidated){
				// 	  await WrappedFetch(`/user/validateemail?hash=${p.hash}&username=${p.username}`,
				// 	 	{method:"post" }
				// 	  )
				// 	  .then(response=> response.json())
				// 	  .then(response=> {
				// 		if (response) {
				// 		  $User.isEmailValidated="Y";
				// 		  if($User.isAccountValidated =='Y')
				// 		  	router.route("/user/home/true" ,true);
				// 		  else
				// 			router.route("/useraccount/details" ,true);

				// 		}
				// 		else
				// 			router.route("/useraccount/details" ,true);

				// 		return;
				// 	  })
				//   }
			}
			Route = Signin;
		})
		.on("/user/home/:emailvalidated?", (p) => {
			params = p;
			if ($User == null) {
				router.route("/signin", true);
				return;
			} else if (
				$User.isEmailValidated != "Y" ||
				$User.isAccountValidated != "Y"
			) {
				router.route("/useraccount/details", true);
				return;
			}
			showNav = true;

			Route = !IS_DEV ? UserHomeTemp : UserHome;
		})
		.on("/useraccount/:step?/:showModal?", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				router.route("/signin", true);
				return;
			}
			Route = UserAccount;
		})
		.on("/account/:step?", (p) => {
			showNav = false;
			params = p;
			Route = ResetPassword;
		})
		.on("/help", () => {
			showNav = true;
			Route = Help;
		})
		.on("/guidance/:step?/", (p) => {
			showNav = true;
			params = p;
			Route = Guidance;
		})
		.on("/faq", () => {
			showNav = true;
			Route = Faq;
		})
		.on("/info", () => {
			showNav = true;
			Route = Info;
		})
		.on("/importantdates", () => {
			showNav = true;
			Route = ImportantDates;
		})
		.on("/howtoregister", () => {
			showNav = true;
			Route = HowToRegister;
		})
		.on("/howtoinstall", () => {
			showNav = true;
			Route = HowToInstall;
		})
		.on("/about", () => {
			showNav = true;
			Route = About;
		})
		.on("/copyright", () => {
			showNav = true;
			Route = Copyright;
		})
		.on("/disclaimer", () => {
			showNav = true;
			Route = Disclaimer;
		})
		.on("/privacy", () => {
			showNav = true;
			Route = Privacy;
		})
		.on("/accessibility", () => {
			showNav = true;
			Route = Accessibility;
		})
		.on("/phasehelp/:phase", (p) => {
			showNav = true;
			params = p;
			Route = PhaseHelp;
		})
		.on("/viewoffer/:applicationId", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				router.route(
					`/signin?returnUrl=${encodeURIComponent(
						"/viewoffer/" + p.applicationId
					)}`,
					true
				);
				return;
			}
			Route = Offer;
		})
		.on("/application/summary/:childId?/:applicationId?", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				//  router.route("/signin", true);
				router.route(
					`/signin?returnUrl=${encodeURIComponent(
						`/application/summary/${p.childId}/${p.applicationId}`
					)}`,
					true
				);
				return;
			}
			if ($User.isEmailValidated != "Y" || $User.isAccountValidated != "Y") {
				router.route("/useraccount/details", true);
				return;
			}
			Route = ApplicationSummary;
		})
		.on("/application/delete/:childId?/:applicationId?", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				router.route("/signin", true);
				return;
			}
			if ($User.isEmailValidated != "Y" || $User.isAccountValidated != "Y") {
				router.route("/useraccount/details", true);
				return;
			}
			Route = DeleteApplication;
		})

		.on("/application/:step?/:childId?/:applicationId?", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				router.route(
					`/signin?returnUrl=${encodeURIComponent(
						`/application/${p.step}/${p.childId}/${p.applicationId}`
					)}`,
					true
				);
				return;
			}
			if ($User.isEmailValidated != "Y" || $User.isAccountValidated != "Y") {
				router.route("/useraccount/details", true);
				return;
			}
			Route = Application;
		})
		.on("/sibling/:childId?", (p) => {
			showNav = true;
			params = p;
			if (!$User) {
				router.route("/signin", true);
				return;
			}
			if ($User.isEmailValidated != "Y" || $User.isAccountValidated != "Y") {
				router.route("/useraccount/details", true);
				return;
			}
			Route = Sibling;
		})
		.on("/pushnotification", () => {
			showNav = true;
			Route = PushNotification;
		})
		.on("/check", () => {
			showNav = true;
			Route = Test;
		})
		.on("/support/:activeStep?/:supportId?", (p) => {
      params = p;
			if (p.activeStep == "summary" && p.supportId != null) {
				if (!$User) {
					router.route(
						`/signin?returnUrl=${encodeURIComponent(
							`/support/summary/${p.supportId}`
						)}`,
						true
					);
					return;
				} else if (!$User.username.endsWith(".000")) {
					Route = NotFound;
					return;
				}
			}
			showNav = true;
			if (p.activeStep == null) router.route("/support/step1", true);
			Route = Support;
		})
		.on("/furtherfeedback/:activeStep?/:feedbackId?", (p) => {
	  params = p;
	  if (p.activeStep == null) p.activeStep = 'step1';
	  if (!$User) {
				router.route(
					`/signin?returnUrl=${encodeURIComponent(
						`/furtherfeedback/${p.activeStep}`
					)}`,
					true
				);
				return;
					}
			showNav = true;
			if (p.activeStep == null) router.route("/furtherfeedback/step1", true);
			Route = FurtherFeedback;
		});

	onDestroy(router.unlisten);

	onMount(() => {
		if ($User && active == "home") {
			uri = "/";
			router.route("/user/home", true);
		}

		router.listen();

		document.addEventListener("keydown", handleFirstTab);
		window.onscroll = function () {
			scrollFunction();
		};
	});

	function scrollFunction() {
		var mybutton = document.getElementById("topBtn");
		if (
			document.body.scrollTop > 50 ||
			document.documentElement.scrollTop > 50
		) {
			mybutton.style.display = "block";
		} else {
			mybutton.style.display = "none";
		}
	}
	function topFunction() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	const handleFirstTab = (e) => {
		if (e.which === 9)
			/* tab */

			document.documentElement.classList.remove("no-focus-outline");

		document.removeEventListener("keydown", handleFirstTab);
		document.addEventListener("mousedown", handleMouseDownOnce);
	};
	const handleMouseDownOnce = () => {
		document.documentElement.classList.add("no-focus-outline");

		document.removeEventListener("mousedown", handleMouseDownOnce);
		document.addEventListener("keydown", handleFirstTab);
	};

	const onPageChange = (path) => {
		router.route(path.detail, false);
	};

	const onPageRedirect = (path) => {
		showCannotAccessPrefrenceModal =
			path.detail.text && path.detail.text == "cannotAccessPreference";
		router.route(path.detail.url, true);
	};

	const onNotification = (message) => {
		showNotification = true;
		notificationMessage = message.detail;
	};

	const saveAndExit = (e) => {
		saveAndExitApp.saveAndExit();
	};
</script>

<style>
	.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #407f8a; /* Blue */
		border-radius: 50%;
		width: 120px;
		height: 120px;
		display: inline-flex;
		animation: spin 2s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>

<svelte:window
	on:focus={() => {
		WrappedFetch('/user/GetAuthUser', { method: 'post' })
			.then((response) => response.json())
			.then((response) => {
				let user = JSON.stringify($User);
				let newUser = JSON.stringify(response.user);
				if (response.user) $User = response.user;

				if (user != newUser) window.location.reload();
			});
	}} />

{#if showNav}
	<section class="has-navbar-fixed-top">
		<NavBar
			{active}
			on:pageChange={onPageChange}
			{applicationSubstep}
			on:saveAndExit={saveAndExit}
			on:navigate={handleNavigate} />
	</section>
	<!-- <Nav {active} /> -->
{/if}
<main
	style={!excludePages.includes(subStep) ? 'padding-bottom:20px;' : ''}
	aria-label="main page body">
	{#if isComponentLoading}
		<section class="hero is-fullheight">
			<div class="hero-body">
				<div class="container" style="text-align:center">
					<div class="loader" />
					<!-- <progress value="80" max="100"></progress> -->
				</div>
			</div>
		</section>
	{/if}
	<svelte:component
		this={Route}
		{...params}
		bind:this={saveAndExitApp}
		on:pageChange={onPageChange}
		on:pageRedirect={onPageRedirect}
		on:notification={onNotification}
		bind:isLoading={isComponentLoading}
		bind:showInstallHelp />
	<button on:click={topFunction} id="topBtn" title="Go to top">
		<span class="fas fa-arrow-up" style="font-size:25px;" />
	</button>
</main>
<ModalMessage bind:showModal>
	{@html modalMessage}
</ModalMessage>

<ModalMessage
	bind:showModal={showTimerModal}
	on:continue={() => WrappedFetch('/user/GetAuthUser')}>
	Your session is expiring in
	{sec2time(sessionExpireIn)}. Click continue to keep using this website.
</ModalMessage>
<ModalMessage
	buttonText="Close"
	bind:showModal={showCannotAccessPrefrenceModal}
	on:continue={() => (showCannotAccessPrefrenceModal = false)}>
	<p style="padding:0.5rem;">
		A new application cannot currently be made for this child.<br />
		An application already exists or your Local Authority is not open for
		applications at this moment.
		<br />
		Please
		<a
			href="/contact"
			on:click={() => {
				showCannotAccessPrefrenceModal = false;
			}}>click here</a>
		to see admissions details for your Local Authority.
	</p>
</ModalMessage>
<Notification bind:show={showNotification} {timeOutDuration}>
	{@html notificationMessage}
</Notification>

<Notification bind:show={showInstallHelpNotification} bind:timeOutDuration>
	<p style="margin-bottom:0px;">
		<a
			href="/howtoinstall"
			style="text-decoration:none;"
			on:click={() => (showInstallHelpNotification = false)}>
			Please click
			<u>here</u>
			to see details on how to install eAdmissions on your device!
		</a>
	</p>
</Notification>
<svelte:head>
	<meta
		name="viewport"
		content="width=device-width {active == 'findschools' ? ',user-scalable=no' : ''}" />
</svelte:head>
