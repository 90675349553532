<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    User,
    Children,
    askPushPermission,
    savePushSubscription,
    isPushSupported
  } from "../../store.js";
  import Modal from "../../components/HTMLElements/Modal.svelte";
  import ModalConfirm from "../../components/HTMLElements/ModalConfirm.svelte";
  import ModalMessage from "../../components/HTMLElements/ModalMessage.svelte";
  import VerifySMS from "../../components/SMSVerification.svelte";
  import wrappedFetch from "../../wrappedFetch.js";
  import Help from "../../components/help.svelte";
  import Feedback from "../../components/Feedback.svelte";
  import {dateToWords} from "../../components/application/store.js";

  export let emailvalidated;
  let childToDelete;
  let applicationToDelete;
  let isChildDeleting = false;
  let isSMSOptingOut = false;
  let isSendingSMSVerificationCode = false;
  export let isLoading = true;
  export let showInstallHelp = false;
  let showSMSOptedOutNotification = false;

  let info = "";
  let additionalInformation = '';
  let showOptoutConfirmModal = false;
  let isOptingOut = false;
  let showSMSOptIn =
    $User &&
    $User.mobile &&
    $User.mobile != "" &&
    ($User.isMobileValidated == null || $User.isMobileValidated == "P");
  let showSMSOptinHelp = false;
  let showFeedback =$User && $User.submittedApplications > 0 && !$User.rating; 
  const optOutSMS = e => {   
    if(e)
    e.preventDefault();
    isSMSOptingOut= true;
    wrappedFetch(`/user/SMSOptOut`, {
      method: "post"
    })
      .then(response => response.json())
      .then(response => {
        if (response) {
          showSMSOptIn = false;
          dispatch(
            "notification",
            "Text message alerts opted out successfully."
          );
          isSMSOptingOut = false;
        }
      });
  };

  let showMobileVerification = false;
  let askSMSVerificationCode = false;

  let showPushNotificationAlert = isPushSupported;
  let cookies = document.cookie.split(";");
  // if (showPushNotificationAlert) {
  //   pushPermission = Notification.permission; // pushPermissionResult();
  // }

 
  $: showSMSOptIn =
    $User &&
    $User.mobile &&
    $User.mobile != "" &&
    ($User.isMobileValidated == null || $User.isMobileValidated == "P");
  $: showPushNotificationAlert =
    showPushNotificationAlert &&
    Notification.permission != "denied" &&
    Notification.permission != "granted" &&
    !cookies.find(c => c.includes("push-alert"));

    $: showFeedback = $User && $User.submittedApplications > 0 && !$User.rating;

  const getChildren = async () => {
    isLoading = true;
    const getChildrenResponse = await wrappedFetch(
      "/child/getchildren?x=" + new Date().getTime()
    );
    var result = await getChildrenResponse.json();
    console.log("Children", result.children);
    additionalInformation = result.additionalInformation;
    return result.children;
    
    //return await getChildrenResponse.json();
  };
  const dispatch = createEventDispatcher();
  onMount(async () => {
    //if ($Children.length < 1) {
    Children.set(await getChildren());
  
    isLoading = false;
    showInstallHelp = true;

    //}
    if ($User == null) {
      dispatch("pageChange", "/signin");
    }
  });

  const getAuthUser = async () => {
		let resp = await wrappedFetch("/user/GetAuthUser", { method: "post" });
		return await resp.json();
	};

  const deleteChild = () => {
    let form = new FormData();
    form.append("id", childToDelete.id);
    isChildDeleting = true;
    wrappedFetch(`/child/deletechilddetails`, {
      method: "post",
      body: form
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          $Children = $Children.filter(c => c.id != childToDelete.id);
          childToDelete = null;
          dispatch("notification", "Child deleted successfully.");
        }
        else
        {
         dispatch("notification", response.message);
          dispatch("pageChange", `/user/home`);
          return;
        }
        isChildDeleting = false;

      });
  };

  const onYesPushClick = async e => {
    e.preventDefault();
    askPushPermission().then(async result => {
      if (result == "granted") {
        let save = await savePushSubscription();
        if (save.success) {
          showPushNotificationAlert = false;
          dispatch("notification", "Opt-in was successful!");
        } else dispatch("notification", save.message);
        
      } else if (result == "denied") {
        showPushNotificationAlert = false;
      }
    });
  };

  const onNoPushClick = e => {    
   if(e)
    e.preventDefault();
    let d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie =
      "push-alert" +
      "=" +
      "1" +
      d.getTime() +
      "; expires = " +
      d.toUTCString() +
      ";path=/";
    showPushNotificationAlert = false;
  };

  const optoutNotificationAlerts=()=>{
    isOptingOut = true;
    if(showSMSOptIn)
      optOutSMS();
    if(showPushNotificationAlert)
      onNoPushClick();          
    isOptingOut = false;
    showOptoutConfirmModal = false;
  }

  const sendSMSVerificationCode = e => {
    e.preventDefault();
    isSendingSMSVerificationCode = true;
    wrappedFetch("user/sendSMSverificationcode")
      .then(res => res.json())
      .then(async(result) => {
        if (result.success) {
          askSMSVerificationCode = false;
          showMobileVerification = true;

          $User.isMobileValidated = (await getAuthUser()).user.isMobileValidated;        
          // info="Your mobile verification code has been sent";
        }
        isSendingSMSVerificationCode = false;
      });
  };
</script>

<style>
  .alert-icon {   
    font-size: 3rem;
    color: #6f4076;  
  }


  @media screen and (max-width: 767px) {
    .notification-container .btn-container{
    display:none
  }
  .notification-container .notification-msg {
    padding: 0rem;
  } 
  }
</style>

<Feedback bind:showModal={showFeedback}/>

<Modal bind:showModal={askSMSVerificationCode}>
  <p style="padding:0.5rem;margin-bottom: 0px;">
    Please click 'Send' to send mobile verification code to the mobile number:
    <b>{$User.mobile}</b>
  </p>
  <p style="padding:0.5rem;margin-bottom: 0px;">
    If the above mobile number is not correct, please
    <b>
      <a href="useraccount/editaccount">click here</a>
    </b>
    to update your account.
  </p>

  <footer
    class="modal-card-foot buttons"
    slot="footer"
    style="justify-content: space-between;">
    <button
      class="button continue"
      on:click={() => {
        askSMSVerificationCode = false;
      }}>
      Close
    </button>
    <button   
      class="button is-primary continue {isSMSOptingOut ? 'is-loading' : ''}"
      disabled={isSendingSMSVerificationCode}
      on:click={sendSMSVerificationCode}>
      Send
    </button>
  </footer>
</Modal>
<!-- {#if showMobileVerification} -->
  <VerifySMS
  bind:showModal={showMobileVerification}
    mobile={$User.mobile}
    isDispatchResend ={true}
    on:resend={()=>{showMobileVerification = false;askSMSVerificationCode = true;}}
    on:close={() => (showMobileVerification = false)} />
<!-- {/if} -->
<svelte:head>
  <title>Application Home | eAdmissions</title>
</svelte:head>
{#if !isLoading}
  <section class="">

    <div class="container">
      <div class="tile is-parent" style="padding: 0rem;">
        <article
          aria-label="Home Page Header"
          class="tile is-child notification"
          style="background-color: transparent;">
          <h1 class="title" style="color: #70457a; text-align:center;">
            Your Home Page
          </h1>
        </article>

      </div>
      {#if showPushNotificationAlert || showSMSOptIn}
        <div class="tile is-parent" style="padding: 0rem;">
          <article
            aria-label="SMS Optin Notification"
            class="tile is-child notification"
            style="background-color: transparent;;padding-top:0px;">
            <div class="box" style=" ">

              <div>
                <span style="float:right;cursor:pointer;" class="faIcon fas fa-times" on:click={()=>showOptoutConfirmModal = true}></span>
                <p class="title is-5">Get eAdmissions Alerts!</p>
                <p>
                  You can sign up to receive optional messages during the current admissions round. Information on what is sent is available 
                  <a
                    href="javascript:;"
                    on:click={() => {
                      showSMSOptinHelp = true;
                    }}>
                    here
                  </a>
                </p>
              </div>
              <div class="columns">
                {#if showPushNotificationAlert}
                  <div class="column is-6" style="padding-bottom:0px;">
                    <div
                      class="notification is-invalid"
                      style="color: rgb(74, 74, 74);background-color: white;">
                      <div class="columns notification-container">
                        <div
                          class="column is-2 btn-container"
                          style="align-self: center; ">
                          <div class="alert-icon">
                            <i class="fas fa-bell" aria-hidden="true" style="text-align: center;" />
                          </div>
                        </div>
                        <div
                          class="column is-10 notification-msg"
                          style="padding-top: 10px;/* margin-bottom: 10px; */">
                          <p><b>Push Notifications</b></p>

                          <p class="" style="margin-bottom: 10px;">
                            Would you like to receive push notifications?
                          </p>
                          <button
                            class="button notification-btn"
                            on:click={onYesPushClick}
                            style="font-size: 15px;">
                            <b>Yes</b>
                          </button>
                          <button
                            class="button notification-btn"
                            on:click={onNoPushClick}
                            style="font-size: 15px;">
                            <b>No</b>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                {/if}
                {#if showSMSOptIn}
                  <div class="column is-6" style="padding-bottom:0px;">
                    <div
                      class="notification is-invalid"
                      style="color: rgb(74, 74, 74);background-color: white;">
                      <div class="columns notification-container">
                        <div
                          class="column is-2 btn-container"
                          style="align-self: center; ">
                          <div class="alert-icon">
                            <i class="fas fa-sms" aria-hidden="true" style="text-align: center;"  />
                          </div>
                        </div>
                        <div
                          class="column is-10 notification-msg"
                          style="padding-top: 10px;/* margin-bottom: 10px; */">
                          <p><b>Text Message</b></p>

                          <p class="" style="margin-bottom: 10px;">
                            Would you like to receive text messages?
                          </p>
                          <button
                            class="button notification-btn"
                            on:click={e => {
                              e.preventDefault();
                              if($User.isMobileValidated == "P")
                              showMobileVerification = true;
                              else
                              askSMSVerificationCode = true;
                            }}
                            style="font-size: 15px;">
                            <b>Yes</b>
                          </button>
                          <button                            
                            class="button notification-btn {isSMSOptingOut ? 'is-loading' : ''}"
                            disabled={isSMSOptingOut}
                            on:click={optOutSMS}
                            style="font-size: 15px;">
                            <b>No</b>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                {/if}
              </div>
            </div>
          </article>
        </div>
      {/if}
     

      <a
        href="javascript:;"
        on:click={() => dispatch('pageChange', '/application/childdetails/0')}
        class="button is-large is-primary"
        style="margin-left:25px;">
        Start an application
      </a>
     
      {#if $Children.length > 0}
      <div class="tile is-parent" style="padding:0rem;">
        <article
        aria-label=""
        class="tile is-child notification"
        style="background-color: transparent;padding-top:0.75rem;padding-bottom:0px;">
      <h2 style="color: #70457a;"><strong>Your Applications</strong></h2>
      <hr style="margin-top:0px;margin-bottom:0px;"/>
      </article>
      </div>
        {#each $Children as child}
          <div class="tile is-parent" style="padding-bottom:0px;padding-top:0px;">
            <div class="tile is-vertical is-12">

              <div class="tile is-parent is-vertical">
                <article
                  class="tile is-child notification"
                  aria-label="Application for {child.forename}
                  {child.surname}">

                  <p class="title is-5" style="padding-top:10px;">
                    {#if child.middlename == null}
                      {child.forename} {child.surname} ({child.gender})
                    {:else}
                      {child.forename} {child.middlename} {child.surname} ({child.gender})
                    {/if}
                  </p>
                  <p class="subtitle is-6">
                    DOB: {dateToWords(child.dob)}<!--{child.dobStr}--> Postcode: {child.postcode}
                  </p>
                  <!--todo replace below static text with something from database-->
                  {#if child.isEHCPAllowed == 'N' && child.ehcpNotAllowedText && child.ehcpNotAllowedText != ''}
                    <p class="has-text-danger" style="margin-bottom:1rem;">
                      {child.ehcpNotAllowedText || ''}
                      <!-- If your child has an EHCP, please do not complete an
                    application.. -->
                    </p>
                  {/if}
                  <a
                    aria-label="Edit details for {child.forename}
                    {child.surname}"
                    href={child.isSibling &&  !child.scSkip ? `/sibling/${child.id}` : `/application/childsummary/${child.id}/${child.applications[0] ? child.applications[0].applicationId : ''}`}>
                    Edit child details
                  </a>
                  {#if child.canApply && (child.scSkip || (child.scForename != '' && child.scSurname != ''))}
                    <p>
                      <a
                        href={child.scSkip || (child.scForename != '' && child.scSurname != '') ? `/application/preferences/${child.id}/0` : `/application/childsummary/${child.id}/0`}>
                        {child.applications.length > 0 ? 'Start application to a new borough' : 'Start new application'}
                      </a>
                    </p>
                  {/if}
                  {#if !child.isSibling && child.applications.length == 0}
                    <p style="margin-bottom:0px;">
                      <a
                        href="javascript:;"
                        on:click={() => (childToDelete = child)}>
                        Delete child details
                      </a>
                    </p>
                  {/if}

                  {#if child.applications.length > 0}
                    {#each child.applications as app}
                      <hr style=" background-color: #a6cdd1; height: 1px;" />
                      <div>
                        <p class="title is-5">
                          {child.phase} application to {app.laDisplayName}
                        </p>

                        {@html app.statusHTML}

                        {#if app.reference}
                          <p>
                            <strong>
                              Application reference: {app.reference || ''}
                            </strong>
                          </p>
                        {/if}
                        {#if app.offerRepliedStr && app.offerRepliedStr != ''}
                          <p style="margin-bottom:0px;">
                            Outcome response date: {app.offerRepliedStr}
                          </p>
                        {:else if app.submittedStr && app.submittedStr != ''}
                          <p style="margin-bottom:0px;">
                            Last submitted date: {app.submittedStr}
                          </p>
                        {/if}
                        <br />

                        {#if app.isResubmittable}

                          <a
                            href="application/submit/{child.id}/{app.applicationId}">
                            Resubmit application
                          </a>
                          <br />
                        {/if}

                        {#if app.isSchoolEditable}
                          <a
                            aria-label="Edit selected schools for {child.forename}
                            {child.surname}"
                            href="/application/preferences/{child.id}/{app.applicationId}">
                            Edit selected schools
                          </a>
                          <br />
                        {/if}
                        {#if app.isOfferVisible}
                          <a
                            aria-label="view outcome of application for {child.forename}
                            {child.surname}"
                            href="/viewoffer/{app.applicationId}">
                            View outcome of application and respond
                          </a>
                          <br />
                        {/if}
                        {#if app.isApplicationViewable}
                          <a
                            aria-label="view application for {child.forename}
                            {child.surname}"
                            href="/application/summary/{child.id}/{app.applicationId}">
                            View application
                          </a>
                          <br />
                        {/if}
                        {#if app.isApplicationDeleteable}
                          <!-- <a on:click={()=> applicationToDelete = app}>Delete application</a> -->
                          <a
                            aria-label="delete application for {child.forename}
                            {child.surname}"
                            href="/application/delete/{child.id}/{app.applicationId}">
                            Delete application
                          </a>
                          <br />
                        {/if}
                        {#if app.isUploadVisible}
                          <a
                            aria-label="Attach a document for {child.forename}
                            {child.surname}"
                            href="/application/documents/{child.id}/{app.applicationId}">
                            Attach a document
                          </a>
                          <br />
                        {/if}
                        {#if app.isUploadRemoveVisible}
                          <a
                            aria-label="Withdraw a document for {child.forename}
                            {child.surname}"
                            href="/application/documents/{child.id}/{app.applicationId}">
                            Withdraw a document
                          </a>
                          <br />
                        {/if}
                      </div>
                    {/each}
                  {/if}

                </article>

              </div>

            </div>
          </div>
        {/each}
      {/if}
      <div class="tile is-parent" style="padding:0rem;margin-top:1.5rem;">
        <article
        aria-label=""
        class="tile is-child notification"
        style="background-color: transparent;;padding-top:0px;padding-bottom:12px;">
      <h2 style="color: #70457a;"><strong>Additional Information</strong></h2>
      <hr style="margin-top:0px;"/>
      <article class="notification">{@html additionalInformation}</article>
      </article>
      </div>

      <div class="tile is-parent" style="padding-bottom:0px;padding-top:0px;">

        <div
          tabindex="0"
          aria-label="Go to my account"
          class="tile tile-hover is-parent column is-4-widescreen"
          on:click={() => dispatch('pageChange', '/useraccount/details')}
          on:keypress={e => {
            if (e.keyCode == 13) dispatch('pageChange', '/useraccount/details');
          }}>
          <article
            class="tile home-button is-child notification is-primary"
            style="background-color: #6F4077;">
            <div class="columns is-mobile">
              <div class="column is-10"> <p class="title is-4">My Account</p>
                <!-- <p class="subtitle is-6">Check and update your details.</p> -->
                <p style="text-decoration:underline">View</p></div>
              <div class="column is-2"><i class="fas fa-user" style="font-size:2.5rem;"></i>
           </div>

             
            </div>
          </article>
        </div>
        <div
          tabindex="0"
          aria-label="go to find schools"
          class="tile tile-hover is-parent column is-4-widescreen"
          on:click={() => dispatch('pageChange', '/findschools')}
          on:keypress={e => {
            if (e.keyCode == 13) dispatch('pageChange', '/findschools');
          }}>
          <article
            class="tile home-button is-child notification is-primary"
            style="background-color:#416EA1">
            <div class="columns is-mobile">
              <div class="column is-10"> 
                <p class="title is-4">Find schools</p>
                <!-- <p class="subtitle is-6">In your area.</p> -->
                <p style="text-decoration:underline">View</p>
              </div>              
              <div class="column is-2"><i class="fas fa-map-marker-alt" style="font-size:2.5rem;"></i>
             
            </div>
          </article>
        </div>
        <div
          tabindex="0"
          aria-label="Go to help and tutorials"
          class="tile tile-hover is-parent column is-4-widescreen"
          on:click={() => dispatch('pageChange', '/help')}
          on:keypress={e => {
            if (e.keyCode == 13) dispatch('pageChange', '/help');
          }}>
          <article
            class="tile home-button is-child notification is-primary"
            style="background-color: #666666;">
            <div class="columns is-mobile">
              <div class="column is-10"> 
                <p class="title is-4">Help & Tutorials</p>
                <!-- <p class="subtitle is-6">On the eAdmissions system.</p> -->
                <p style="text-decoration:underline">View</p>
              </div>          
              <div class="column is-2"><i class="fas fa-question-circle" style="font-size:2.5rem;"></i>

             
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
{/if}

<ModalConfirm showModal={childToDelete} on:ok={deleteChild} bind:isUpdating={isChildDeleting}>
  Are you sure you want this delete this child?
</ModalConfirm>
<ModalConfirm showModal={showOptoutConfirmModal} on:ok={optoutNotificationAlerts} bind:isUpdating={isOptingOut}>
  Are you sure you want to opt out from text message and/or push notification alerts?
</ModalConfirm>
<Modal bind:showModal={emailvalidated}>
  <p>Your email address has now been validated successfully.</p>
</Modal>

<Modal bind:showModal={showSMSOptinHelp}>
  <Help
    helpId="SmsTrial"
    showSearchOption={false}
    showContent={true}
    doAnimation={false}
    isPopup={true} />

</Modal>
