<script>
  let error = "";
  let reg = '';
  export let number = null;
  export let isRequired = false;
  export let isMobile = false;
  export let isWork = false;
  export let isValid = false;
  export let placeholder = "";
  export let isDirty = false;
  export let label = "";
  export let ref = null;
  export let faIcon = null;
  export let readoutLabel = null;
  // let regex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
  let phoneRegex = /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))$/;
  let mobileRegex = /^(((073)|(074)|(075)|(077)|(078)|(079))\d{2}\s?\d{3}\s?\d{3})$/;
  $: {reg = isMobile == 'true' ? mobileRegex : phoneRegex;}
  
	$: {
    error = ''; 
    isValid = isRequired == 'true' && (number == '' || number == null) ? false : true;
    if(isDirty ){ // && isRequired
      if (isRequired == 'true' && (number == '' || number == null)) {
        isValid = false;
        error = "Please enter your " + label;
      } else if ((number !== "" && number != null) && !reg.test(number) ) {
        isValid = false;
        if (isMobile == 'true'){
          error = "Invalid mobile number format. Please recheck number. ";
        }
        else{
          error = "Invalid number format. Please recheck number. ";
        }
      } else {
        if (isWork == 'false'){
          if (isMobile == 'false') {
            if (number.substring(0,2) == '07'){
              error = "You have entered a mobile number as a landline. Please recheck number. ";
              isValid = false;
            }
            else
            {
              isValid = true;
            }
          }
          else
          {
            isValid = true;
          }
        }
        else
          {
            isValid = true;
          }
        //error ='';
      }
    }
  }

  function handleKeyUp(event){
    //if(number) isDirty = true;
    var charCode = event.which || event.keyCode;
    if(number)
    number=number.replace(/^\s+|\s+$/gm,'');

    if (isNaN(number) || (charCode > 64 && charCode < 91)) {
       //}else if(!(/^(?=.*\d)[\d ]+$/.test(text))){
       //isValid = false;
       event.preventDefault();
       if(number)
       number = number.replace(/[^\d]/gi, "");
       error = "This field only accepts numbers.";
     } 
  }


</script>
<style>
  .fa-icon {
    padding-top: 10px;
    padding-left: 10px;
  }
</style>

<input
  aria-label={readoutLabel ? readoutLabel : label}
  class="input {!isValid && isDirty ? 'is-danger' : ''}"
  type="tel"
  name="numberField"
  on:keyup={handleKeyUp}
  on:blur={ () => {

     if (number)
      number=number.replace(/^\s+|\s+$/gm,'');

      if (!isDirty)
        isDirty = (number != null && number != '');  
      } }
  bind:value={number}
  {placeholder}
  bind:this={ref} 
/>

{#if faIcon}
  <span class="icon is-small is-left fa-icon { faIcon }" />
{/if}
{#if !isValid && isDirty}
  <span>
    <p class="help has-text-danger">{error}</p>
  </span>
{/if}
