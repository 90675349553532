<script>
  import Modal from "../HTMLElements/Modal.svelte";
   import ModalMessage from "../HTMLElements/ModalMessage.svelte";
  import RadioInput from "../HTMLElements/RadioInput.svelte";
  import Help from "../help.svelte";
  import SchoolPicker from "../applicationpreference/schoolpicker.svelte";
  import { Child } from "./store.js";
  import { createEventDispatcher } from "svelte";
  import SiblingDetails from "./siblingdetails.svelte";
  import Showmorediv from "../HTMLElements/showmorediv.svelte";
  import Field from "../FormField.svelte";
  import wrappedFetch from  '../../wrappedFetch.js';

  export let childId;
  export let applicationId;
  let step = "school";
  export let schoolId;
  export let showModal;
  export let laid;
  export let selectedPreferences = [];
  export let focusContext = null;
  export let showDifferentAddressModal;
  export let LAName="";
  let findFocusable = true;
  const dispatch = new createEventDispatcher();
  let school;
  let showHelp = false;
  let showAddSibling = false;
  let showRemoveSibling = false;
  let preference;
  let sibling;
  let modalScrollElement;
  let showMedicalSocHelp = false;
  let showMedicalSocContinue = false;
  let schoolStepErrorMessage;
  let siblingStepErrorMessage;
  let staffChildrenStepErrorMessage;
  let exceptionalReasonStepErrorMessage;
  let siblingDetailsInstance;
  let siblingDetailsError;
  let isLoading = false;
  let siblingRef = null;
  let staffChildRef = null;
  let exceptionalRef = null;
  let helpId;
  let differentAddressModal = false;
  let reasonText;
  let isSiblingAllowed=false;
  
  const SiblingModalTitle = "Sibling Information"; 
  
  $:{
    if(preference != null && preference.reason != null )
    {
      if(!reasonText)
      reasonText = preference.reason;
      if(preference.reason.length > 1000)
    preference.reason = preference.reason.substring(0,1000);  

    }
  }
  const textAreaValue=(e)=>{
    reasonText=e.target.value;
  }
  const searchSchool = sid => {
    schoolStepErrorMessage='';
    wrappedFetch(
      `/preferences/GetPreference?schoolId=${sid}&childId=${childId}&applicationId=${applicationId}`, { method: "post" }
    )
      .then(response => response.json())
      .then(response => {
        if (modalScrollElement && modalScrollElement.scrollTo)
          modalScrollElement.scrollTo(0, 0);
        school = response;
        preference = school.preference;
        preference.hasStaffChildren =
          preference.staffChild == "Y"
            ? true
            : preference.staffChild == "N"
            ? false
            : null;
        preference.hasSibling = preference.siblingId
          ? true
          : schoolId > 0
          ? false
          : null;
        preference.exceptionalReason = null;

        if (preference.medical == "Y" && preference.social == "Y")
          preference.exceptionalReason = "both";
        else if (preference.medical == "Y")
          preference.exceptionalReason = "medical";
        else if (preference.social == "Y")
          preference.exceptionalReason = "social";
        else if (preference.medical == "N" && preference.social == "N")
          preference.exceptionalReason = "none";

        sibling = school.sibling;
        isSiblingAllowed =  school.laAttributes.useLaSiblingInfo || school.phaseAttributes.useSchoolSiblingInfo || school.phaseAttributes.useSchoolSiblingInfo == null;
      });
  };

  const savePreference = () => {
    let data = { ...preference };

    if (data.exceptionalReason == "both") {
      data.medical = "Y";
      data.social = "Y";
    } else if (data.exceptionalReason == "medical") {data.medical = "Y";data.social = "N";}
    else if (data.exceptionalReason == "social") data.social = "Y";
    else if (data.exceptionalReason == "none") {
      data.medical = "N";
      data.social = "N";
    }

    data.staffchild = preference.hasStaffChildren ? "Y" : "N";

    if (!data.hasSibling) data.siblingId = null;
    else data.sibling = sibling;

    isLoading = true;

    wrappedFetch(
      `/preferences/${
        schoolId ? "updatepreference" : "insertpreference"
      }?childId=${childId}&laid=${laid}`,
      {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          _paq.push(['trackEvent', 'Preference', schoolId ? "Updated" : "Added", response.preference.schoolName, response.preference.schoolId])
          showModal = false;
          dispatch("added", response.preference);
        }

        isLoading = false;
      });
  };

  const validateSibling = ()=>{
    siblingDetailsError = "";
    isLoading = true;
    return wrappedFetch(`/child/IsSiblingValid?mainApplicantId=${childId}&schoolId=${preference.schoolId}&applicationId=${applicationId}`,
    {
        method: "post",
        body: JSON.stringify(sibling),
         headers: {
          "Content-Type": "application/json"
        }
    }).then(response => response.json())
      .then(response => {
        if (response.success) {
          if(response.child)
          sibling.ID = response.child.id;
          isLoading = false;
          return true;
        }
        siblingDetailsError = response.message;
        isLoading = false;
        return false;
      });
  }

  if (schoolId) searchSchool(schoolId);
  
</script>
<style>
  .icon {
    color: #209cee; /* #6f4077;*/
    cursor: pointer;
  }
  .bolded {
    font-weight: bold;
  }
</style>

<Modal
  aria-label="School Preferences Popup"
  bind:showModal
  bind:scrollElement={modalScrollElement}
  allowClose={false}
  {findFocusable}
  >

  {#if step == 'school'}
    {#if !school && !schoolId}
      <SchoolPicker tabindex="0"
        on:addSchool={event => searchSchool(event.detail)}
        gender={$Child.gender}
        phaseID={$Child.phaseId}
        phaseName={$Child.phaseName}
        {LAName}
        postcode={$Child.selectedAddress.postcode}
        laid={laid || $Child.selectedAddress.la_id}
        isMultipleApplication={laid ? true : false}
        childID={$Child.id}
        {selectedPreferences}
        on:showHelp={event=>{helpId = event.detail;showHelp = true;}} />
    {/if}
    {#if school}
     <h1 class="title is-4">{school.info.name}</h1> 
      
      {@html school.info.address.split(',').join('<br/>')}

      {#if school.info.workPhone}
        <br />
        {school.info.workPhone}
      {/if}
      {#if school.info.email}
        <br />
        {school.info.email}
      {/if}
      {#if school.attributes.schoolUrl}
        <br />
        <a target="_blank" href={school.attributes.schoolUrl}>School website</a>
      {/if}
<br/><a target="_blank" href="https://www.compare-school-performance.service.gov.uk/school/{school.info.urn}">More information</a>
     
      <!-- {#if !schoolId}
        <button on:click={() => (school = null)}>Change School</button>
        <br />
      {/if} -->
      {#if school.attributes.hasSecondSite}
        <br />
         <br />
        <h4 class="title is-4">Additional School Site</h4> 
        {#if  school.attributes.siteInfo }       
      <p> {@html school.attributes.siteInfo}</p> 
        <br/>
        {/if}
        {#if  school.info.additionalAddress}
      
        {@html school.info.additionalAddress.split(',').join('<br/>') }
        {/if}
       
      {/if}
      {#if school.attributes.hasImportantInfo}
       <br />
        <br />
        <h4 class="title is-4">Important School Information</h4>
       
        {@html school.attributes.information}
     
      {/if}
      <br />
      <br/>
      <h2 class="title is-4">Admissions Criteria</h2>
      <p>
        You should make sure you have read and understood the admissions
        criteria for this school. Please use the following link if you need to
        re-read them.
      </p>
      {#if school.attributes.criteriaUrl}
        <a target="_blank" href={school.attributes.criteriaUrl}>
          School's Admissions Criteria
        </a>
        <br />
      {/if}
      {#if school.bookletUrl}
        <a target="_blank" href={school.bookletUrl}>
          Local Authority Guidance
        </a>
       
      {/if}
    

      {#if school.phaseAttributes.showWarning}
       <br />
     <br/>
        <h4 class="title is-4">Supplementary Information Form</h4>
        {#if school.phaseAttributes.showWarningText}
          {@html school.phaseAttributes.warningText}
        {/if}
        <br />
        {#if school.phaseAttributes.showWarningUrl}
          <a target="_blank" href={school.phaseAttributes.warningUrl}>
            Supplementary Form
          </a>
         
        {/if}
      {/if}
    {/if}


    {#if schoolStepErrorMessage}
    <br/>     
      <p class="has-text-danger">{schoolStepErrorMessage}</p>
    {/if}
  {/if}
     
  {#if step == 'sibling'}
    <h1 class="title is-4">{SiblingModalTitle}</h1>  
      <Showmorediv>
        {#if school.laAttributes.useLaSiblingInfo || school.phaseAttributes.useSchoolSiblingInfo == null}
          {@html school.laAttributes.siblingInfo || ''}
        {:else if school.phaseAttributes.useSchoolSiblingInfo}
          {@html school.phaseAttributes.schoolSiblingInfo || ''}
        {:else}
          {@html school.laAttributes.laSiblingInfoNa || ''}
        {/if}
      </Showmorediv>  
      <br />
   
    {#if isSiblingAllowed}
      <p class="bolded">Do you wish to claim sibling (brother or sister) priority?</p>
      <RadioInput
      label="sibling"
      item={[{ text: 'Yes', value: true }, { text: 'No', value: false }]}
      bind:group={preference.hasSibling} 
      bind:ref={siblingRef}/>
      {#if preference.siblingId}
        <div jwcid="showSiblingDetails">{sibling.forename} {sibling.surname}</div>
      {/if}
    {/if}
    {#if siblingStepErrorMessage}
      <p class="has-text-danger">{siblingStepErrorMessage}</p>
    {/if}
  {/if}
  <!-- {/if} -->
  {#if step == 'siblingdetails'}
  <div style="display:flex;">
    <h1 class="title is-4">Sibling details</h1> <span name="infoIcon" class="icon">
          <span on:click={()=>{helpId='help27';showHelp=true}} class="fas fa-info-circle" />
        </span>
    </div>
     {#if sibling.hasApplication=='Y'}
     <p class="help has-text-danger">This sibling has their own applications. Edits must be made from the Home page.
     <br/>
     Please click 'Continue' to modify other preference details.</p>
     {/if}
    <SiblingDetails tabindex="0"
      id={preference.siblingId}
      bind:this={siblingDetailsInstance}
      bind:sibling
      schoolId={preference.schoolId}
      allowDisable={false} 
      bind:error={siblingDetailsError}
      bind:showDifferentAddressModal
      on:showHelp={event=>{helpId = event.detail;showHelp = true;}}/>
  {/if}
  {#if step == 'staffchildren'}
    <h1 class="title is-4">Does this child have a parent employed at this school?</h1>
    <Showmorediv>
      {@html school.attributes.staffChildrenText || ''}
    </Showmorediv>
    <br />
    <RadioInput
    label="staff children"
      item={[{ text: 'Yes', value: true }, { text: 'No', value: false }]}
      bind:group={preference.hasStaffChildren} 
      bind:ref={staffChildRef} />
    <br />
    {#if staffChildrenStepErrorMessage}
      <p class="has-text-danger">{staffChildrenStepErrorMessage}</p>
    {/if}
  {/if}

  {#if step == 'exceptionalreason'}
    <div style="display:flex;"><h1 class="title is-4">Exceptional Medical or Social Reasons</h1>
      <span name="infoIcon" class="icon"><span on:click={()=>{helpId="help28";showHelp=true}} class="fas fa-info-circle" /></span>
    </div>
   
    <Showmorediv>
      {#if school.laAttributes.useLaMedicalInfo || school.phaseAttributes.useSchoolMedicalInfo == null}
        {@html school.laAttributes.laMedicalInfo || ''}
      {:else if school.phaseAttributes.useSchoolMedicalInfo}
        {@html school.phaseAttributes.schoolMedicalInfo || school.laAttributes.laMedicalInfo}
      {:else}
        {@html school.laAttributes.laMedicalInfoNa || ''}
      {/if}
    </Showmorediv>
    <br />
    {#if school.laAttributes.useLaMedicalInfo || school.phaseAttributes.useSchoolMedicalInfo || school.phaseAttributes.useSchoolMedicalInfo == null}
      <RadioInput
      label="social medical"
        isFlex={true}
        item={[{ text: 'No', value: 'none' }, { text: 'Medical', value: 'medical' }, { text: 'Social', value: 'social' }, { text: 'Medical and Social', value: 'both' }]}
        bind:group={preference.exceptionalReason} 
        bind:ref={exceptionalRef} />
    {/if}
    {#if exceptionalReasonStepErrorMessage}
      <p class="has-text-danger">{exceptionalReasonStepErrorMessage}</p>
    {/if}
    <br />
    <h2 class="title is-4">Reasons for Preference (Optional)</h2>
    Only use the space below if you wish to give any reasons for your preference
    for this school. These might include religious, philosophical or any other
    reasons. <strong>
      Please do not use this box to enter any exceptional, medical or social
      reasons or sibling (brother or sister) information. Please complete the
      relevant sections above.</strong>   
    <br />
    <textarea maxlength="1000" {reasonText} on:input={textAreaValue} on:blur={()=>{preference.reason=reasonText}} rows="5" style="width:100%;margin-top:10px;" >{preference.reason || ''}</textarea>
   <p style="font-size:0.8rem;">({reasonText == null? preference && preference.reason ? preference.reason.length :0 : reasonText.length} of 1000 characters used)</p>
  {/if}

  <footer
    class="modal-card-foot buttons"
    slot="footer"
    style="justify-content: space-between;">
    {#if step == 'school'}
      {#if school && !schoolId}
        <button class="button" on:click={() => (school = null)}>Change School</button>
        <br />
      {:else}
      <button
        class="button is-pulled-left"
        on:click={(e) => {
          if(confirm("This school has not yet been added to your preference list yet. Are you sure you want to cancel?")) {
            showModal = false;
            document.getElementById(focusContext).focus();
          }
          else {e.preventDefault(); }
        }}>
        Cancel
      </button>
      {/if}
    {:else}
      <button
        class="button is-pulled-left"
        on:click={() => {
          if (step == 'sibling') step = 'school';
          else if (step == 'siblingdetails') step = 'sibling';
          else if (step == 'staffchildren') step = preference.hasSibling ? 'siblingdetails' : 'sibling';
          else if (step == 'exceptionalreason') step = school.attributes.staffChildrenRequired ? 'staffchildren' : preference.hasSibling ? 'siblingdetails' : 'sibling';
        }}>
        Back
      </button>
    {/if}
    <button
      id="lastBtn"
      class="button is-primary is-pulled-right {isLoading ? 'is-loading' : ''}"
      disabled={isLoading}
      on:click={async () => {
        if (step == 'school') {
          schoolStepErrorMessage = '';
          if (!school) {
            schoolStepErrorMessage = 'Please select a school';
            return;
          }
          step = 'sibling';
        } else if (step == 'sibling') {
          siblingStepErrorMessage = '';
          if (isSiblingAllowed && preference.hasSibling === null) {
            siblingStepErrorMessage = 'You must indicate if there is a sibling';
            return;
          }
          if (preference.hasSibling) step = 'siblingdetails';
          else {
            step = school.attributes.staffChildrenRequired ? 'staffchildren' : 'exceptionalreason';
          }
        } else if (step == 'siblingdetails') {         
          if(sibling.hasApplication =='Y')
          {
             step = school.attributes.staffChildrenRequired ? 'staffchildren' : 'exceptionalreason';
             return;
          }
            if (await siblingDetailsInstance.validateAndSave() && await validateSibling() && showDifferentAddressModal) {           
              differentAddressModal= true;
             
            }
         else  if (await siblingDetailsInstance.validateAndSave() && await validateSibling()) {       
            step = school.attributes.staffChildrenRequired ? 'staffchildren' : 'exceptionalreason';
          }
          else {           
            modalScrollElement.scrollTop = modalScrollElement.scrollHeight;
          }         
        } else if (step == 'staffchildren') {
          staffChildrenStepErrorMessage = '';
          if (preference.hasStaffChildren == null) {
            staffChildrenStepErrorMessage = 'You must indicate if the child is a staff child';
            return;
          }
          step = 'exceptionalreason';
          
        } else if (step == 'exceptionalreason') {
          if ((school.laAttributes.useLaMedicalInfo === null || school.phaseAttributes.useSchoolMedicalInfo === null || school.phaseAttributes.useSchoolMedicalInfo === true) && preference.exceptionalReason === null) {
            exceptionalReasonStepErrorMessage = 'You must indicate if there is an exceptional medical or social reason';
            return;
          }
          if(preference.exceptionalReason !=null && preference.exceptionalReason  != "none")
            showMedicalSocContinue = true;
          else
           savePreference();          
        }
        findFocusable = true;
      }}>

      {#if schoolId > 0 && step == 'exceptionalreason'}
        Save
      {:else if !schoolId && step == 'exceptionalreason'}
        Add School
      {:else}Continue{/if}
    </button>
  </footer>
</Modal>
<Modal bind:showModal={showHelp}>
  <Help helpId={helpId} />
</Modal>

<ModalMessage bind:showModal={showMedicalSocContinue} on:continue={savePreference} allowClose={false}>
  <div class="field">You must submit evidence to support your medical/social reasons for this school.</div>
</ModalMessage>

<Modal showModal={differentAddressModal}>
  <Help
    helpId="NotAtSameAddress"
    showSearchOption={false}
    showContent={true}
    doAnimation={false} />
  <footer class="modal-card-foot buttons is-right" slot="footer">
    <button class="button continue" on:click={()=> {differentAddressModal=false;step = school.attributes.staffChildrenRequired ? 'staffchildren' : 'exceptionalreason'}}>
      Continue
    </button>
  </footer>
</Modal>

