<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { GetChild } from "./service.js";
  import Select from "../HTMLElements/Select.svelte";
  import {
    Child,
    ChildChunks,
    DefaultChildChunks,
    HelpSummary,
    InCareBoroughs
  } from "./store.js";
  import * as objectWrapper from "../Utils/ObjectWrapper.js";
  import Field from "../FormField.svelte";
  import * as Validator from "../Utils/ValidateFormFIelds.js";
  import Radio from "../HTMLElements/RadioInput.svelte";
  import CheckBox from "../HTMLElements/CheckBoxInput.svelte";
  import Modal from "../HTMLElements/Modal.svelte";
  import Help from "../help.svelte";
  import Cards from "../../components/Cards.svelte";
  import * as utils from "../Utils/utils.js";
  import wrappedFetch from "../../wrappedFetch.js";

  const dispatch = new createEventDispatcher();
  let isMobile = utils.isMobileDevice();
  export let id;
  export let applicationId;

  let error = "";
  let laList = [];
  let showHelpModal = false;
  let isFormValid = true;

  let showChildInCareModal = false;
  let showEHCPWarning = false;
  let showModal = false;
  let cardItem = 0;
  let showEHCPHelpModal = false;
  let showInCareHelpModal = false;
  let helpId ="help30" ;
  let showInfo = false;
  let wrappedObj;
  let ref;
  let isDetailsChanged = false;
  let isSaveAndExit = false;

export let applications =[];
export let lateApplications =[];
let showApplicationResubmit =false;
let isCouncilTaxIncomplete = false;
  let radioItems = [
    { value: true, TextValue: "Yes" },
    { value: false, TextValue: "No" }
  ];
  let showContinue = true;

  export let radioParams = { item: radioItems, required: true };
  export const saveAndExit = async () => {
    isSaveAndExit = true;
    await validateAndSave();   
    if (isFormValid && !showModal && !showApplicationResubmit)
      dispatch("pageChange", `/user/home`);
  };

  const getModalContent = async () => {
    const getHelpContents = await wrappedFetch("helpcontent?helpId=ChildWarnings");
    return await getHelpContents.json();
  };

  onMount(async () => {
    if (!$Child) await GetChild(id);
    isCouncilTaxIncomplete=$Child.showBoroughCouncilTax == 'M' && ($Child.councilTaxRef == null || $Child.councilTaxRef == "")
    if (!$Child.forename || isCouncilTaxIncomplete) {
      dispatch("pageChange", `/application/childdetails/${id}/${applicationId}`);
      return;
    }
    ChildChunks.set({
      extras: {
        id: $Child.id,        
        multipleBirth: $Child.multipleBirth,
        isInCare: $Child.inCareId ? true : false,
        inCareId: $Child.inCareId,
        statement: $Child.statement // ehcp
      }
    });

    DefaultChildChunks.set($ChildChunks)
    wrappedObj = objectWrapper.wrapObject($ChildChunks.extras);
   
      
  });

  $: {
    if (
      wrappedObj &&
      wrappedObj.isInCare.value &&
      $InCareBoroughs.length <= 0
    ) {
      wrappedFetch("borough/getBoroughs?isAdmitting=false")
        .then(res => res.json())
        .then(result => {
          laList = result;
          laList.forEach((data, key) => {
            laList[key]["label"] = data.name;
          });
          laList.unshift({id:"167",label:"Outside England",name:"Outside England"})
          InCareBoroughs.set(laList);
        });
    }
  }

  const handleModalContinue = () => {};

  const getHelpText = title => {
    if ($HelpSummary.length > 1)
      return $HelpSummary.find(h => h.title === title).text;
    // const search =  $HelpSummary.find(h => h.title === title);
    // return search.text;
  };

  const validateAndSave = async () => {
    isFormValid = true;

    showModal = false;
    showChildInCareModal = false;
    showEHCPWarning = false;
        
      ChildChunks.set({ extras: objectWrapper.unwrapObject(wrappedObj) });       
      $DefaultChildChunks.extras.isInCare =  $DefaultChildChunks.extras.isInCare ?"Y" : "N";  

    if (wrappedObj.isInCare.value && !wrappedObj.inCareId.value) {
      isFormValid = false;
      error = "You must select the local authority caring for the child";
      return;
    }

    // Validate
    if (!Validator.validate(wrappedObj)) {
      isFormValid = false;
      error = "Please ensure that you have completed the required fields.";
      wrappedObj = wrappedObj;
      return;
    }

      //Check if anything changed before doing anything else
      isDetailsChanged = !(JSON.stringify($ChildChunks) === JSON.stringify($DefaultChildChunks));
       if(!isDetailsChanged )
       {
          if ($Child.isInCare == 'Y' || $Child.statement == 'Y')
          {
            if($Child.statement == 'Y')
                showEHCPWarning = true;
            if($Child.isInCare == 'Y')
                showChildInCareModal = true;
            showModal = true;
            return;
          }
          if($Child.phase == 'N1')
          dispatch("pageChange", `/application/relationdetails/${id}/${applicationId}`);
          else
          dispatch("pageChange", `/application/currentschool/${id}/${applicationId}`);
            
          return;
       }

 
    let childResp = await saveChild();    
    if (childResp.success) {
      Child.set(childResp.child);

      if (childResp.child.statement == "Y") {
        cardItem++;
        showEHCPWarning = true;
        showModal = true;
      }
      if (childResp.child.isInCare == "Y") {
        cardItem++;
        showModal = true;
        showChildInCareModal = true;
      }      
     // dispatch("notification","Details changed successfully!!");
       applications=childResp.applications || [];
      lateApplications = childResp.lateApplications || [];
      if(applications.length > 0 || lateApplications.length > 0)
      {
        if(!$Child.isSibling){
          showApplicationResubmit = true;
          if(!showModal)
          dispatch('showApplicationResubmitModal',isSaveAndExit ?'/user/home' :`/application/${ $Child.phase == 'N1'? 'relationdetails':'currentschool'}/${id}/${applicationId}`);
        }
      }   
    }
    else{
      isFormValid = false;
      error = childResp.message;
      wrappedObj = wrappedObj;
    }    
  };

  async function saveChild() {   
    const saveChildURL = "child/savechildadditionaldetails";

    const resp = await wrappedFetch(saveChildURL, {
      method: "post",
      body: JSON.stringify($ChildChunks.extras),
      headers: {
        "Content-Type": "application/json"
      }
    });
    return await resp.json();
  }

  const saveAndNavigate = async () => {
  
    await validateAndSave();  
    if (isFormValid && !showModal && !showApplicationResubmit)
    {
      if($Child.phase == 'N1')
      dispatch("pageChange", `/application/relationdetails/${id}/${applicationId}`);
      else
      dispatch("pageChange", `/application/currentschool/${id}/${applicationId}`);
    }
      
  };
</script>

<style>
  .card-header-title {
    color: white;
    font-weight: 500;
  }
</style>

<div class="step-2-shows">
  <div class="field">
    <a href="javascript:;" on:click={()=> {dispatch('back', `/application/childdetails/${id}/${applicationId}`)}}>
      <span class="fas fa-arrow-left" />
      Back
    </a>
  </div>
   <div style="display:inline-flex" >
 <h1 class="title is-3">Child details continued </h1>
 <!-- <span name="infoIcon" class="icon helpicon"  on:click={() => { dispatch('showContextualHelpModal')}}>
 <span class="fas fa-question-circle"></span>
 </span> -->
 </div>
  {#if wrappedObj}
    <Field
      info={getHelpText('Help40')}   
      label=" Are you applying for a school place for twins/triplets etc (multiple birth)?"
      showInfo={!wrappedObj.multipleBirth.isValid && wrappedObj.multipleBirth.isDirty}>
      <span slot="inputField">
        <Radio
          bind:group={wrappedObj.multipleBirth.value}
          bind:isDirty={wrappedObj.multipleBirth.isDirty}
          bind:isValid={wrappedObj.multipleBirth.isValid}
          label="multiple birth"
          {...radioParams} />
      </span>
    </Field>

    {#if wrappedObj.multipleBirth.value == "Y"}
      <p class="help is-danger">
        You must create a separate application for each child.
      </p>
    {/if}

    <CheckBox
      info={getHelpText('Help31')}
      helpId="help31"
      label="Tick if this child is currently, or has previously been, in the care of a Local Authority in England, or in state care outside England."
      bind:checked={wrappedObj.isInCare.value} 
       bind:showModal={showInCareHelpModal}
       bind:showInfo={showInfo}
      on:change={()=>{ showInfo = wrappedObj.isInCare.value; }}/>

    {#if $InCareBoroughs.length > 0 && wrappedObj.isInCare.value}
      <div class="field">
        <div class="">
          <label for="" class="label">Select a Local Authority</label>
          <div>
            <Select
              items={$InCareBoroughs}
              bind:selectedValue={wrappedObj.inCareId.value}
              placeholder="--- Please select ---"
              label="a Local Authority"
              selectedValueText="id"
              bind:isRequired={wrappedObj.isInCare.value} />
          </div>
        </div>
      </div>
    {/if}

    <CheckBox
      {helpId}
      label="Tick if child has an Education Health and Care Plan (EHCP)"
      bind:checked={wrappedObj.statement.value}
      bind:showModal={showEHCPHelpModal}   
      on:change={()=>{if(wrappedObj.statement.value) showEHCPHelpModal=true; }} />

    <hr />
    {#if !isFormValid}
      <div class="notification is-invalid">
        <p>{@html error}</p>
      </div>
    {/if}

    <div>
      <a
        href="javascript:;"
        class="button is-primary"
        on:click={saveAndNavigate}>
        Save and continue
      </a>
    </div>
  {/if}
</div>

<Modal bind:showModal  on:closed = {() => {showModal = false;showContinue = true;}}>
  {#await getModalContent() then content}
    <section class="section">
      <div class="container">

        <div class="columns is-centered">

          <div class="column is-12-tablet">
            <div class="field">
              <p>
                You have ticked the following statement(s). Please read the
                information below and check that this applies to your child.
                {#if cardItem > 1}Please click on each items for details.{/if}
              </p>
            </div>
            {#if showChildInCareModal}
              <Cards showContent={!isMobile && cardItem <= 1}>
                <div class="card-header-title" slot="header">
                  {@html content[2].text}
                </div>
                <div class="content" slot="content">
                  {@html content[3].text}
                </div>
              </Cards>
            {/if}
            {#if showEHCPWarning}
              <Cards showContent={!isMobile && cardItem <= 1}>
                <div class="card-header-title" slot="header">
                  {@html content[4].text}
                </div>
                <div class="content" slot="content">
                  {@html content[5].text}
                </div>
              </Cards>
            {/if}            
          </div>
        </div>
      </div>
    </section>
  {/await}
  <footer class="modal-card-foot buttons is-right" slot="footer">
    <button
      class="button is-purple continue"
      on:click={() => {
        showModal = false;
        showContinue = true;
      }}>
      Cancel
    </button>
    {#if showContinue}
    <button
      class="button is-primary continue"
      on:click={() => {
        showModal = false;
        if(isSaveAndExit && !showApplicationResubmit)
        {
           dispatch("pageChange", `/user/home`);
          return;
        }
        if(showApplicationResubmit)
         dispatch('showApplicationResubmitModal',isSaveAndExit ?'/user/home' :`/application/${ $Child.phase == 'N1'? 'relationdetails':'currentschool'}/${id}/${applicationId}`);
         else
        dispatch('pageChange', $Child.phase == "N1"? `/application/relationdetails/${id}/${applicationId}`:`/application/currentschool/${id}/${applicationId}`);
      }}>
      Continue
    </button>
    {/if}
  </footer>
</Modal>
