<script>
  import { onMount } from "svelte";
  import Field from "../FormField.svelte";
  export let password = null;
  export let confirmPassword = null;
  export let showPolicy = false;
  export let isRequired = false;
  export let focus = true;
  export let showCapsWarning = true;
  let min = 6, max = 12;

  let isCapsOn = false;
  let showPassword = false,
      showConfirmPassword = false;
  let passwordInput, confirmPasswordInput;
  let initialText = "<ul style='list-style-position: outside; padding-left:20px;'>" +
    "<li> The password must be between " +
    min +
    " and " +
    max +
    " characters long.</li>" +
    "<li> Allowed characters: " +
    "<li style='margin-left: 20px; list-style: circle;'> all letters and numbers,</li>" +
    "<li style='margin-left: 20px; list-style: circle;'> !$^&();:-=_+[]{}/#. </li>" +
    "</li>" +
    "<li> Password must not start with a special character.</li>" +
    "</ul>";

  let passwordError = "";
  let confirmPassError = "";

  const reg = /[!$^&();:-=_+\[\]{}\/#]/;
  const regex = /^[\w!$^&();:-=_+\[\]{}\/#]+$/;

  const togglePassword = inputName => {
    if (inputName.id == "password") {
      showPassword = !showPassword;
      if (showPassword) inputName.type = "text";
      else inputName.type = "password";
    } else if (inputName.id == "confirmPassword") {
      showConfirmPassword = !showConfirmPassword;
      if (showConfirmPassword) inputName.type = "text";
      else inputName.type = "password";
    }
  };

 const onPaste=(node)=>{
    node.onpaste = e => {
    e.preventDefault();
  };}
 
    
  onMount(async function() {
    if(focus)
      passwordInput.focus();
  });

  $: {
     
    if(password.isDirty){
      passwordError = "";

      if (password.value && password.value != "") {
        if (
          !regex.test(password.value) &&
          password.value.length > 0
        ) {
          passwordError =
            "The password contains illegal characters - please only use letters, numbers, and the following symbols !$^&();:-=_+[]{}/#";
        } else if (
          regex.test(password.value) &&
          reg.test(password.value.charAt(0))
        ) {
          passwordError = "Password should start with letters or numbers";
        } else if (
          password.value.length > 1 &&
          password.value.length < min
        ) {
          passwordError =
            "Password is " +
            password.value.length +
            " characters long but minimum allowed length is " +
            min;
        } else if (password.value.length > max) {
          passwordError =
            "Password is " +
            password.value.length +
            " characters long but maximum allowed length is " +
            max;
        } else {
          passwordError = "";
        }
      } else if(isRequired == 'true'){
        passwordError = "Please enter a password.";
      }

      if (passwordError) {
        password.isValid = false;
      } else password.isValid = true;

      if (confirmPassword.isDirty && confirmPassword.value != "") matchPassword();
      else {confirmPassError = "Please confirm your password.";}
    }
  }

  const checkInput = e => {
    if (e.getModifierState && e.getModifierState("CapsLock")) isCapsOn = true;
    else isCapsOn = false;
  };

  const matchPassword = e => {
    confirmPassword.isDirty = true;
    if (password.value != confirmPassword.value) {
      confirmPassword.isValid = false;
      confirmPassError = "The two instances of your password do not match.";
    } else {
      confirmPassword.isValid = true;
      confirmPassError = "";
    }
  };

  export const getValidity = e => {
    if( !confirmPassword.isDirty )
      matchPassword();
    
    if(password.isValid && confirmPassword.isValid )
      return true;
    else return false;
  }
</script>

<style>
  .icon {
    padding-top: 10px;
    pointer-events: initial !important;
  }
</style>

  {#if showPolicy == 'true'}
  <p class="is-6" style="font-size: 0.98rem; list-style-position: inside;">
    {@html initialText}
  </p>
  {/if}

  {#if showCapsWarning && isCapsOn}
    <p class="help has-text-centered">
      <span class="fas fa-exclamation-triangle icon has-text-warning" />
      CAPS is on!
    </p>
  {/if}
  <Field label="Password">
  
    <span slot="inputField">
      <input
        aria-label="password"
        type="password"
        id="password"
        bind:this={passwordInput}
        bind:value={password.value}
        placeholder="Enter new password"
        on:blur={() => {password.isDirty = true;}}
        on:keyup={checkInput}
        class="input"
        {min}
        {max}
        required />
      {#if showPassword}
        <span
          class="icon is-small is-right fas fa-eye"
          aria-hidden="true"
          on:click={() => {
            togglePassword(passwordInput);
          }} />
      {:else}
        <span
          class="icon is-small is-right fas fa-eye-slash"
          on:click={() => {
            togglePassword(passwordInput);
          }} />
      {/if}

    </span>
  </Field>
  {#if !password.isValid && password.isDirty}
    <p class="help has-text-danger">{passwordError}</p>
  {/if}

  <Field label="Confirm Password">
    <span slot="inputField">
      <input
        aria-label="confirm password"
        type="password"
        id="confirmPassword"
        placeholder="Re-enter new password"
        bind:this={confirmPasswordInput}
        bind:value={confirmPassword.value}
        class="input"
        on:keyup={checkInput}
        on:blur={matchPassword}
        required 
        autocomplete="off"
        list="autocompleteOff"
        use:onPaste />
      {#if showConfirmPassword}
        <span
          class="icon is-small is-right fas fa-eye"
          aria-hidden="true"
          on:click={() => {
            togglePassword(confirmPasswordInput);
          }} />
      {:else}
        <span
          class="icon is-small is-right fas fa-eye-slash"
          on:click={() => {
            togglePassword(confirmPasswordInput);
          }} />
      {/if}
    </span>
  </Field>
  {#if !confirmPassword.isValid && confirmPassword.isDirty}
    <p class="help has-text-danger">{confirmPassError}</p>
  {/if}

