<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { Child,ShowTooFewSchoolWarning, ShowTowerHamletQuestion } from "./store.js";
  import { flip } from 'svelte/animate';
  import ModalMessage from "../HTMLElements/ModalMessage.svelte";
  import Select from "../HTMLElements/Select.svelte";
  import ModalConfirm from "../HTMLElements/ModalConfirm.svelte";
  import Modal from "../HTMLElements/Modal.svelte";
  import PreferenceModal from './preferencemodal.svelte';
  import TooFewSchools from "./fewpreferences.svelte";
  import TowerHamletQuestions from "./towerhamletquestion.svelte";
  import wrappedFetch from '../../wrappedfetch.js';

  export let id;
  export let applicationId;

  let modalMessage;
  let imagePath = "images/logos/logo_";
  let la_id ="";
  let preferences;
  let preferenceToDelete;
  let sorted = [];
  let showPreferenceModal = false;
  let selectedSchoolId;
  let showTooFewSchoolsWarning = false;
  let isTowerHamlet = false;
  let showTowerHamletQuestion = false;
  let genderDetail;
  let answerDetails;
  let isOwnLa = true;
  let laList =[];
  let laid = '';
  let selectedLa;
  let maxPreference ='';
  let errorMessage;
  let LAName="";

export let applications =[];
export let lateApplications = [];
let isChildInValid=false;
let showIsChildInValid = false;
let isDeletingPreference = false;
let isUpdatingPreference = false;

const dispatch = new createEventDispatcher();


const getLA = async () =>{
   await wrappedFetch(`/borough/GetAvailBoroughsForMultipleApplication?phase=${$Child.phase}&dob=${$Child.dob}&childId=${id}`, {
      method: "post",
    })
      .then(response => response.json())
      .then(response => {
         laList = response;
          laList.forEach((la, key) => {
            laList[key]["label"] = la.name+'('+la.pName+' applications only)';
          });
        // response.forEach(la => {
        //   laList.push(id=la.id,label=la.name+'('+la.pName+' applications only)');

        // });

       return laList;
       })
}

if($Child &&  $Child.ownLa == 'N')
{
  isOwnLa = false;
  getLA();

}
 const numToWords = (number) =>{
    let numbers = [{number :0,word:'ZERO'},{number :1,word:'ONE'},{number : 2, word :'TWO'},{number : 3, word :'THREE'},{number : 4, word :'FOUR'},{number : 5, word :'FIVE'},{number : 6, word :'SIX'}];
    return numbers.find(n =>  n.number ==number);
  }

  const selectPreferencesForLA =() =>{
    preferences = null;
    laid = selectedLa.id
    if(laid && laid != '')
    getPreferences();
  }
  let originalSeq=[];
  const getPreferences = () => {

    return wrappedFetch(`/preferences?applicationId=${applicationId}&childId=${id}&laid=${laid}&x=${new Date().getTime()}`)
      .then(response => response.json())
      .then(response => {
        errorMessage = null;
        if (!response.success)
        {
          isChildInValid = response.isChildInValid;
          showIsChildInValid = response.isChildInValid;
          errorMessage = response.message;
          return;
        }
        originalSeq=[];
        if (!response.preferenceList)
          response.preferenceList = [];

        preferences = response
        LAName = preferences.borough.name;
        if(preferences.preferenceList.length > 0)
        {
          preferences.preferenceList.forEach(element => {
            originalSeq.push({"schoolId" : element.schoolId,"sequence" : element.sequence});
          });
        }


        maxPreference = numToWords(preferences.borough.maxPref);
       if($Child && $Child.gender == "M")
       genderDetail= "son";
       else genderDetail = "daughter";

        laid = response.laId ? response.laId : laid;
/*     Removed 01/08/2024

	     if($Child.selectedAddress.la_id == '211' && preferences.borough.phaseName == 'Secondary')
       {
         isTowerHamlet = true;
         answerDetails = preferences.answers;
       }*/

      });
  }

  const deletePreference = () => {
    isDeletingPreference = true;
    wrappedFetch(`/preferences/deletepreference?applicationId=${preferenceToDelete.applicationId}&schoolId=${preferenceToDelete.schoolId}&sequence=${preferenceToDelete.sequence}&childId=${id}`, {
      method: "post",
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          preferences.preferenceList = preferences.preferenceList.filter(d => d.schoolId != preferenceToDelete.schoolId);
          preferences.preferenceList.forEach(p => {
            if(p.sequence > preferenceToDelete.sequence) {
              p.sequence -= 1;
              p.ordinalSequence = ordinal(p.sequence );
            }
          });
          _paq.push(['trackEvent', 'Preference','Deleted',preferenceToDelete.schoolName,preferenceToDelete.schoolId])
          preferenceToDelete = null;
          preferences = preferences;
          modalMessage = "Preference deleted successfully.";
          dispatch("notification", modalMessage);
          if(applications.length == 0)
            applications.push({});

        }
        isDeletingPreference = false;
      });
    //  UpdatePreferenceSequence(false);
  };

  const ConfirmSelection=()=>{
  if(preferences.preferenceList.length < preferences.borough.maxPref)
   {
  showTooFewSchoolsWarning = true;
   }
   else
    onContinue();
  }

  const addSchool=()=>{showPreferenceModal = true; selectedSchoolId = null; focusContext = 'addSchoolBtn'}
  const addSchool2=()=>{showPreferenceModal = true; selectedSchoolId = null; focusContext = 'addSchoolBtn2'}

  const UpdatePreferenceSequence = (redirect = true) => {
    if(applicationId == 0)
      return

    let sequences=[];
    preferences.preferenceList.forEach(element => {
     sequences.push({"schoolId" : element.schoolId,"sequence" : element.sequence});
    });
    showTooFewSchoolsWarning = false;
    if(redirect &&  (JSON.stringify(originalSeq)==JSON.stringify(sequences)))
    {
      dispatch('pageChange', `/application/submit/${id}/${applicationId}`);
      return false;
    }
   // setContext()
   isUpdatingPreference = true;
     wrappedFetch(`/preferences/UpdatePreferenceSequence?applicationId=${applicationId}&childId=${id}`, {
      method: "post",
       headers: {
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(preferences.preferenceList)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
           if(applications.length == 0)
            applications.push({});
         if(redirect)
           dispatch('pageChange', `/application/submit/${id}/${applicationId}`);

        }
        isUpdatingPreference =false;
      });
  }

  const onContinue = () =>{
       ShowTooFewSchoolWarning.set(false);
 if(isTowerHamlet)
 {
     showTowerHamletQuestion = true;
 }
  else
   dispatch('pageChange', `/application/submit/${id}/${applicationId}`);

  }

  $: sorted = (preferences && preferences.preferenceList) ? preferences.preferenceList.sort(function (a, b) { return a.sequence - b.sequence;}) : [];
  $: la_id =   laid  ?  laid :($Child ?$Child.selectedAddress.la_id : "" );

  const moveUp = (e, item) => {
    e.preventDefault();
    var index = preferences.preferenceList.findIndex(x => x.schoolId == item.schoolId);
    preferences.preferenceList[index - 1].sequence += 1;
    preferences.preferenceList[index].sequence -= 1;
    preferences.preferenceList[index - 1].ordinalSequence = ordinal(preferences.preferenceList[index - 1].sequence );
    preferences.preferenceList[index].ordinalSequence = ordinal(preferences.preferenceList[index].sequence);

    UpdatePreferenceSequence(false);
  }

  const moveDown = (e, item) => {
    e.preventDefault();
    var index = preferences.preferenceList.findIndex(x => x.schoolId == item.schoolId);
    preferences.preferenceList[index + 1].sequence -= 1;
    preferences.preferenceList[index + 1].ordinalSequence = ordinal(preferences.preferenceList[index + 1].sequence );
    preferences.preferenceList[index].sequence += 1;
    preferences.preferenceList[index].ordinalSequence = ordinal(preferences.preferenceList[index].sequence);

    UpdatePreferenceSequence(false);
  }

  const ordinal = (number) => {
			var suffix = "th";
			var hr = number % 100;
			var tr = number % 10;
			if (hr != 11 && hr != 12 || hr != 13)
			{
				if (tr == 1)
				{
					suffix = "st";
				}
				else if (tr == 2)
				{
					suffix = "nd";
				}
				else if (tr == 3)
				{
					suffix = "rd";
				}
      }
			return number + suffix;
  }

   const sort = (id, newIndex) => {
    let index =  preferences.preferenceList.findIndex(x => x.sequence == id);
    let newSequence = newIndex + 1;
     preferences.preferenceList[index].sequence = newSequence;
     let n = newSequence + 1;
     let s= 1;
      preferences.preferenceList.forEach((p, k) => {
        if (k != index) {
          if (newSequence == 1) {
            p.sequence = n++;
          }
           else if (id > newIndex) {
            if( p.sequence >= newSequence) {
            newSequence = newSequence + 1;
            p.sequence = newSequence;
          }
           }
           else{
              if (p.sequence <= newSequence) {
                p.sequence =s++;
              }
           }
       }
     });

        preferences.preferenceList.map(p => {
        p.ordinalSequence = ordinal(p.sequence);
        return p;
      });

  };

  onMount(async () => {
    if(!$Child.scSkip){
      dispatch('pageChange', `/application/secondcontact/${id}/${applicationId}`);
      return;
    }

    if(!$Child.scSkip && (!$Child.scForename || $Child.scForename == '')){
      dispatch('pageChange', `/application/secondcontact/${id}/${applicationId}`);
      return;
    }

   if(isOwnLa && applicationId == 0) await getPreferences();
  });

  export const saveAndExit = async () => {
   // await UpdatePreferenceSequence(false);
    dispatch("pageChange", '/user/home');
  }

  $:{

    if (applicationId > 0)
      getPreferences();
  }

  let focusContext = 'addSchoolBtn';
</script>
<style>
  .card-header {
        background-color: whitesmoke;
  }
  .card-header-title {
    color: #4a4a4a;
    font-weight: 100;
  }
</style>

<div class="step-10-shows" aria-hidden={showPreferenceModal}>
   <div class="field">
              <a href="/application/childsummary/{id}/{applicationId}">
                <span class="fas fa-arrow-left" />
                Back
              </a>
            </div>
{#if !isOwnLa && applicationId <1 }
  <div class= "field" style="margin-bottom : 3rem;">

  <h4 class="title is-3">Select Local Authority</h4>
   <div class="field">

   <p>Select the Local Authority where the nursery school you wish to apply for is located.
   If your Home Local Authority does not appear on the dropdown list, you will need to contact them to find out how to apply to their nursery schools.
   You can find Local Authority contact details on the <a href="/contact">Local Authority details</a> page.</p>
   </div>

<Select items={laList}
        bind:selected={selectedLa}
        placeholder="--- Please select ---"
        label="a Local Authority"
        on:change ={selectPreferencesForLA}
         />

  </div>

{/if}
{#if preferences}
  <div style="display:inline-flex" >
  <h1 class="title is-3">{$Child.phaseName} school preferences for {$Child.forename + ' ' + $Child.surname}</h1>
  <span name="infoIcon" class="icon helpicon"  on:click={() => { dispatch('showContextualHelpModal')}}>
    <span class="fas fa-info-circle"></span>
  </span>
 </div>
  <p class="subtitle is-5">
  List the schools you want to apply for. You can list up to {maxPreference.word} { maxPreference.word == 'ONE' ? 'school' : 'schools'}.
  </p>
  <ul>
  <li>Make sure you have read the Admissions Policy for each school you have listed.</li>
  <li>The Admissions Policy is the set of rules which will be used to decide the order in which children will be offered the available places.</li>
  <li>Make sure you list the schools in the order you prefer them. If you qualify for more than one school, you will only be offered the one which is higher on your list.</li>
  <li>Private schools are excluded.</li>
  <li><a on:click={() => { dispatch('showContextualHelpModal')}}>Click here</a> to find out what Local Authority schools are available on this website.</li>
  <li>Once you have selected your schools you will need to select ‘Confirm selection’.</li>
  </ul>


  <br />
  {#if preferences.preferenceList.length < preferences.borough.maxPref}
  <p>
    <a href="javascript:;" on:click={addSchool} class="button is-primary" id="addSchoolBtn"
       tabindex="{showPreferenceModal ? -1 : 0}">Add school</a>
  </p>
    <br />
  {/if}

  <!-- <p>
    Hold and drag
    <span class="fas fa-grip-lines" />
    to change the preference order.
  </p> -->

    <div id="listWithHandle" class="list-group">
      {#each sorted as item, i (item.schoolId)}
        <div class="list-group-item" id={item.sequence} animate:flip="{{duration: 300}}">
          <div class="card">
            <header class="card-header" style="align-items: center;">
              <p class="card-header-title" style="margin-bottom:0px;">
                 {item.ordinalSequence} Preference
              </p>
              <span>
                {#if i > 0}<span class="fas fa-arrow-up" style="margin-left:10px; margin-right: 10px; cursor: pointer;" on:click={ e => {if(!isUpdatingPreference) moveUp(e, item) }}></span>{/if}
                {#if i < sorted.length - 1 }<span class="fas fa-arrow-down" style="margin-left:10px; margin-right: 10px; cursor: pointer;" on:click={ e => {if(!isUpdatingPreference)moveDown(e, item) }}></span>{/if}
                <!-- <span class="fas fa fa-grip-lines" style="margin-left:10px;cursor: grab;" /> -->
              </span>
            </header>
            <div class="card-content">
              <div class="content">
                <div>
                  <p style="margin-bottom:0px;">
                    <strong>
                      {@html item.schoolName}
                    </strong>
                    {@html item.address}
                    {#if item.siblingId}
                    <p>Sibling: {item.siblingForename} {item.siblingSurname}</p>
                    {/if}
                  </p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a tabindex="{showPreferenceModal ? -1 : 0}" aria-label="edit preference {item.schoolName}" id="editBtn" class="card-footer-item"
                 on:click={() => {selectedSchoolId = item.schoolId; showPreferenceModal=true; focusContext='editBtn'}}
                 on:keypress={(e) => {if(e.keyCode == 13) selectedSchoolId = item.schoolId; showPreferenceModal=true;}}>
                Edit
              </a>
              <a tabindex="{showPreferenceModal ? -1 : 0}" aria-label="delete preference {item.schoolName}" class="card-footer-item"
                 on:click={() => (preferenceToDelete = item)}
                 on:keypress={(e) => {if(e.keyCode == 13) preferenceToDelete = item}}>Delete</a>
            </footer>
          </div>
        </div>
      {/each}
    </div>
  {/if}

   {#if errorMessage && !isChildInValid}
    <div class="notification is-invalid">
        <p class="help">{errorMessage}</p>
      </div>
  {/if}


  <br />


  {#if preferences && preferences.preferenceList && preferences.preferenceList.length >0}
  <div>
    {#if preferences.preferenceList.length > 1 && preferences.preferenceList.length < preferences.borough.maxPref}

      <a href="javascript:;" on:click={addSchool2} class="button is-primary" id="addSchoolBtn2"
        tabindex="{showPreferenceModal ? -1 : 0}">Add school</a>

    {/if}

    <button on:click={ConfirmSelection} class="button is-purple" tabindex="{showPreferenceModal ? -1 : 0}">
      Confirm selection
    </button>
  </div>
  {/if}
</div>

<ModalConfirm showModal={preferenceToDelete} on:ok={deletePreference} bind:isUpdating={isDeletingPreference}>
  Are you sure you want to delete this preference?
</ModalConfirm>

{#if showPreferenceModal}
  <PreferenceModal bind:showModal={showPreferenceModal}
  schoolId={selectedSchoolId} childId={id} applicationId={applicationId} {laid} selectedPreferences={preferences.preferenceList} {LAName}
    {focusContext}
    on:added={(event)=>{
       if(applications.length == 0)
            applications.push({});
    if (applicationId == 0) {
      dispatch("pageRedirect",{url:`/application/preferences/${id}/${event.detail.applicationId}`});
      return;
    }

    if (!selectedSchoolId){

      preferences.preferenceList.push(event.detail);
      modalMessage = "Preference added successfully";
      dispatch("notification", modalMessage);
      if (window.scrollTo)
        window.scrollTo(0,document.body.scrollHeight);
      }
    else{

      //replace item in the array
      preferences.preferenceList = preferences.preferenceList.map(p=> {
        if (p.schoolId == event.detail.schoolId)
          return event.detail;
        return p;
      });
       modalMessage = "Preference updated successfully";
      dispatch("notification", modalMessage);
    }

    }}></PreferenceModal>
{/if}

{#if showTooFewSchoolsWarning}
<TooFewSchools bind:showTooFewSchoolsWarning={showTooFewSchoolsWarning}
maxPreference={preferences.borough.maxPref} selectedPreferences ={preferences.preferenceList.length}
on:continue ={onContinue}
on:addSchool={addSchool}
/>
{/if}
{#if showTowerHamletQuestion}
<TowerHamletQuestions  bind:showTowerHamletQuestion={showTowerHamletQuestion} childId={id} {applicationId} {genderDetail} {answerDetails}
on:success ={()=>{ dispatch('pageChange', `/application/submit/${id}/${applicationId}`);}}
/>
{/if}
<Modal
  bind:showModal={showIsChildInValid} allowClose={false}>
  <div class="section">
    <div class="field">

      <p class="title is-5">
        You have incomplete child details!</p>
      <p><b style="color:#cc0404">{@html errorMessage}</b></p>
        <p style="margin-bottom:0px;">Please click on the button below to go to the child details page and select an option to the outstanding question.</p>
     </div>
  </div>
  <footer class="modal-card-foot buttons is-right" slot="footer">
    <a
      href="javascript:;"
      on:click={() => {
        showIsChildInValid = false;
        dispatch('pageChange', `/application/childsummary/${id}/${applicationId}`);
      }}
      class="button is-primary"
      style="margin-right:10px;">
     Edit child details
    </a>

  </footer>

</Modal>
