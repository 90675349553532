<section class="hero is-fullheight">
    <div class="hero-body">
    <div class="container" style="text-align:center">
    <div class="loader"></div>
     <!-- <progress value="80" max="100"></progress> -->
     </div>
     </div>
     </section>
     <style>
        .loader {
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #407f8a; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          display: inline-flex;
          animation: spin 2s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        </style>
        