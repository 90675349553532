	<script>
    import { createEventDispatcher } from 'svelte';  
    const dispatch = createEventDispatcher();

	export let selected = undefined;
	export let labelText = 'label';
	export let selectedValueText=null;
	export let items = undefined;
	export let placeholder;	
	export let isRequired = true;
	export let isValid = !isRequired;
	export let id="";
	let error = '';
	export let ref = null;
	export let selectedValue =null;
	export let isDirty = false;
	export let label = "";
	export let disabled = null;	
	export let readoutLabel = null;
	export let errorTextRed = true;
	$:{		
	  setTimeout( () => {
  		isValid = true;

		if ( !selected && selectedValue && items.length > 0)
			selected = items.find((i) =>  i.label == selectedValue) || items.find((i) => i.value == selectedValue) || items.find((i) => i.id == selectedValue) ;

		if(selectedValue == '' || !items || items.length == 0)
			selected = '';

		if (isDirty){
			if(isRequired && (selected == '' || !selected )){
				isValid = false;
				error = "Please select " + label;
			} else{			
				isValid = true;
				error= '';
			}
		}
	  }, 0);
	}

	export const handleSubmit = (e) => {
		isDirty = true;
		if (selectedValueText && selectedValueText != '')
			selectedValue = selected[selectedValueText];

		dispatch('change');
	}
	
	export const getValidity = () => {
		if(isRequired && (selected == '' || !selected )){
			return false;
		} else{			
			return true;
		}
		return true;
	}
    </script>
	<style>
	select:disabled{
		border: 1px solid lightgray;
	}
	</style>

<div class="select   { (!isValid && isDirty )  ? 'field is-danger' : ''}" >

	<select aria-label={readoutLabel ? readoutLabel : label} class="{id}"  
			bind:value={selected} 
			on:blur={()=> dispatch('blur')} 
			on:change={handleSubmit}
			placeholder="{placeholder}"  
			bind:this={ref} 
			disabled={disabled}>
		{#if placeholder && placeholder != ""}
			<option value="">{placeholder}</option>
		{/if}
		{#each items as item}
			<option value={item}>
				{item[labelText]}
			</option>
		{/each}
	</select>
</div>

{#if !isValid && isDirty}
<span>
	<p style="margin-bottom:0px;" class={errorTextRed ? "help has-text-danger": "help has-text-danger-white"}>{error}</p>
</span>
{/if}
