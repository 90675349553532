<script>
  import Help from "../help.svelte";
  import { createEventDispatcher, afterUpdate } from "svelte";

  export let showModal = false;
  export let scrollElement;
  export let allowClose = true;
  export let zIndex = 40;
  export let findFocusable = true;
  let focusable = null;
  const dispatch = new createEventDispatcher();

  afterUpdate(()=>{
    if (showModal){
      let element = document.querySelectorAll(".modal-background")    
      if (element && allowClose) {
        element.forEach(el =>{      
          el.addEventListener('click', function() {
            showModal = false;
            dispatch("closed");
          });
        })
      }
    }
  })
  
  let trap = false;
  const trapFocus = e =>{
    if(findFocusable){
      focusable = document.querySelector("#modalPopup").querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      focusable[0].focus();
      findFocusable = false;
    }
    if(focusable.length == 2)
      trap = true;

    if(trap){
      e.preventDefault();
      focusable[0].focus();
      trap = false;
    }
    else if (e.target == focusable[focusable.length - 2] ) {
      trap = true;
    }
  }

  const focusLast = e => {
    if(focusable && e.target == focusable[0]){
      focusable[focusable.length - 1].focus();
    }
  }
  
</script>

<style>
  .modal {
    padding: 3rem 1.5rem;
  }
  
</style>

<!-- <svelte:body on:click={()=> showModal=false}></svelte:body> -->
{#if showModal}
  <div class="modal is-active" style="z-index:{zIndex}"
       role="dialog" 
       aria-modal="true" 
       aria-label="modal Popup" 
       id="modalPopup" 
       on:keyup={ e =>{ 
         if(e.keyCode == 9) {
           trapFocus(e);
         }
       }}
       on:keydown={e => {
         if(e.shiftKey && e.keyCode == 9){
           focusLast(e);
         }
       }} >
    <div class="modal-background" />
    <div class="modal-card">
      <section class="modal-card-body" bind:this={scrollElement}>
        <slot />
      </section>
       <slot name="footer" />
       {#if allowClose}
      <button class="modal-close is-large" aria-label="close" 
              on:click={()=>{
                dispatch("closing");
                showModal = false;
                dispatch("closed");
              }} />
      {/if}
    </div>
    <div id="postDiv" tabindex="0"></div>
  </div>
{/if}
