<script>
	import { onMount, createEventDispatcher } from "svelte";
	import { SupportUser, Response,isFormAlreadySubmitted } from "./store.js";
	import Steps from "./steps.svelte";
	import wrappedFetch from "../../wrappedFetch.js";
	import Field from "../../components/FormField.svelte";
	import Select from "../../components/HTMLElements/Select.svelte";
	import CheckBox from "../../components/HTMLElements/CheckBoxInput.svelte";
	const dispatch = new createEventDispatcher();
	let selectedLA;
	let las = [];
	let issues = [];
	let selectedIssue;
	let errMsg = "";
	let isDirty = false;

	let problemNotSolved = false;
	let isPageLoading = true;

	const getBorough = async () => {
		let resp = await wrappedFetch(
			"/borough/GetBoroughs?isAdmitting=true",
			{ method: "post" }
		);
		return await resp.json();
	};

	const getContent = async () => {
		const getHelpContents = await wrappedFetch(
			"helpcontent?helpId=contactissues"
		);
		let resp = await getHelpContents.json();
		let id = 1;
		resp.forEach((r, i) => {
			if (r.type == "h3") {
				issues.push({ id: id, label: r.text, helpText: resp[i + 1].text });
				id++;
			}
		});

		//return issues;

		// return resp.filter(r => r.type=="h2")[0].text;
	};
	const gotoNext = () => {
		isDirty = true;

		if (!selectedLA || !selectedIssue) {
			errMsg = "Please ensure that you have completed the required fields.";
			return;
		}

		$Response.la = selectedLA.label;
		$Response.issue = selectedIssue.label;
		$Response.problemNotSolved = problemNotSolved;
		$Response.step2 = true;
		dispatch("pageChange", "/support/step3");
	};
	onMount(async () => {
		if (!$Response.step1 || isFormAlreadySubmitted()) dispatch("pageChange", "/support/step1");

		las = await getBorough();
		las.forEach((data, key) => {
			las[key]["label"] = data.name;
		});
		las.push({ id: "0", label: "Other" });

		await getContent();
		if ($Response.step2) {
			selectedLA = las.filter((l) => l.label == $Response.la)[0];
			selectedIssue = issues.filter((l) => l.label == $Response.issue)[0];
			problemNotSolved = $Response.problemNotSolved;
		} else if ($SupportUser != null)
			selectedLA = las.filter((i) => i.id == $SupportUser.laID)[0];

		isPageLoading = false;
	});
</script>
<style>
    .icon{
        color: #209cee;
        font-size: 1.5rem;
        cursor: default;
    }
</style>
{#if !isPageLoading}
	<Steps
		backUrl="/support/step1"
		buttonText="Next"
		disabled={!problemNotSolved}
		bind:isPageLoading
		on:handleNext={() => gotoNext()}
		on:pageChange={(event) => dispatch('pageChange', event.detail)}>
		<div class="column">
			<Field label="Select your Local Authority">
				<span slot="inputField">
					<Select
						items={las}
						bind:selected={selectedLA}
						isRequired={true}
						{isDirty}
						selectedValueText="id"
						label="a Local Authority"
						placeholder="Please select a local authority" />
				</span>
			</Field>

			<p style="margin-top:10px;">
				If you need to speak to your Local Authority in regards to this issue,
				then please
				<a href="/contact">click here</a>.
			</p>
			<Field label="What does your issue relate to?">
				<span slot="inputField">
					<Select
						items={issues}
						bind:selected={selectedIssue}
						isRequired={true}
						{isDirty}
						selectedValueText="id"
						label="issue list"
						placeholder="Please select an issue" />
				</span>
			</Field>
			<div style="padding: 10px 0px;">
				{#if selectedIssue}
					<article class="tile is-child notification">
						<div
							class="field"
							style="display:flex;justify-content:space-between">
							<p name="label" class="label">Help: {selectedIssue.label}</p>
							<span name="infoIcon" class="icon"><span
									class="fas fa-info-circle"
									tabindex="0"
									aria-label="Password Help help" /></span>
						</div>
						{@html selectedIssue.helpText}

						<hr style="background-color: rgb(166, 205, 209);height: 1px;"> 
                        <p><i>If this information resolves your issue, please click the 'Exit' button below to leave this form.</i></p> 
						<button
							class="button"
							style="font-size:1rem;"
							on:click={() => {
                                  _paq.push(['trackEvent', 'SupportForm', `Exit`]);
                                if (SupportUser != null) 
                                dispatch('pageChange', '/user/home');
                                else 
                                dispatch('pageChange', '/');
							}}>
							Exit
						</button>

						<!-- <p>You can reset your password by doing following steps.If you are still encountering issues, please press Next</p> -->
					</article>
			
				<br />
				<CheckBox
					label="Tick if this did not solve your problem."
                    bind:checked={problemNotSolved} />
                    {/if}
				{#if errMsg != ''}
					<p id="errMsg" class="help is-danger">
						{@html errMsg}
					</p>
				{/if}
			</div>
		</div>
	</Steps>
{/if}
