<script>
import { createEventDispatcher } from 'svelte';
import Modal from "./Modal.svelte";
import Help from "../Help.svelte";
export let checked=false;
export let label ="";
export let info= "";
export let helpId="";
export let modalContent ="";
export let showInfo = false;
export let showModal = false;
export let isDisabled = false;
export let isLabelBold=false;
const dispatch =new createEventDispatcher();

function toggleInfo() {
  showInfo = !showInfo;
  if(showInfo)
    _paq.push(['trackEvent', 'Info', label]); 
}


const change=(label)=>{
  let isChecked = checked?'Yes':'No'; 
  _paq.push(['trackEvent', 'Checkbox', `[${isChecked}]${label}`]);
  dispatch('change');
}
</script>

<style>
  .info-container {
    border-left: 2px solid #6f4077;
    font-size: 14px;
    color: #6f4077;
    /* margin-bottom: .5rem !important; */
  }
  .field-container {
    padding: 10px 0px;
  }
  .icon {
    color: #209cee; /* #6f4077;*/
    cursor: pointer;
  }
</style>

<div class="field-container" style="display:flex">
  <label class="checkbox" disabled={isDisabled ? true : null}>
    <input
    class="checkbox"
      aria-label={label}
      type="checkbox"
      disabled={isDisabled ? true : null}
      bind:checked={checked}    
      on:change={()=>{change(label)}} />
      {#if isLabelBold}
       <span> <strong>{@html label}</strong></span>
      {:else}
    <span> {@html label}</span>
    {/if}
  </label>
  {#if info!="" || helpId != ""}
    <span name="infoIcon" class="icon">
      <span on:click={()=>{ info!="" ? toggleInfo() : showModal = true;}} 
            on:keyup={ e =>{ if(e.keyCode == 13) info != "" ? toggleInfo() : showModal = true;}}
            class="fas fa-info-circle" 
            tabindex="0" aria-label={label + ' help'} />
    </span>
  {/if}
</div>
{#if info != '' && showInfo}
  <div class="info-container">
    <p style="padding: 5px;">
      {info}
      {#if helpId != ''}
        <div
          on:click={() => {
            showModal = true;
          }}
          on:keyup={ e =>{ if(e.keyCode == 13) showModal = true;}}
          style="padding-left: 0px;" 
          tabindex="0" aria-label={label + ' help'} >
          <p class="help is-info" style="margin-left: 0; cursor: pointer;">
            More Info
          </p>
        </div>
      {/if}
    </p>
  </div>
{/if}

{#if showModal}
  <Modal  bind:showModal >
  {#if helpId !=""}
    <Help {helpId} isPopup={true}/> 
  {:else if modalContent !=''}
    {modalContent}
  {/if}
  </Modal>
{/if}