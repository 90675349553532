<script>
  import { afterUpdate, onMount } from "svelte";
  import Map from "../components/findschools/map.svelte";
  import Select from "../components/HTMLElements/Select.svelte";
  import wrappedFetch from "../wrappedFetch.js";
  let mapFunctions;
  let showMap,
    showPhases = false;
  let laList = [];
  let phases = [];
  let selectedPhases = "";
  let isPostcodeSearch = false;
  let selectedLocalAuthority = "";
  let postcodeInput = "";
  let postcode = "";
  let isNoResult  = false;
  let ref = null;
  let errMsg = "";
  $:{if(isNoResult)  
    errMsg="No schools were found for this postcode"; 
    else  
     errMsg ="";
 
   }
  

  let params = {
    showMap: showMap,
    selectedLA: { selectedLocalAuthority },
    isPostcodeSearch: isPostcodeSearch,
    postcode: postcode,
    showAddSchool: false   
  };

  //onMount(async function() {
    wrappedFetch("https://maptest.lgfl.org.uk/eadmissions/las.ashx")
      .then(res => res.json())
      .then(result => {
        laList = result;
        laList.forEach((data, key) => {
          laList[key]["label"] = data.laName;
        });
      });
  //});
  let filteredLaList;
  $: {
    if(!isPostcodeSearch && selectedLocalAuthority && selectedLocalAuthority.laId != '000')
      filteredLaList = laList.filter(x => x.laId != '000');
    else
      filteredLaList = laList;
  }

  const addSchoolToApplication = school => {
    const selectedschool = school.detail;    
  };
  let Route = Map;

  const handleMap = LAID => {

    let serviceURL = "";
    //params.selectedLA ="";
    if (isPostcodeSearch) {
      serviceURL =
        "https://maptest.lgfl.org.uk/eadmissions/search.ashx?postcode=" + LAID;
    } else {
      serviceURL =
        "https://maptest.lgfl.org.uk/eadmissions/search.ashx?laid=" + LAID;
    }
    wrappedFetch(serviceURL)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          
          errMsg = "";
          params.selectedLA = selectedLocalAuthority = result;
        
          if (isPostcodeSearch)
            params.selectedLA.laId = selectedLocalAuthority.laid;
          else params.selectedLA.laId = LAID;

          phases = [];
          selectedLocalAuthority.phases.forEach((data, key) => {
            phases.push(data);
            phases[key]["isChecked"] = true;
            params.showMap = true;
            params.isPostcodeSearch = isPostcodeSearch;
            params.postcode = LAID.toUpperCase();
            showPhases = true;
          });
        } else {
          params.showMap = false;
          showPhases = false;
          selectedLocalAuthority = laList.find(l => l.laId == "000");
          errMsg = result.message;
        }
      });
  };
  const handleSelectLocalAuthority = selectedAuthority => {   
    
    isPostcodeSearch = false;
    postcodeInput = "";
    errMsg = "";
    if (selectedAuthority.laId == "000") {
      showPhases = false;
      params.showMap = false;
    } else {
      handleMap(selectedAuthority.laId);
    }
  };
  const handlePhaseChange = () => {
    selectedPhases = "";
    errMsg = "";
    Array.from(document.querySelectorAll('input[type="checkbox"]'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)
      .forEach(v => {
        selectedPhases += v + ",";
      }); 
    mapFunctions.setSchoolMarkers(
      "LIVE",
      false,
      selectedLocalAuthority,
      selectedPhases,
      "",
      "",
      selectedLocalAuthority.laId
    );

    //call function setSchoolMarkers
  };
  const searchByPostcode = () => {
    //params.showMap=false;
  isNoResult = false;
  errMsg = "";
    if (postcodeInput == "") {
      errMsg = "No Postcode entered";
      return false;
    } else {
      isPostcodeSearch = true;
      selectedLocalAuthority = laList.find(l => l.laId == "000");
      postcode = postcodeInput.replace(/(\S*)\s*(\d)/, "$1 $2");
      handleMap(postcode);
    }
  };

  const handlePostcodeInput = e => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code == 13) searchByPostcode();
  };
  
  onMount(() => {
    ref.focus();
  });
</script>
<style>
.checkbox:hover  {
    color: #fff; }

     @media screen and (max-width: 1220px){
       .search-tile{       
         width: 760px;
       }
       .main-tile{
         display: block;
       }
     }

  .checkDisplay{
      display: flex;
    }
  
  @media screen and (min-width: 767px) {
    .checkDisplay{
      display: inline-flex;
      width: 45%;
    }
  }
</style>

<svelte:head>
  <title>Find schools | eAdmissions</title>
</svelte:head>

<section class="section">
  <div class="container">
    <div class="field">
      <a href="/">
        <span class="fas fa-arrow-left" />
          Back
      </a>
    </div>
    <div id="main-div" class="tile main-tile">
      <div
        class="tile notification is-primary is-vertical is-4 search-tile contact-container-left"
        style="margin-bottom:0px; ">
        <h1 class="title">Find schools</h1>
        <p class="subtitle is-6">
          Please select a local authority from the drop-down list or search to
          find schools in your area.
          <br><br>
          To make an application, please sign into your account and press the Start Application button.
        </p>
        <div class="field">
          <label id="lalbl"  class="label" style="color:white;">
            Select a Local Authority
          </label>
          <Select
            aria-labelledby="lalbl"
            id="laselect"
            items={filteredLaList}
            bind:selected={selectedLocalAuthority}
            placeholder=""
            on:change={handleSelectLocalAuthority(selectedLocalAuthority)}
            bind:ref />
        </div>
        <label for="postcode" class="label" style="color: white;">or enter your postcode</label>
        <div class="field has-addons">
          <div class="control">
            <input
              id="postcode"
              type="text"
              class="input"
              required=""
              placeholder="Postcode"
              bind:value={postcodeInput}
              on:keypress={handlePostcodeInput} />
          </div>
          <div class="control">
            <button
              class="button is-default"
              style="background-color: #f1f1f1;"
              on:click={searchByPostcode}>
              Search
            </button>
          </div>

        </div>
        {#if errMsg != ''}
          <div class="control">
            <p class="help has-text-danger-white">{errMsg}</p>
          </div>
        {/if}
        {#if showPhases}
          <br />
          <div class="control">
            <label for="" class="label" style="color: white;">
              Admission Phases
            </label>
            {#each phases as phase}
              <label class="checkbox checkDisplay" style="margin-top: 10px;">
                <img src="/images/map/arrow_{phase.colour}.png" alt="marker" />
                <input
                  name="phases"
                  type="checkbox"
                  bind:checked={phase.isChecked}
                  value={phase.phaseId}
                  on:click={handlePhaseChange} />
                {phase.phase}
              </label>
            {/each}
          </div>
        {/if}

      </div>
    

      <svelte:component
        this={Route}
        bind:isNoResult
        {...params}
        bind:this={mapFunctions}
        on:addSchool={addSchoolToApplication} 
        on:LAChange={(laId)=> { 
            selectedLocalAuthority = laList.find(l => l.laId ==laId.detail);
            handleSelectLocalAuthority(selectedLocalAuthority);
         }}
/>

    </div>

  </div>

</section>
