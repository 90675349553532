<script>
	import { onMount, createEventDispatcher } from "svelte";
	import Field from "../FormField.svelte";
	import DOB from "../HTMLElements/DOB.svelte";
	import TextBox from "../HTMLElements/TextBox.svelte";
	import Radio from "../HTMLElements/RadioInput.svelte";
	import AddressPicker from "../HTMLElements/AddressPicker.svelte";
	import Number from "../HTMLElements/NumberInput.svelte";
	import {
	Child,
	HelpSummary,
	ChildChunks,
	DefaultChildChunks,
	} from "./store.js";

	import { User } from "../../store.js";
	import { GetChild } from "./service.js";
	import * as objectWrapper from "../Utils/ObjectWrapper.js";
	import Modal from "../HTMLElements/Modal.svelte";
	let differentAddressHelpId = "NotAtSameAddress";
	let showDifferentAddressModal = false;
	import * as Validator from "../Utils/ValidateFormFields.js";
	import * as ExtractMsg from "../Utils/UserMsgFromInvalidResponse.js";
	import Help from "../help.svelte";
	import wrappedFetch from "../../wrappedFetch.js";
	const dispatch = new createEventDispatcher();
	export let id;
	export let applicationId;
	export let isSibling;

	let showCouncilTax = false;
	let selectedAddress;
	let dobError = "";
	let dobStr;
	let wrappedObj;
	let dateofbirth;
	let isValidDOB;
	let isValidDate = undefined;
	let isPostcodeDirty = false;
	let showBoroughCouncilTax = "";
	let isDOBRequired = true;
	let bindParams = {
	isRequired: isDOBRequired,
	dateFrom: new Date("1990/01/31"),
	dateTo: new Date("2021/01/31"),
	};

	let isFormValid = true;
	let error = "";
	let isLoading = false;
	let isDetailsChanged = false;
	let isSaveAndExit = false;
	let isCounciltaxRequired = false;
	let childWithSameDetailsExists = false;
	let phaseName = "";
	let applicationYear="";
	let showDOBHelp = false;

	export let applications = [];
	export let lateApplications = [];
	let showApplicationResubmit = false;
	
		let item = [
	{ value: "Boy", TextValue: "Boy", disable: isSibling },
	{ value: "Girl", TextValue: "Girl", disable: isSibling },
	];
	export let radioParams = { item: item, required: true, label: "sex" };
	let isAddressRequired = !$Child.useParentAddress;
	export const saveAndExit = async () => {
	isSaveAndExit = true;
	await validateAndSave();
	if (isFormValid && !showDifferentAddressModal && !showApplicationResubmit) {
			dispatch("pageChange", `/user/home`);
		}
	};

	const handleChange = async () => {
		showBoroughCouncilTax = await wrappedFetch(
			"child/ShowBoroughCouncilTax?laid=" + wrappedObj.address.value.la_id,
			{ method: "post" }
		).then((result) => result.text());
		wrappedObj.address.value.showCounciltax = showBoroughCouncilTax;
		showCouncilTax =
			showBoroughCouncilTax == "Y" || showBoroughCouncilTax == "M";
		wrappedObj.councilTaxRef.isRequired = showBoroughCouncilTax == "M";

		if($User.hasOutOfCohort == true)
		{
		await isValidDob();
		}
	};

	const checkValidDOBRange = async () => {
		if($User.hasOutOfCohort && wrappedObj &&  wrappedObj.dob.isValid && (wrappedObj.useParentAddress.value || (!wrappedObj.useParentAddress.value && selectedAddress != null)))
		{
			await isValidDob();
		}
	}


	onMount(async () => {		
		//$User.hasOutOfCohort = true;	
		if (!$Child || $Child.id != id) await GetChild(id);	
		
		ChildChunks.set({
			details: {
				id: $Child.id,
				parentId: $Child.parentId,
				forename: $Child.forename,
				middleName: $Child.middleName,
				surname: $Child.surname,
				dob: $Child.dob,
				gender: $Child.gender ? ($Child.gender == "M" ? "Boy" : "Girl") : null,
				useParentAddress: $Child.useParentAddress,
				addressId: $Child.addressId,
				councilTaxRef:
					$Child.councilTaxRef == "undefined" || $Child.councilTaxRef == "null"
						? ""
						: $Child.councilTaxRef,
				address: $Child.address,
				parentAddress: $Child.parentAddress,
				isParentOutOfCohortAuthenticated: $User.hasOutOfCohort
			},
		});

		DefaultChildChunks.set($ChildChunks);
		wrappedObj = objectWrapper.wrapObject($ChildChunks.details);

		if (wrappedObj.useParentAddress.value)
			selectedAddress = wrappedObj.parentAddress.value;
		else
			selectedAddress =
				wrappedObj && wrappedObj.address.value
					? wrappedObj.address.value
					: null;

		showBoroughCouncilTax = selectedAddress.showCounciltax;
		showCouncilTax =
			showBoroughCouncilTax == "Y" || showBoroughCouncilTax == "M";
		wrappedObj.councilTaxRef.isRequired = showBoroughCouncilTax == "M";
	});

	if ($Child && $Child.dobError == "Y")
	dobError="An application for the current admissions round cannot be made for a child with this date of birth.";
	//	dobError = "Not valid for current admissions round";

	if($Child && $Child.phaseName != null)
	{	
		
	let yeargroup = $Child.phaseId == 3 || $Child.phaseId == 5 ? ` (Year ${$Child.yearGroup})`:'';//show year group for Junior and Secondary below DOB
		//phaseName = $Child.outOfCohort == "Y" ? $Child.phaseName+ yeargroup+' (Out of Cohort)':$Child.phaseName+yeargroup;
		phaseName = $Child.phaseName+yeargroup;
	}
	if($Child && $Child.applicationYear != null)	applicationYear =$Child.applicationYear;

	const handleModalContinue = () => {
		showDifferentAddressModal = false;
		if (!showApplicationResubmit && isSaveAndExit) {
			dispatch("pageChange", `/user/home`);
			return;
		}
		if (showApplicationResubmit)
		{			
			dispatch(
				"showApplicationResubmitModal",
				!isSaveAndExit
					? `/application/childattributes/${$Child.id}/${applicationId}`
					: "/user/home"
			);
		}
		else
		{			
			dispatch(
				"pageChange",
				`/application/childattributes/${$Child.id}/${applicationId}`
			);
		}
	};
	const getHelpText = (title) => {
		if ($HelpSummary.length > 1)
			return $HelpSummary.find((h) => h.title === title).text;
	};

	const isValidDob = async () => {
		if (!wrappedObj.dob.isValid) {
			dobError = "";
			return;
		}
		var resp;

		if (wrappedObj && wrappedObj.useParentAddress.value) 
			selectedAddress = wrappedObj.parentAddress.value;
	
		if($User.hasOutOfCohort == true)
		{
			var selectedIAid = selectedAddress.la_id;
			if (typeof selectedIAid == 'undefined')
			{
				selectedIAid = selectedAddress.lA_id;
			}

			resp = await wrappedFetch(`child/IsDobInRangeForOutOfCohort?dob=${dobStr}&laid=${selectedIAid}`, {
				method: "post",
			});
		}
		else
		resp = await wrappedFetch("child/IsDobInRange?dob=" + dobStr, {
			method: "post",
		});
		let result = await resp.json();
		if (result.success) 
		{
			dobError = "";
		let yeargroup = result.phase.id == 3 || result.phase.id == 5 ? ` (Year ${result.phase.yearGroup})`:'';//show year group for Junior and Secondary below DOB
			phaseName = result.phaseName+yeargroup;

		}
		else dobError = result.message;
	};

	async function saveChild() 
	{
		async function callSavePost()
		{
			$ChildChunks.details.gender =
				$ChildChunks.details.gender == "Boy" ? "M" : "F";
			$ChildChunks.details.useParentAddress = wrappedObj.useParentAddress.value
				? "Y"
				: "N";
			$ChildChunks.details.councilTaxRef =
				wrappedObj.councilTaxRef.value != null &&
				wrappedObj.councilTaxRef.value != "null"
				? String(wrappedObj.councilTaxRef.value)
				: null;

			isLoading = true;

			var resp = await wrappedFetch("/child/SaveChildPersonalDetails", {
				method: "post",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify($ChildChunks.details),
			});

			return await resp.json();	
		}

		var res = await callSavePost();

		if (typeof(res) !== 'undefined' && res.success)
		{
			if (IS_DEV) console.log("saved child details ok");
			return res.data;
		}
		else
		{
			isFormValid = false;
			if (IS_DEV) console.log("Error saving child details.");
			console.log(res);

			if (res.message =="SubmittedPhasedChanged"){
				error = ExtractMsg.ExtractMsgsFromInvalidResponse (res, 
                            "The child's date of birth cannot be changed. Your application will need to be cancelled. Please contact your home <a href='https://www.eadmissions.org.uk/contact/' target='_blank' style='text-decoration:underline'>Local Authority</a>.", 
                            "Problem saving the your child details.");
			}
			else if (res.message =="UnSubmittedPhasedChanged"){
				error = ExtractMsg.ExtractMsgsFromInvalidResponse (res, 
                            "The child's date of birth cannot be changed. You will need to delete this application from your home page. Any problem please contact your home <a href='https://www.eadmissions.org.uk/contact/' target='_blank' style='text-decoration:underline'>Local Authority</a>.", 
                            "Problem saving the your child details.");
			}
			else
			{
				error = ExtractMsg.ExtractMsgsFromInvalidResponse (res, 
                            "Unfortunately, we are unable to save your childs details. Please contact our <a href='https://www.eadmissions.org.uk/support/' target='_blank' style='text-decoration:underline'>technical support team</a> if this problem is persistent.", 
                            "Problem saving the your child details.");
			}
		}
	}

	const isNameAllowed = () => {
	return wrappedFetch(
	"uso/isnameallowed?fn=" +
	wrappedObj.forename.value +
	"&ln=" +
				wrappedObj.surname.value
		)
			.then((res) => res.json())
			.then((result) => {
				return result;
			});
	};

	$: {
		selectedAddress = wrappedObj && wrappedObj.address.value ? wrappedObj.address.value	: null;

		if (wrappedObj && wrappedObj.useParentAddress.value) {
			selectedAddress = wrappedObj.parentAddress.value;
			wrappedObj.address.isValid = true;
		} 
	
		showBoroughCouncilTax =
			selectedAddress != null && selectedAddress.showCounciltax;
		showCouncilTax =
			showBoroughCouncilTax == "Y" || showBoroughCouncilTax == "M";
		if (wrappedObj)
			wrappedObj.councilTaxRef.isRequired = showBoroughCouncilTax == "M";
		if (wrappedObj && wrappedObj.councilTaxRef.value && !showCouncilTax)
			wrappedObj.councilTaxRef.isDirty = false;
		if (
			wrappedObj &&
			(wrappedObj.councilTaxRef.value == null ||
				wrappedObj.councilTaxRef.value == "") &&
			!showCouncilTax
		)
			wrappedObj.councilTaxRef.isValid = true;
	}

	const validateName = nameInput =>{
		let regExp = /[a-z]/i;//for checking alphabets only
		let regExp1 =/[^\u0000-\u007f]/;//for checking utf8 characters
		let isMatch = regExp.test(nameInput.value.charAt(0))
		let isMatch1 = regExp1.test(nameInput.value.charAt(0))
		if(!isMatch && !isMatch1)
		{
			isFormValid = false;
			return false;
		}
		if(nameInput.value.includes("@"))
		{
			isFormValid = false;
			return false;
		}
		return true;
	}

	const validateAndSave = async () => {	
		if (!showCouncilTax) wrappedObj.councilTaxRef.value = "";

		isFormValid = true;
		ChildChunks.set({ details: objectWrapper.unwrapObject(wrappedObj) });

		isDetailsChanged = !(
			JSON.stringify($ChildChunks) === JSON.stringify($DefaultChildChunks)
		);

		isPostcodeDirty = true;
		// Validate

		if (!Validator.validate(wrappedObj)) {
			console.log(wrappedObj);
			isFormValid = false;
			error = "Please ensure that you have completed the required fields.";
			wrappedObj = wrappedObj;
			return;
		}

		if (
			showBoroughCouncilTax &&
			wrappedObj.councilTaxRef.value &&
			!(wrappedObj.councilTaxRef.value.match(/[^0-9a-z]/i) == null)
		) {
			isFormValid = false;
			wrappedObj.councilTaxRef.isDirty = true;
			wrappedObj.councilTaxRef.isValid = false;
			error =
				"Please ensure that you have numbers and letters in council tax reference.";
			wrappedObj = wrappedObj;
			return;
		}
		if (!isDetailsChanged) {
			if ($Child.useParentAddress != "Y") showDifferentAddressModal = true;
			else
				dispatch(
					"pageChange",
					`/application/childattributes/${$Child.id}/${applicationId}`
				);
			return;
		}

		let childResponse = await saveChild();

		isLoading = false;
		if (!childResponse) return;
		if (childResponse.success) {
			showDifferentAddressModal = false;
			// debugger
			Child.set(childResponse.child);
			if ($Child.useParentAddress != "Y") showDifferentAddressModal = true;
			applications = childResponse.applications || [];
			lateApplications = childResponse.lateApplications || [];
			// dispatch("notification","Details changed successfully!!");
			if (applications.length > 0 || lateApplications.length > 0) {
				showApplicationResubmit = true;
				if (!showDifferentAddressModal)
					dispatch(
						"showApplicationResubmitModal",
						!isSaveAndExit
							? `/application/childattributes/${$Child.id}/${applicationId}`
							: "/user/home"
					);
			}
		} else {
			isFormValid = false;
			error = childResponse.message;
			childWithSameDetailsExists = childResponse.matchingChildExistForParent;
			if (!childWithSameDetailsExists) return;
			dispatch("notification", error);
			dispatch("pageRedirect", {
				url: `/application/childattributes/${childResponse.childId}/${applicationId}`,
			});
		}
	};

	const saveAndNavigate = async () => {
		let oldChildId = $Child.id;
		await validateAndSave();

		if (isFormValid && !showDifferentAddressModal && !showApplicationResubmit) {
			if (!oldChildId || oldChildId == 0)
				dispatch("pageRedirect", {
					url: `/application/childdetails/${$Child.id}/${applicationId}`,
				});
			dispatch(
				"pageChange",
				`/application/childattributes/${$Child.id}/${applicationId}`
			);
		}
	};
</script>
<style>
	 .info-container {
    border-left: 2px solid #6f4077;
    font-size: 14px;
    color: #6f4077;
    margin-bottom: .5rem !important;
	padding:5px;
  }
  .dob-info{
	font-size: 14px;
    color: #6f4077;
  }
</style>

<div class="step-1-shows">
	<div class="field">
		<a
			tabindex="0"
			href="javascript:;"
			on:click={() => {
				dispatch('back', '/user/home');
			}}>
			<span class="fas fa-arrow-left" />
			Back
		</a>
	</div>
	<h1 class="title is-3" id="header">Child details</h1>

	<h2 class="subtitle">
		Child's name must be as it appears on their birth certificate
	</h2>
	{#if wrappedObj}
		<div aria-labelledby="header">
			<Field label="First Name">
				<span slot="inputField">
					<TextBox
						placeholder="First Name"
						isRequired={true}
						bind:text={wrappedObj.forename.value}
						bind:isValid={wrappedObj.forename.isValid}
						bind:isDirty={wrappedObj.forename.isDirty}
						label="the child's first name"
						disabled={isSibling} />
				</span>
			</Field>

			<Field label="Middle Name" isRequired={false}>
				<span slot="inputField">
					<TextBox
						placeholder="Middle Name"
						isRequired="false"
						bind:text={wrappedObj.middleName.value}
						bind:isValid={wrappedObj.middleName.isValid}
						bind:isDirty={wrappedObj.middleName.isDirty}
						label="the child's middle name"
						disabled={isSibling} />
				</span>
			</Field>

			<Field label="Last Name">
				<span slot="inputField">
					<TextBox
						placeholder="Last Name"
						isRequired={true}
						bind:text={wrappedObj.surname.value}
						bind:isValid={wrappedObj.surname.isValid}
						bind:isDirty={wrappedObj.surname.isDirty}
						label="the child's last name"
						disabled={isSibling} />
				</span>
			</Field>

			<Field label="Date of Birth" hasIconRight={false} helpId="Help47">
				<span slot="inputField">
					<DOB
						bind:dob={wrappedObj.dob.value}
						bind:dobStr
						bind:isValid={wrappedObj.dob.isValid}
						bind:isDirty={wrappedObj.dob.isDirty}
						on:changed={isValidDob}
						{...bindParams}
						bind:allowDisable={isSibling} />
						
				</span>
			</Field>
			{#if dobError != ''}
				<p class="info-container"> {dobError}<br/><br/><span style="color: #1d70b8;cursor: pointer;" on:click={()=>showDOBHelp = true}>More Info</span></p>
			{:else if phaseName != "" && wrappedObj.dob.isValid}
		<p class="dob-info">This application will be for the <b>September {applicationYear}</b> intake to <b>{phaseName}</b>.</p>
			{/if}

			<Field label="Sex" helpId="Help44">
				<span slot="inputField">
					<Radio
						label="sex"
						bind:group={wrappedObj.gender.value}
						bind:isDirty={wrappedObj.gender.isDirty}
						bind:isValid={wrappedObj.gender.isValid}
						{...radioParams} />
				</span>
			</Field>

			<Field
				helpId="help34"
				bind:hideInfo={wrappedObj.address.isValid}
				label="Address">
				<span slot="inputField">
					<div class="field">
						<label class="checkbox">
							<input
								type="checkbox"
								bind:checked={wrappedObj.useParentAddress.value}
						on:change={checkValidDOBRange} />
							Use your home address
						</label>
					</div>
					{#if wrappedObj.useParentAddress.value}
						{#each wrappedObj.parentAddress.value.addr.split(',') as addr}
							{addr}<br />
						{/each}
					{:else}
						<AddressPicker
							bind:selectedAddress={wrappedObj.address.value}
							bind:isValid={wrappedObj.address.isValid}
							bind:isAddressDirty={wrappedObj.address.isDirty}
							bind:isPostcodeDirty
							on:change={handleChange}
							username="childdetails"
							isRequired={!wrappedObj.useParentAddress.value} />
					{/if}
				</span>
			</Field>
			<!-- need to show/hide counciltaxref depending on condition -->
      <!--{#if showCouncilTax || (wrappedObj.councilTaxRef.value != '' && wrappedObj.councilTaxRef.value)-->
      {#if showCouncilTax}
				<Field
					info={getHelpText('Help35')}
					bind:hideInfo={wrappedObj.councilTaxRef.isValid}
					label="Council Tax Account Number"
					isRequired={wrappedObj.councilTaxRef.isRequired}>
					<span slot="inputField">
						<TextBox
							placeholder=""
							isRequired={wrappedObj.councilTaxRef.isRequired}
							bind:text={wrappedObj.councilTaxRef.value}
							bind:isValid={wrappedObj.councilTaxRef.isValid}
							bind:isDirty={wrappedObj.councilTaxRef.isDirty}
							label="Council tax account number" />
					</span>
				</Field>
			{/if}

			<hr />

			{#if !isFormValid}
				<div class="notification is-invalid">
					<p style="margin-bottom:0px;">
						{@html error}
					</p>
				</div>
			{/if}

			<div>
				<button
					class="button is-primary {isLoading ? 'is-loading' : ''}"
					on:click={saveAndNavigate}
					disabled={isLoading}>
					Save and continue
				</button>
			</div>
		</div>
	{/if}
</div>

<Modal showModal={isFormValid && showDifferentAddressModal}>
	<Help
		helpId={differentAddressHelpId}
		showSearchOption={false}
		showContent={true}
		doAnimation={false} />
	<footer class="modal-card-foot buttons is-right" slot="footer">
		<button class="button continue" on:click={handleModalContinue}>
			Continue
		</button>
	</footer>
</Modal>

<Modal bind:showModal={showDOBHelp}>
<Help
helpId ={'Help47'}
showSearchOption={false}
showContent={false}
doAnimation={false}
isPopup={true} />
</Modal>
