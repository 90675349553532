<script>
  import Cards from "../components/Cards.svelte";
</script>

<style>
  .card-header-title {
    color: white;
    font-weight: 500;
  }
  .image-container{     
      margin-left: 2em;
      flex-wrap: wrap;
      display: flex;
  }
  img{
       margin-right: 5em;
      margin-bottom: 2em;
  }

</style>

<div class="container" style="margin-top:1.5rem;">

  <div class="columns is-centered" style="margin: 0;">

    <div class="column is-12">
      <div class="field">
        <a href="/">
          <span class="fas fa-arrow-left" />
            Back
        </a>
      </div>
      <h1 class="title" style="text-align: center;color: #70457a;">
        How to install the eAdmissions app
      </h1>
      <!-- <Cards bind:id={item.id} showContent={item.showContent} doAnimation={doAnimation}> -->
      <Cards>
        <div class="card-header-title" slot="header">
          On Safari (iPhone and iPad)
        </div>
        <div class="content" slot="content">
          <ol>
            <li>
              Tap the Share button in Safari when using the website.              
            </li>
            <li>
              Tap the icon labelled Add to Home Screen.             
            </li>
            <li>
              Tap Add in the upper-right corner.    
            </li>
          </ol>
          <div class="image-container">
          <img
            src="images/installhelp/iphone1.jpg"
            border="0"
            alt="iphonehelp" />
          <img
            src="images/installhelp/iphone2.jpg"
            border="0"
            alt="iphonehelp" />
          <img
            src="images/installhelp/iphone3.jpg"
            border="0"
            alt="iphonehelp" />
            </div>
        </div>
      </Cards>
      <Cards>
        <div class="card-header-title" slot="header">
          On Google Chrome mobile (Android, iPhone and iPad)
        </div>
        <div class="content" slot="content">
          <ol>
            <li>
            Tap “Add eAdmissions to Home screen”             
            </li>           
          </ol>
          <div class="image-container">
         
          <img
            src="images/installhelp/chrome.jpg"
            border="0"
            alt="chromehelp" />
          <img
            src="images/installhelp/chrome2.jpg"
            border="0"
            alt="chromehelp" />
            </div>
        </div>
      </Cards>
      <Cards>
        <div class="card-header-title" slot="header">
          On Google Chrome desktop (Windows and Mac)
        </div>
        <div class="content" slot="content">
          <ol>
            <li>
              Click on the plus icon next the bookmark start icon             
            </li>
            <li>
              Click install             
            </li>            
          </ol>
          <div class="image-container">
          <img
            src="images/installhelp/chromedesktop.png"
            border="0"
            alt="chromedesktop" />         
            </div>
        </div>
      </Cards>
    </div>
  </div>
</div>
