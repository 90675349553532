<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { GetChild } from "./service.js";
  import Select from "../HTMLElements/Select.svelte";
  import {
    Child,
    ChildChunks,
    DefaultChildChunks,
    RelationshipOptions,
    HelpSummary,
  } from "./store.js";
  import * as objectWrapper from "../Utils/ObjectWrapper.js";
  import Field from "../FormField.svelte";
  import * as Validator from "../Utils/ValidateFormFIelds.js";
  import Radio from "../HTMLElements/RadioInput.svelte";
  import CheckBox from "../HTMLElements/CheckBoxInput.svelte";
  import Modal from "../HTMLElements/Modal.svelte";
  import Help from "../help.svelte";
  import Cards from "../../components/Cards.svelte";
  import * as utils from "../Utils/utils.js";
  import wrappedFetch from "../../wrappedFetch.js";

  const dispatch = new createEventDispatcher();
  let isMobile = utils.isMobileDevice();
  export let id;
  export let applicationId;

  let error = "";

  let showHelpModal = false;
  let isFormValid = true;
  let showModal = false;
 let onBackUrl='';

  let wrappedObj;
  let ref;

  let radioItems = [
    { value: true, TextValue: "Yes" },
    { value: false, TextValue: "No" }
  ];
  let showContinue = true;
  let isDetailsChanged = false;
  let isSaveAndExit = false;

  export let radioParams = { item: radioItems, required: true };
  export const saveAndExit = async () => {
    isSaveAndExit = true;
    await validateAndSave();
    if (isFormValid && !showModal && !showApplicationResubmit)
      dispatch("pageChange", `/user/home`);
  };

  const getModalContent = async () => {
    const getHelpContents = await wrappedFetch("helpcontent?helpId=ChildWarnings");
    return await getHelpContents.json();
  };

export let applications =[];
export let lateApplications =[];
let showApplicationResubmit =false;

    if($Child.phase == 'N1')
     onBackUrl = `/application/childattributes/${id}/${applicationId}`;
      else
     onBackUrl =`/application/currentschool/${id}/${applicationId}`;

  onMount(async () => {
    if (!$Child) await GetChild(id);
    if($Child.phase != "N1" && $Child.educationStatus == "") 
    // !$Child.currentSchoolNameTyped  && !$Child.currentSchoolId && ($Child.homeEducated == 'N' )  && $Child.abroadEducated == 'N' && $Child.notAtSchool =='N')
    {
      dispatch("pageChange", `/application/currentschool/${id}/${applicationId}`);
      return;
    }
    if($Child.phase == "N1" && !$Child.multipleBirth )
    {
      dispatch("pageChange", `/application/childattributes/${id}/${applicationId}`);
      return;
    }
    ChildChunks.set({
      relations: {
        id: $Child.id,
        relationship: $Child.relationship,
        responsibility: $Child.responsibility      
      }
    });

    DefaultChildChunks.set($ChildChunks);
    wrappedObj = objectWrapper.wrapObject($ChildChunks.relations);

    if(!wrappedObj.relationship.value || wrappedObj.relationship.value == '')
      wrappedObj.relationship.isValid = false;
      
  });


  const handleModalContinue = () => {};

  const getHelpText = title => {
    if ($HelpSummary.length > 1)
      return $HelpSummary.find(h => h.title === title).text;
    // const search =  $HelpSummary.find(h => h.title === title);
    // return search.text;
  };

  const validateAndSave = async () => {
    isFormValid = true;

    showModal = false;
    showApplicationResubmit = false;
    ChildChunks.set({ relations: objectWrapper.unwrapObject(wrappedObj) });
    //Check if anything has changed before doing anything else 
    isDetailsChanged = !(JSON.stringify($ChildChunks) === JSON.stringify($DefaultChildChunks));


    // Validate
    if (!Validator.validate(wrappedObj)) {
      isFormValid = false;
      error = "Please ensure that you have completed the required fields.";
      wrappedObj = wrappedObj;
      return;
    }

          if(!isDetailsChanged)
       {
         if($Child.responsibility == "N")
         {
           showModal = true;
           return;
         }
         if(isSaveAndExit)
         dispatch("pageChange", '/user/home');
         else
         dispatch("pageChange", `/application/secondcontact/${id}/${applicationId}`);            
          return;
       }

    let childResp = await saveChild();    
    if (childResp.success) {
      Child.set(childResp.child);
      
      if (childResp.child.responsibility == "N") {       
        showModal = true;       
      }
      applications=childResp.applications || [];
      lateApplications = childResp.lateApplications || [];     
     // dispatch("notification","Details changed successfully!!");
      if(applications.length > 0 || lateApplications.length > 0)
      {
        if(!$Child.isSibling){
          showApplicationResubmit = true; 
          if(!showModal)
            dispatch('showApplicationResubmitModal',isSaveAndExit ?'/user/home' :`/application/secondcontact/${id}/${applicationId}`);
        }
      }   
        
    }
    else{
      isFormValid = false;
      error = childResp.message;
      wrappedObj = wrappedObj;
    }    
  };

  async function saveChild() {   
    const saveChildURL = "child/savechildrelationdetails";

    const resp = await wrappedFetch(saveChildURL, {
      method: "post",
      body: JSON.stringify($ChildChunks.relations),
      headers: {
        "Content-Type": "application/json"
      }
    });
    return await resp.json();
  }

  const saveAndNavigate = async () => {
    await validateAndSave();
    if (isFormValid && !showModal && !showApplicationResubmit)
    {      
      dispatch("pageChange", `/application/secondcontact/${id}/${applicationId}`);      
    }
      
  };
</script>

<style>
  .card-header-title {
    color: white;
    font-weight: 500;
  }
</style>

<div class="step-2-shows">
  <div class="field">
    <a href="javascript:;" on:click={()=> {dispatch('back', onBackUrl)}}>
      <span class="fas fa-arrow-left" />
      Back
    </a>
  </div>
   <div style="display:inline-flex" >
 <h1 class="title is-3">Relationship details </h1>
 <!-- <span name="infoIcon" class="icon helpicon"  on:click={() => { dispatch('showContextualHelpModal')}}>
 <span class="fas fa-question-circle"></span>
 </span> -->
 </div>
  {#if wrappedObj}
    <Field
      info={getHelpText('Help26')} 
      showInfo={!wrappedObj.relationship.isValid && wrappedObj.relationship.isDirty}
      label="Relationship to Child">
      <span slot="inputField">
        <Select
          items={RelationshipOptions}
          bind:selectedValue={wrappedObj.relationship.value}
          placeholder="Select a relationship"
          isRequired="true"
          selectedValueText="value"
          bind:isValid={wrappedObj.relationship.isValid}
          bind:isDirty={wrappedObj.relationship.isDirty}
          bind:ref
          label="relationship" />
      </span>
    </Field>

    <Field    
      info={getHelpText('Help46')}    
      helpId="help46"
      label="Do you have parental responsibility?"
      showInfo={!wrappedObj.responsibility.isValid && wrappedObj.responsibility.isDirty}>
      <span slot="inputField">
        <Radio
          bind:group={wrappedObj.responsibility.value}
          bind:isDirty={wrappedObj.responsibility.isDirty}
          bind:isValid={wrappedObj.responsibility.isValid}
          label="parental responsibility"
          errorMessage="You must select parental responsibility."
          {...radioParams} />
      </span>
    </Field>

    <hr />
    {#if !isFormValid}
      <div class="notification is-invalid">
        <p>{@html error}</p>
      </div>
    {/if}

    <div>
      <a
        href="javascript:;"
        class="button is-primary"
        on:click={saveAndNavigate}>
        Save and continue
      </a>
    </div>
  {/if}
</div>

<Modal bind:showModal on:closed = {() => {showContinue = true;}}>
  {#await getModalContent() then content}
    <section class="section">
      <div class="container">

        <div class="columns is-centered">

          <div class="column is-12-tablet">
            <div class="field">
              <p>
                You have indicated that you don't have parental responsibility for this child,
                 therefore your home LA may contact you to seek further information.         
              </p>
            </div>  
              <Cards showContent={!isMobile}>
                <div class="card-header-title" slot="header">
                  {@html content[6].text}
                </div>
                <div class="content" slot="content">
                  {@html content[7].text}
                </div>
              </Cards>          
          </div>
        </div>
      </div>
    </section>
  {/await}
  <footer class="modal-card-foot buttons is-right" slot="footer">
    <button
      class="button is-purple continue"
      on:click={() => {
        showModal = false;
        showContinue = true;
      }}>
      Cancel
    </button>
    {#if showContinue}
    <button
      class="button is-primary continue"
      on:click={() => {
        showModal = false;
        if(!showApplicationResubmit && isSaveAndExit)
        {
          dispatch("pageChange", '/user/home');
          return;
        }
        if(showApplicationResubmit)
        dispatch('showApplicationResubmitModal',isSaveAndExit ?'/user/home' :`/application/secondcontact/${id}/${applicationId}`);
        else
        dispatch('pageChange', `/application/secondcontact/${id}/${applicationId}`);
      }}>
      Continue
    </button>
    {/if}
  </footer>
</Modal>
