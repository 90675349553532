<script>
  import { onMount } from "svelte";
  import Cards from "./Cards.svelte";
  import * as utils from "./Utils/utils.js";
  import * as animateScroll from "svelte-scrollto";
  import wrappedFetch from  '../wrappedFetch.js';
  export let helpId;
  export let showSearchOption;
  export let isPage = true;
   export let doAnimation = true; 
   export let showMainHeader = true;
   export let showContentHeader = true;
   export let isIndexed = false;
   export let startAt = 0;
   export let endAt = 0;
   export let back="";
   export let isPopup=false;

  let searchvalue = "";
  // let showParagraphs =false;
  let heading = { header: "", content: "" };
  let headerHighlighted ="";
  let paragraphs = [];
  let c = [];
  let filteredParagraphs = [];
  let noMatch = false;
  let keypr = false;
  let regex = new RegExp("<[^>]*>", "gmi");
  let isMobile = utils.isMobileDevice();
  export let showContent = !isMobile;
  const getContent = async () => {
    const getHelpContents = await wrappedFetch("helpcontent?helpId=" + helpId);
    return await getHelpContents.json();
  };

  onMount(async function() {
    const contents = await getContent();


    let i = 1;
    contents.forEach((d, k) => {     
      switch (d.type) {
        case "h1":
          heading.header = d.text;
          heading.content = `<div>${contents[k + 1]["text"]}</div>`;
          break;

        case "h3":
          if(!isIndexed)
          {
          if (d.text != "")
            paragraphs.push({
              header: `<div>${d.text}</div>`,
              content: `<div>${contents[k + 1]["text"]}</div>`,
              id: i++,
              showContent: showContent
            });
          }
          else if( k >= startAt && k <= endAt)
          {
            if (d.text != "")
            paragraphs.push({
              header: `<div>${d.text}</div>`,
              content: `<div>${contents[k + 1]["text"]}</div>`,
              id: i++,
              showContent: showContent
            });
          }

          break;
      }
    });

    search("");
  });

  const search = s => {
    let rg = new RegExp(
      `(>[\\w\\s,\\.\\-\\(\\)\\'\\"\\?\\!\\*\\&\\%\\;\\:\\@\\_\\#\\/]*)(${s})`,
      "gmi"
    );
    headerHighlighted = heading.content;

    if(s!="")
   headerHighlighted = heading.content.replace(rg, match => {
            return match.replace(new RegExp(s, "gmi"), `<mark>$&</mark>`);
          })
    filteredParagraphs = paragraphs
      .map(p => {
        if (s == "") {
          noMatch = false;
          return p;
        }

        return {
          id: p.id,
          header: p.header.replace(rg, match => {
            return match.replace(new RegExp(s, "gmi"), `<mark>$&</mark>`);
          }),
          content: p.content.replace(rg, match => {
            return match.replace(new RegExp(s, "gmi"), `<mark>$&</mark>`);
          }),
          showContent:
            p.content
              .replace(regex, match => "")
              .toLowerCase()
              .indexOf(s.toLowerCase()) > -1 ||
            s == "" ||
            p.header
              .replace(regex, match => "")
              .toLowerCase()
              .indexOf(s.toLowerCase()) > -1
        };
      })
      .filter(p => {
        return s == "" || p.showContent;
      });

    //   if (searchvalue == "") return true;
    //   if( p.content.indexOf(searchvalue) > -1){
    //    return true;
    //   }
    //   else return false;
    // })
  };

  let timeout;
  $: {
    //delays search by 300 ms
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      search(searchvalue);
    }, 300);
  }

  $: noMatch =
    searchvalue != "" && filteredParagraphs.length <= 0 && headerHighlighted.indexOf("<mark>") < 0;
</script>

<style>
  .card-header-title {
    color: white;
    font-weight: 500;
  }
  .icon {
    padding-left: 5px !important;
    padding-top: 7px !important;
  }
  .field a {
    color: #1d70b8;
    cursor: pointer;
    text-decoration: none;
  }

</style>
{#if isPopup}
<section>
  <div class="container">
    <div class="tile is-parent">
      <article
        class="tile is-child"
        style="background-color: transparent;">
        {#if back != ""} 
          <div class="field" style="margin-left: -0.75rem">
            <a href={back}>
              <span class="fas fa-arrow-left" />
              Back
            </a>
          </div>
        {/if}
        {#if showMainHeader}
          <h1 class="title" style="text-align: center;color: #70457a;">
            {@html heading.header}
          </h1>
        {/if}
      </article>
    </div>
    
    <div class="tile" style="margin-top:0px;">
      <div class="tile is-vertical is-12">
        <div class="tile is-parent">
          <article class="tile is-child">
            {#if showSearchOption}
              <div class="field" style="margin-bottom: 1.5rem;">
                <div class="control has-icons-left">
                  <input
                    aria-label="search box"
                    type="text"
                    class="input"
                    placeholder="Start typing to search"
                    bind:value={searchvalue}
                    on:input={() => {
                      keypr = true;
                    }}
                    style="width: 400px;" />
                  <span class="icon is-small is-left">
                    <span class="fa fa-search" />
                  </span>
                </div>
              </div>

              {#if noMatch}
                <div class="notification">
                  No search results found!
                </div>
              {/if}
            {/if}
            {#if showContentHeader && (searchvalue == "" || headerHighlighted.indexOf("<mark>") > -1) }
            <div class="field">
            {@html headerHighlighted}
            </div>
            <!-- {#if filteredParagraphs.length < 1  && !showSearchOption}
              <p style="text-align:center">
                {@html heading.content}
              </p>
            {:else}
              <p >
                {@html heading.content}
              </p>
            {/if} -->
            {/if}

            {#if filteredParagraphs.length > 0}
              {#each filteredParagraphs as item}
              <Cards bind:id={item.id} showContent={item.showContent} doAnimation={doAnimation}>
                  <div class="card-header-title" slot="header">
                    {@html item.header}
                  </div>
                  <div class="content" slot="content">
                    {@html item.content}
                  </div>
                </Cards>
              {/each}
            {/if}
      </article>
      </div>
      </div>
    </div>
  </div>
</section>
{/if}
{#if isPage && !isPopup}
<section>
  <div class="container">
    <div class="tile is-parent">
      <article
        class="tile notification is-child"
        style="background-color: transparent;">
        {#if back != ""} 
          <div class="field" style="margin-left: -0.75rem">
            <a href={back}>
              <span class="fas fa-arrow-left" />
              Back
            </a>
          </div>
        {/if}
        {#if showMainHeader}
          <h1 class="title" style="text-align: center;color: #70457a;">
            {@html heading.header}
          </h1>
        {/if}
      </article>
    </div>
    
    <div class="tile is-parent" style="margin-top:0px;">
      <div class="tile is-vertical is-12">
        <div class="tile is-parent">
          <article class="tile is-child">
            {#if showSearchOption}
              <div class="field" style="margin-bottom: 1.5rem;">
                <div class="control has-icons-left">
                  <input
                    aria-label="search box"
                    type="text"
                    class="input"
                    placeholder="Start typing to search"
                    bind:value={searchvalue}
                    on:input={() => {
                      keypr = true;
                    }}
                    style="width: 400px;" />
                  <span class="icon is-small is-left">
                    <span class="fa fa-search" />
                  </span>
                </div>
              </div>

              {#if noMatch}
                <div class="notification">
                  No search results found!
                </div>
              {/if}
            {/if}
            {#if showContentHeader && (searchvalue == "" || headerHighlighted.indexOf("<mark>") > -1) }
            <div class="field">
            {@html headerHighlighted}
            </div>
            <!-- {#if filteredParagraphs.length < 1  && !showSearchOption}
              <p style="text-align:center">
                {@html heading.content}
              </p>
            {:else}
              <p >
                {@html heading.content}
              </p>
            {/if} -->
            {/if}

            {#if filteredParagraphs.length > 0}
              {#each filteredParagraphs as item}
              <Cards bind:id={item.id} showContent={item.showContent} doAnimation={doAnimation}>
                  <div class="card-header-title" slot="header">
                    {@html item.header}
                  </div>
                  <div class="content" slot="content">
                    {@html item.content}
                  </div>
                </Cards>
              {/each}
            {/if}
      </article>
      </div>
      </div>
    </div>
  </div>
</section>
{/if}
{#if !isPage}
  <h2 class="title is-4">
    {heading.header}
  </h2>
  {#if filteredParagraphs.length < 1  && !showSearchOption }  
    <p  style="text-align:center">
      {@html heading.content}
    </p>
  {:else}
    <p>
      {@html heading.content}
    </p>
  {/if}
{/if}
