<script>
    import { Username, IsMobileVerified, IsEmailValidated } from "./store.js";
    import { onMount, createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();
    import Field from "../FormField.svelte";
    import TextBox from "../HTMLElements/Textbox.svelte";
    import wrappedFetch from '../../wrappedfetch.js';

    let error = '';
    let ref = null;
    let showHelpInfo = false;
    function toggleInfo() {
      showHelpInfo = !showHelpInfo;
    if(showHelpInfo)
      _paq.push(['trackEvent', 'Info', 'Password change']); 
  }
    
    onMount(async function() {
        ref.focus();
        $Username.isDirty = false;
  });

    const validate = async (e) => {
        e.preventDefault();
        $Username.isDirty = true;
        if(!$Username.isValid) return;

        var doesUserExist = await wrappedFetch("user/CheckUsernameExists?username=" + $Username.value)
                        .then(res => res.json())
                        .then(result => {
                            return result;
                    });
        
            if($Username.isValid){
                if( $Username.value != '' && $Username.value.indexOf('.') == -1){
                    error = "Username must contain a '.' e.g. jbloggs.uso or jbloggs.123 ";
                    $Username.isValid = false;
                    return;
                } else if( !$Username.value.indexOf('@') == -1){
                    error = "You entered an email address, not a USO username.";
                    $Username.isValid = false;
                    return;
                }
                else if(!doesUserExist.success){
                    error = doesUserExist.message;
                    $Username.isValid = false;
                    return;
                } else if(doesUserExist.success)
                    $IsMobileVerified = doesUserExist.isMobileValidated;
                    $IsEmailValidated = doesUserExist.isEmailValidated;
            }
            else{
                error = "Please ensure that you have completed the required fields.";
                return;
            }
        if($Username.isDirty && $Username.isValid)
            dispatch("pageChange", "/account/resetoptions");
    };
  
</script>
<style>
h1 .info-help-container
 {
  border-left: 2px solid #6f4077;
  font-size: 14px;
  color: #6f4077;
  margin-bottom: .5rem !important;
  font-weight: initial;
}</style>

<form action="" class="box">
 <div class="field">
              <a href="/signin">
                <span class="fas fa-arrow-left" />
                Back
              </a>
            </div>
    <h1 class="title is-4">Password change 
      <span name="infoIcon" class="icon info-icon" on:click={()=>showHelpInfo=!showHelpInfo}>
        <span tabindex="0" aria-label="Password change help" class="fas fa-info-circle">          
        </span>
      </span>
      {#if showHelpInfo}
      <div class="info-help-container">
        <p style="padding: 5px;">
         Please <a href="https://www.youtube.com/watch?v=AFZ8HgN_PMU" target="_blank">click here</a> to watch a video tutorial on resetting your eAdmissions username and password.
         </p>
    </div>
    {/if}
    </h1>
    <p class="subtitle is-6">If you know your username, please enter it below.</p>

    <Field label="Username"   helpId="help48"
           showInfo={ !$Username.isValid && $Username.isDirty }>
      <span slot="inputField">
        <TextBox
          placeholder="jbloggs8.uso"
          isRequired="true"
          bind:text={$Username.value}
          bind:isValid={$Username.isValid}
          bind:isDirty={$Username.isDirty}
          on:blur={$Username.isDirty = true}
          bind:ref
          label="username"
        />
      </span>
    </Field>

    <a href="/account/forgotusername">Forgotten username?</a>
    <hr />    
    {#if !$Username.isValid && $Username.isDirty && error!=''}
      <div class="notification is-invalid">
        <p class="help" style="margin-bottom:0px">{error}</p>
      </div>
    {/if}
    
    <div style="text-align: right;">
        <button type="submit" class="button is-purple" on:click={validate}>
            Continue
        </button>
    </div>
</form>
