<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let itemList = [];
  export let selected;
  export let placeholder;
  export let isValid = true;
  export let isDirty = false;
  export let disabled = false;
  export let showDropdown = false;
  export let showSchoolNotListed = false;
  const dispatch = new createEventDispatcher();

  let searchTerm;
  let searchList = [];
  let chevron = showDropdown?"fa-chevron-up":"fa-chevron-down";

  onMount(() => {  
    searchList = itemList;
    placeholder =
      selected && itemList.find(i => i.id == selected)
        ? itemList.find(i => i.id == selected).name
        : placeholder;
  });

  $: {
    searchList = itemList;  
  //  showDropdown = false;
  }

  const toggleShow = () => {  
    showDropdown = !showDropdown;
    if( !isDirty && !showDropdown)
      isDirty = true;
    chevron = chevron == "fa-chevron-down" ? "fa-chevron-up" : "fa-chevron-down";
  };

  const handleSearch = e => {
    
    searchList = itemList.filter(
      i =>  i.name.toLowerCase().startsWith(searchTerm.toLowerCase()));
    let searchList2 = itemList.filter(
      i =>  i.name.toLowerCase().indexOf(searchTerm.toLowerCase() ) >= 0);
    searchList =[...new Set([...searchList ,...searchList2])];       
      if(showSchoolNotListed && !searchList.find(s=> s.id==0))
        searchList.push(itemList.filter(i => i.id == 0)[0])
 
  };

  const handleSelect = item => {
    selected = item.id;
    placeholder = item.name;
    toggleShow();
    searchTerm = "";
    searchList = itemList;
    isValid = true;
    dispatch("change");
  };
</script>

<style>
  .dropbtn {
    background-color: white;
    display: flex;
    color: #363636;
    padding: 10px;
    font-size: 19.2px;
    border: solid 1px;
    cursor: pointer;
    max-width: 100%;
    text-align: left;
    height: auto;
    border-radius: 0.2em;
    border-color: #70457a;
  }

  .dropbtn:hover,
  .dropbtn:focus {
    background-color: white;
  }

  .is-danger {
    border: solid 1px #cc0404;
  }

  #searchInput {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 8px 5px 8px 12px;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  #searchInput:focus {
    outline: 3px solid #ddd;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    color: #363636;
    border-color: #70457a;
  }

  .dropdown-content {
    /* display: none;*/
    position: relative;
    width: 100%;
    background-color: white;
    /* min-width: max-content; */
    overflow-y: auto;
    border: 1px solid white;
    z-index: 40;
    height: 250px;
    font-size: 19.2px;
  }

  .dropdown-content div {
    color: #363636;
    padding: 0px 13px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .dropdown-content div:hover {
    background-color: #3273dc;
    color: white;
  }
  .selected {
    background-color: #3273dc;
    color: white;
  }
  .chevron {
    color: #3273dc;
    font-size: 14px;
    padding: 5px;
  }
</style>

<div class="dropdown">

  <button
    on:click={toggleShow}
    class="empty dropbtn {!isValid && isDirty ? 'is-danger' : ''}"
    {disabled}>
    <span class="empty">
      {placeholder}
      <span class="empty chevron fas {chevron}" />
    </span>
  </button>

  {#if showDropdown && searchList.length>0 && !disabled}
    <div id="dropdownList" class="dropdown-content">
      <input
      style="margin-bottom:5px;"
        id="searchInput"
        bind:value={searchTerm}
        on:input={handleSearch}
        autofocus
        autocomplete="off"
        class="empty"
        list="autocompleteOff" placeholder="Start typing" />
      {#each searchList as item}
        <div
          id="selectOptions{item.id}"
          class={selected && selected == item.id ? 'selected' : ''}
          on:click={() => handleSelect(item)}>
          {item.name}
        </div>
      {/each}
    </div>
  {/if}
</div>
{#if !isValid && isDirty}
  <span>
    <p class="help has-text-danger">
      Please select a school from the dropdown list.
    </p>
  </span>
{/if}
