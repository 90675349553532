<script>
  import { slide} from 'svelte/transition';
  import {Location} from '../store.js'
  export let showContent = true;
  export let id;  
  export let doAnimation = true; 
  const toggleInfo = () => {
   showContent = !showContent;
  };

let count = 0;
const track = (value)=>{
    if (count == 0) {count++;return;}
    doAnimation = false;
  }
  Location.subscribe(track)

</script>

<style>
 
  .card-header {
    background-color: #70457a;
  }
  .card-header-title {
    color: white;
    font-weight: 500;
  }

  a:hover {
    color: #003078;
  }

  .tile {
    flex-basis: auto;
    width: 100%;
    max-width: 100%;
  }
</style>


<div class="card" id={id}>
  <header class="card-header"  on:click={toggleInfo} style="cursor:pointer">
    <slot name="header" />
   
    <div     
      class="card-header-icon"
      aria-label="more options"
      style="color:white; font-size:25px">
      <span class="icon">
      
       {#if showContent} <span class="fas fa-angle-up" aria-hidden="true" />{/if}
       {#if !showContent} <span class="fas fa-angle-down" aria-hidden="true" />{/if}
      </span>
    </div>
  </header>
  {#if showContent}
    {#if doAnimation}
    <div class="card-content" in:slide={{duration: 1000 }}> 
     <slot name="content" />
     </div>
   {:else}
        <div class="card-content">
        <slot name="content" />
        </div>
    {/if}
      
   
  {/if}
</div>

