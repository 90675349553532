<script>
  import Modal from "./HTMLElements/Modal.svelte";
  import wrappedFetch from "../wrappedFetch.js";
  import {User} from "../store.js";
  export let showModal = true;
  let showNextForm = false;
  let baseUrl=window.location.origin;
  const updateFeedback = (rating, showNext) => { 
       _paq.push(['trackEvent', 'Feedback', `Rating [${rating}]`]); 
    let form = new FormData();
    form.append("rating", rating);
    wrappedFetch(`/user/UpdateFeedback`, {
      method: "post",
      body: form
    })
      .then(response => response.json())
      .then(response => {
        if (response > 0) {
          $User.rating=rating;
         showModal = false;
        showNextForm = showNext;
       //   dispatch("notification", "Child deleted successfully.");
        }
      });
    
  };
</script>

<style>
  .feedback-icons {
    cursor: pointer;
    font-size: 4rem;
    margin-bottom:5px;
  }
  @media screen and (max-width: 767px) {
    .feedback-icons {
      cursor: pointer;
      font-size: 2rem;
    }
    .hide-text{display:none;}
  }
</style>

<Modal bind:showModal allowClose={false}>
  <div class="columns is-centered">

    <div class="has-text-centered">
      <br />
      <h1 class="title is-4" style="color:#6f4077">Feedback</h1>
      <h1 class="subtitle is-5" style="color:#6f4077">
        How satisfied were you with your experience today?
      </h1>
      <div class="columns is-mobile" style="margin-bottom:0;">
        <div class="column" style="cursor: pointer;"  on:click={() => updateFeedback('1', true)}>
          <i
            class="far fa-angry feedback-icons"
            style="color:red"
            />
          <br />
          <span class="hide-text">Very Unsatisfied</span>
        </div>

        <div class="column" style="cursor: pointer;"  on:click={() => updateFeedback('2', true)} >
          <i
            class="far fa-frown feedback-icons"
            style="color:orange"
           />
          <br />
          <span class="hide-text">Unsatisfied</span>
        </div>

        <div class="column" style="cursor: pointer;"  on:click={() => updateFeedback('3', true)}>
          <i
            class="far fa-meh feedback-icons"
            style="color:#F6E22C"
            />
          <br />
          <span class="hide-text">Neutral</span>
        </div>
        <div class="column"  style="cursor: pointer;" on:click={() => updateFeedback('4', true)} >
          <i
            class="far fa-smile feedback-icons"
            style="color:limegreen"
           />
          <br />
          <span class="hide-text">Satisfied</span>
        </div>
        <div class="column"  style="cursor: pointer;" on:click={() => updateFeedback('5', true)} >
          <i
            class="far fa-laugh feedback-icons"
            style="color:green"
           />
          <br />
          <span class="hide-text">Very Satisfied</span>
        </div>

      </div>
    </div>
  </div>

  <footer class="modal-card-foot buttons is-right" slot="footer" style="margin-bottom:auto;">
    <button
      class="button is-purple continue"
      on:click={() => {
        showModal = false;
        updateFeedback('0', false);
      }}>
     Later
    </button>

  </footer>
</Modal>

<Modal bind:showModal={showNextForm} allowClose={false}>
  <div class="columns is-centered">

    <div class="has-text-centered">
      <br />
      <h1 class="title is-4" style="color:#6f4077">Thank you!</h1>
      <div style="text-align:left;padding:0.75rem;">
        <p>
          We would love to hear further on your experience with using the
          website.Your feedback is greatly appreciated and will use to improve
          the service.
        </p>
        <p>
          If you could please spare a few minutes, we welcome you to complete
          the feedback form by clicking on the link below.
        </p>
          <p>
         If you want to report an issue or need assistance, please <a href="{baseUrl}/support" target="_blank">click here</a>
        </p>
      </div>
      <div >
        <!-- <a href="https://forms.eadmissions.org.uk/26B727A4-7687-4341-A5F8-6E641B1A8336" target="_blank"  style="font-size:1.7rem;color: #407f8a;"
        on:click={()=>{showNextForm=false; _paq.push(['trackEvent', 'Feedback','Form']); }}>Feedback Form <i class="fas fa-external-link-alt" style="font-size:1rem;"></i></a>
       -->
       <a href="{baseUrl}/furtherfeedback" target="_blank"  style="font-size:1.7rem;color: #407f8a;"
       on:click={()=>{showNextForm=false; _paq.push(['trackEvent', 'Feedback','Form']); }}>Feedback Form <i class="fas fa-external-link-alt" style="font-size:1rem;"></i></a>
      </div>
    </div>
  </div>

  <footer class="modal-card-foot buttons is-right" slot="footer" style="margin-bottom:auto;">
    <button
      class="button is-purple continue"
      on:click={() => {
         _paq.push(['trackEvent', 'Feedback','FormDismiss']);
        showNextForm = false;
      }}>
      Close
    </button>

  </footer>
</Modal>
